import React from 'react';

import {
  Radio,
  RadioGroup,
} from '@company-settings/components/company-settings-tab/pay-and-redeem/redeem/redeem-options/RedeemOptions.style';
import { useRedeem } from '@company-settings/hooks';

export const RedeemOptions = (): JSX.Element => {
  const { redeemOptions, handlerChange, redeemChosenOption, redeemRadioGroupName } = useRedeem();

  return (
    <RadioGroup id={redeemRadioGroupName} name={redeemRadioGroupName} onChange={handlerChange}>
      {redeemOptions.map((redeemOption) => (
        <Radio
          key={redeemOption.label}
          id={redeemOption.label}
          label={redeemOption.label}
          value={(redeemOption.value as unknown) as string}
          checked={redeemOption.value === redeemChosenOption}
        />
      ))}
    </RadioGroup>
  );
};
