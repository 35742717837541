/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import * as faker from 'faker';
import { Model, Response } from 'miragejs';
import { DDC_APP_TIER_BASE_URL } from '../../config/constants';

const HttpCodes = {
  notAuthorized: 401,
  forbidden: 403,
  notFound: 404,
  UnprocessableEntity: 422,
  internalServerError: 500,
};

const DistributionCenterServiceErrors = {
  unauthorized_access_401: 'unauthorized_access_401',
  access_denied_403: 'access_denied_403',
  not_found_404: 'not_found_404',
  sever_error_500: 'sever_error_500',
};

const moreMockData = new Array(94).fill(-1).map((_, index) => ({
  vendorId: faker.datatype.uuid(),
  tradeName: faker.datatype.string(),
  name: 'z' + faker.company.companyName(),
  deliveryCenterId: faker.datatype.number(),
  displayId: faker.datatype.number(),
  enterpriseFiscalNumber: faker.datatype.string(),
  coverageAreas: [
    {
      type: faker.datatype.string(),
      state: faker.address.state(),
      city: faker.address.city(),
      deliveryWindow: {
        minDays: faker.datatype.number({ min: 1, max: 15 }),
        maxDays: faker.datatype.number({ min: 1, max: 15 }),
      },
    },
  ],
  defaultLocation: {
    zipCode: faker.address.zipCode(),
    state: faker.address.state(),
    city: faker.address.city(),
    streetAddress: faker.address.streetAddress(),
    number: faker.datatype.number(),
    addressAdditionalInformation: faker.datatype.string(),
  },
}));

const content = [
  {
    vendorId: '0a8fc797-e734-40d9-9c9b-14563254',
    tradeName: 'CDD Caxias',
    name: 'Centro de Distribuição Caxias',
    deliveryCenterId: '5fe99a25249bce6cc2a033ce',
    displayId: 'CDD Catalão thales',
    enterpriseFiscalNumber: '697.382.976.72',
    vendorLegalName: 'vendor',
    serviceModel: '1P-3L',
    coverageAreas: [
      {
        type: 'BY_CITY',
        state: 'WI',
        city: 'Menomonee Fallsaa',
        deliveryWindow: {
          minDays: 2,
          maxDays: 5,
        },
      },
    ],
    defaultLocation: {
      zipCode: '0306902409',
      state: 'WI',
      city: 'Menomonee Falls',
      streetAddress: 'Av Falls parkway N423456',
      number: '0200',
      addressAdditionalInformation: 'Near the Church',
    },
  },
  {
    vendorId: 'vendorId_1407',
    tradeName: 'CDD 1407',
    name: 'Centro de Distribuição 1407',
    deliveryCenterId: '1407',
    displayId: '1407',
    enterpriseFiscalNumber: '697.382.976.72',
  },
  {
    vendorId: 'vendorId_1408',
    tradeName: 'CDD 1408',
    name: 'Centro de Distribuição 1408',
    deliveryCenterId: '1408',
    displayId: '1408',
    enterpriseFiscalNumber: '697.382.976.72',
    vendorLegalName: 'vendor legal name',
    serviceModel: '1P-3L',
    coverageAreas: [
      {
        type: 'BY_CITY',
        state: 'MG',
        city: 'Belo Horizonte',
      },
    ],
  },
  {
    vendorId: '51df3511-52d5-4d84-ad57-c7f8c6e0a543',
    tradeName: 'CDD 1410',
    name: 'Centro de Distribuição 1410',
    deliveryCenterId: '1410',
    displayId: '1410',
    enterpriseFiscalNumber: '697.382.976.72',
    serviceModel: '1P-3L',
    coverageAreas: [
      {
        type: 'BY_CITY',
        state: 'MG',
        city: 'Belo Horizonte',
        deliveryWindow: {
          minDays: 1,
          maxDays: 4,
        },
      },
      {
        type: 'BY_CITY',
        state: 'SP',
        city: 'Campinas',
        deliveryWindow: {
          minDays: 1,
          maxDays: 4,
        },
      },
    ],
  },
  {
    vendorId: '681e2534-af5f-47da-9648-fa71ea563317',
    tradeName: 'CDD ABC',
    name: 'Centro de Distribuição ABC',
    deliveryCenterId: '5fe99a25249bce6cc2a033cg',
    displayId: 'CDD Catalão thales',
    enterpriseFiscalNumber: '697.382.976.72',
    vendorLegalName: 'vendor',
    serviceModel: '1P-3L',
    coverageAreas: [
      {
        type: 'BY_CITY',
        state: 'WI',
        city: 'Menomonee Fallsaa',
        deliveryWindow: {
          minDays: 2,
          maxDays: 5,
        },
      },
    ],
    defaultLocation: {
      zipCode: '0306902409',
      state: 'WI',
      city: 'Menomonee Falls',
      streetAddress: 'Av Falls parkway N423456',
      number: '0200',
      addressAdditionalInformation: 'Near the Church',
    },
  },
  {
    vendorId: '0a8fc797-e734-40d9-9c9b-14563254',
    tradeName: 'CDD updated 1411',
    name: 'Centro de Distribuição updated 1411',
    deliveryCenterId: '1411',
    displayId: '1411',
    enterpriseFiscalNumber: '697.382.976.72',
    defaultLocation: {
      zipCode: '030690240',
      state: 'MG',
      city: 'Belo Horizonte',
      streetAddress: 'Av Falls parkway  updated1411',
      number: '1411',
      addressAdditionalInformation: 'Near the Church updated 1411',
    },
  },
  ...moreMockData,
];

const DistributionCenter = Model.extend({});

const distributionCenterRoutes = (context) => {
  function showErrorResponse(errorId) {
    let status;
    switch (errorId) {
      case DistributionCenterServiceErrors.access_denied_403:
        status = HttpCodes.forbidden;
        break;
      case DistributionCenterServiceErrors.not_found_404:
        status = HttpCodes.notFound;
        break;
      case DistributionCenterServiceErrors.unauthorized_access_401:
        status = HttpCodes.notAuthorized;
        break;
      default:
        status = HttpCodes.internalServerError;
        break;
    }
    return new Response(status, { some: 'header' }, { error: [errorId] });
  }

  const getAllHandler = async (schema, request) => {
    const { page, pageSize } = request.queryParams;

    const contentMock = content.slice(
      Number(page) * Number(pageSize),
      Number(page) * Number(pageSize) + Number(pageSize)
    );

    return {
      content: contentMock,
      pagination: {
        pageNumber: Number(page),
        pageSize: Number(pageSize),
        pages: content.length / Number(pageSize),
        total: content.length,
      },
    };
  };

  context.get(`${DDC_APP_TIER_BASE_URL}`, getAllHandler);
};

export default distributionCenterRoutes;
