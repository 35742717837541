import { Paragraph as HexaParagraph } from '@hexa-ui/components';
import { styled } from 'theme';

export const StyledDialog = styled('div', {
  '& [role="dialog"]': {
    width: 600,
    overflow: 'hidden',
    maxHeight: 'initial',

    '& > div:nth-child(2)': {
      overflow: 'initial',
    },
  },
});

export const ButtonGroup = styled('div', {
  display: 'flex',
  gap: 'calc($2 + $1)',
  justifyContent: 'flex-end',
});

export const InputsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$8',

  '& > div': {
    width: '100%',
  },
});

export const Paragraph = styled(HexaParagraph, {
  marginBottom: '$8',
});
