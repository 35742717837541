import { styled } from 'theme';

import { Paragraph as HexaParagraph } from '@hexa-ui/components';

export const StyledDialog = styled('div', {
  '& [role="dialog"]': {
    width: 600,
  },
});

export const ButtonGroup = styled('div', {
  display: 'flex',
  gap: 'calc($2 + $1)',
  justifyContent: 'flex-end',
});

export const Paragraph = styled(HexaParagraph, {
  marginBottom: '$28',
});

export const SelectContainer = styled('div', {
  marginTop: '$8',
});

export const ButtonContainer = styled('div', {
  marginTop: '$10',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$4',

  '& > div > div:nth-child(1)': {
    background: 'none',
  },
});
