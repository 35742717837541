import React from 'react';
import { useIntl } from 'react-intl';

import {
  Radio,
  RadioGroup,
} from '@company-settings/components/company-settings-tab/order-notifications/order-notification-options/OrderNotificationOptions.styles';
import type { OrderNotificationOptionsProps } from '@company-settings/components/types';
import { OrderNotificationOptions as OrderNotificationOptionsEnum } from '@company-settings/enums';
import { useShowOrderNotificationWarning } from '@company-settings/hooks';
import { AlertWarning } from '@core/components';

const renderOption = (orderNotificationOption: OrderNotificationOptionsEnum, label: string) => (
  <Radio
    key={orderNotificationOption}
    id={orderNotificationOption}
    label={label}
    value={orderNotificationOption}
  />
);

export const OrderNotificationOptions = ({
  options,
}: OrderNotificationOptionsProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { showAlertMessage, handleShowAlertMessage } = useShowOrderNotificationWarning();

  return (
    <>
      <RadioGroup
        name="orderNotification.orderNotificationEmails"
        onValueChange={handleShowAlertMessage}
      >
        {options.map((orderNotificationOption) => {
          const optionLabelKey = `COMPANY_SETTINGS.ORDER_NOTIFICATIONS_${orderNotificationOption}_LABEL`;

          return renderOption(orderNotificationOption, formatMessage({ id: optionLabelKey }));
        })}
      </RadioGroup>
      {showAlertMessage && (
        <AlertWarning
          message={`${formatMessage({
            id: 'COMPANY_SETTINGS.ORDER_NOTIFICATIONS_ALERT_WARNING',
          })}`}
          fullWidth
          spaced
        />
      )}
    </>
  );
};
