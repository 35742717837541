import {
  AvailablePaymentMethod,
  CompanySettingsV2Form,
  GenericPaymentMethod,
} from '@company-settings/types';
import { Plus } from '@hexa-ui/icons';
import { IVendorMS } from 'domains/Vendor';
import { useStoreMap } from 'effector-react';
import { useFormikContext } from 'formik';
import { clone } from 'ramda';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import VendorDetailStore from 'stores/vendorDetail/VendorDetailStore';
import { notifyError } from 'utils';
import { PaymentButton } from './PaymentManager.styles';
import CustomPaymentModal from './custom-payment-modal/CustomPaymentModal';
import PaymentManagerModal from './payment-manager-modal/PaymentManagerModal';

const PlusIcon = (): JSX.Element => <Plus fr={1} size="medium" />;

export interface PaymentManagerProps {
  availablePaymentMethods?: Array<AvailablePaymentMethod>;
}

export const PaymentManager = ({ availablePaymentMethods }: PaymentManagerProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [availableMethods, setAvailableMethods] = useState<AvailablePaymentMethod[]>([]);
  const [isModalOpen, setIsModalOpen] = useState({
    modalInfo: false,
    customPayment: false,
  });
  const { values, setFieldValue, dirty } = useFormikContext<CompanySettingsV2Form>();

  const paymentMethods = values.checkoutSettings?.paymentMethods ?? [];
  const setPaymentMethods = setFieldValue.bind(null, 'checkoutSettings.paymentMethods');

  const vendor = useStoreMap({
    store: VendorDetailStore,
    keys: [],
    fn: (state) => state.vendor as IVendorMS,
  });

  const addToPaymentMethods = (option: AvailablePaymentMethod | GenericPaymentMethod) => {
    const hasItemWithSameLabel = paymentMethods.some(
      (method) =>
        method.label.localeCompare(option.label, undefined, {
          sensitivity: 'base',
          ignorePunctuation: true,
        }) === 0
    );

    if (hasItemWithSameLabel) {
      notifyError(formatMessage({ id: 'COMPANY_SETTINGS.DUPLICATED_PAYMENT_METHOD' }));
      throw new Error(formatMessage({ id: 'COMPANY_SETTINGS.DUPLICATED_PAYMENT_METHOD' }));
    }

    const newPaymentMethods = clone(paymentMethods);
    newPaymentMethods.push({ ...option, enabled: true, description: '' });

    setPaymentMethods(newPaymentMethods);
  };

  const confirmModal = (option: AvailablePaymentMethod) => {
    try {
      addToPaymentMethods(option);
      setAvailableMethods((prevMethods) =>
        prevMethods.filter((method) => method.type !== option.type)
      );
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const toggleModal = (modalType: keyof typeof isModalOpen) => {
    setIsModalOpen((currModal) => ({
      ...currModal,
      [modalType]: !currModal[modalType],
    }));
  };

  useMemo(() => {
    if (availablePaymentMethods && !dirty) {
      setAvailableMethods(availablePaymentMethods);
    }
  }, [availablePaymentMethods, dirty]);

  return (
    <>
      <PaymentButton
        onClick={() => toggleModal('modalInfo')}
        icon={PlusIcon}
        leading
        variant="secondary"
        type="button"
      >
        {formatMessage({
          id: 'COMPANY_SETTINGS.PAYMENT_MANAGER_BTN',
        })}
      </PaymentButton>

      <PaymentManagerModal
        isOpen={isModalOpen.modalInfo}
        toggleModal={toggleModal}
        confirmModal={confirmModal}
        availablePaymentMethods={availableMethods}
      />

      <CustomPaymentModal
        isOpen={isModalOpen.customPayment}
        country={vendor?.country}
        addToPaymentMethods={addToPaymentMethods}
        toggleModal={toggleModal}
      />
    </>
  );
};
