import React from 'react';
import type { AlertProps } from 'modules/core/components/alerts';
import { StyledAlert } from 'modules/core/components/alerts/Alert.styles';

export const AlertInfo = ({
  message,
  fullWidth,
  spaced,
  mt,
  mb,
  ...props
}: AlertProps): JSX.Element => (
  <StyledAlert
    type="info"
    fullWidth={fullWidth}
    spaced={spaced}
    {...props}
    css={{ marginTop: mt, marginBottom: mb }}
    message={message}
  />
);
