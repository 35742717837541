import React from 'react';

import {
  Container,
  Content,
  PaymentCheckbox,
} from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/payment-method-wrapper/PaymentMethodWrapper.styles';
import type { PaymentMethodWrapperProps } from '@company-settings/components/types';
import { useGenericPaymentMethod } from '@company-settings/hooks';

export const PaymentMethodWrapper = ({
  children,
  disableSelection = false,
  ...restProps
}: PaymentMethodWrapperProps): JSX.Element => {
  const { fieldNamePrefix, isEnabled, handleSelectionChange } = useGenericPaymentMethod(restProps);

  return (
    <Container border="small" elevated="minimal">
      <Content>
        <PaymentCheckbox
          id={`payment-method-selection-${restProps.type}`}
          name={`${fieldNamePrefix}.enabled`}
          onCheckedChange={handleSelectionChange}
          disabled={disableSelection}
          checked={isEnabled}
        />
        {children}
      </Content>
    </Container>
  );
};
