import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  NewPaymentContainer,
  PayAndRedeemSubtitle,
  PayAndRedeemTitle,
  PaymentContainer,
} from '@company-settings/components/company-settings-tab/pay-and-redeem/PayAndRedeem.styles';
import { MinimumOrder } from '@company-settings/components/company-settings-tab/pay-and-redeem/minimum-order/MinimumOrder';
import { PaymentManager } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-manager/PaymentManager';
import { PaymentMethodItem } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method-item/PaymentMethodItem';
import { PaymentMethod } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/PaymentMethod';
import { Redeem } from '@company-settings/components/company-settings-tab/pay-and-redeem/redeem/Redeem';
import { PaymentMethodTypes } from '@company-settings/enums';
import { useShowManagePaymentMethods } from '@company-settings/hooks';
import { AvailablePaymentMethod, GenericPaymentMethod } from '@company-settings/types';
import { SkeletonLoader } from '@hexa-ui/components';
import { useField } from 'formik';

export interface PayAndRedeemProps {
  availablePaymentMethods: PaymentMethodTypes[] | AvailablePaymentMethod[];
  isLoading: boolean;
}

const renderPaymentMethods = (paymentMethods: Array<PaymentMethodTypes>) => (
  <PaymentContainer>
    {paymentMethods.map((paymentMethod, index) => (
      <PaymentMethod key={paymentMethod} type={paymentMethod} paymentIndex={index} />
    ))}
  </PaymentContainer>
);

const NewPaymentMethods = () => {
  const [, { value: paymentMethods }] = useField<GenericPaymentMethod[]>(
    'checkoutSettings.paymentMethods'
  );
  return (
    <NewPaymentContainer>
      {paymentMethods?.map((paymentMethod, index) => {
        const key = `${paymentMethod.type}_${paymentMethod.label}_${index}`;
        return (
          <PaymentMethodItem
            {...paymentMethod}
            key={key}
            label={paymentMethod.label}
            type={paymentMethod.type}
            paymentIndex={index}
          />
        );
      })}
    </NewPaymentContainer>
  );
};

const renderPaymentMethodsLoading = () => (
  <NewPaymentContainer data-testid="skeleton-loader">
    <SkeletonLoader height="70px" />
    <SkeletonLoader height="70px" />
  </NewPaymentContainer>
);

export const PayAndRedeem = ({
  availablePaymentMethods,
  isLoading,
}: PayAndRedeemProps): JSX.Element => {
  const showManagePaymentMethods = useShowManagePaymentMethods();

  return (
    <Container>
      <PayAndRedeemTitle>
        <FormattedMessage id="COMPANY_SETTINGS.PAY_AND_REDEEM_TITLE" />
      </PayAndRedeemTitle>
      <PayAndRedeemSubtitle>
        <FormattedMessage id="COMPANY_SETTINGS.PAY_AND_REDEEM_SUBTITLE" />
      </PayAndRedeemSubtitle>
      {isLoading && renderPaymentMethodsLoading()}
      {showManagePaymentMethods ? (
        <>
          <NewPaymentMethods />
          <PaymentManager
            availablePaymentMethods={availablePaymentMethods as AvailablePaymentMethod[]}
          />
          <MinimumOrder />
        </>
      ) : (
        renderPaymentMethods(availablePaymentMethods as PaymentMethodTypes[])
      )}
      <Redeem />
    </Container>
  );
};
