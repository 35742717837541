import type { ValueOf } from '@core/types';
import { mergeDeepWithKey } from 'ramda';
import { hasValue } from 'utils/hasValue';

export const applyIf = <TValue>(
  initialValues: NonNullable<TValue>,
  normalizedValues: NonNullable<TValue>
): TValue => {
  const applyFn = (_: string, leftValue: ValueOf<TValue>, rightValue: ValueOf<TValue>) =>
    hasValue(rightValue) ? rightValue : leftValue;

  return mergeDeepWithKey(applyFn, initialValues, normalizedValues);
};

export const applyIfNotUndefined = <TValue>(
  initialValues: NonNullable<TValue>,
  normalizedValues: NonNullable<TValue>
): TValue => {
  const applyFn = (_: string, leftValue: ValueOf<TValue>, rightValue: ValueOf<TValue>) =>
    rightValue !== undefined ? rightValue : leftValue;

  return mergeDeepWithKey(applyFn, initialValues, normalizedValues);
};
