import { globalCss } from './stitches';

export const globalStyles = globalCss({
  'header#mfe-content-header > div h1': {
    display: 'block',
    height: 'auto',
    lineHeight: '40px',
    wordBreak: 'break-word',
  },
  'header#mfe-content-header.hideHeader': {
    display: 'none',
  },
});
