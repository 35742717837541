import { Card } from '@hexa-ui/components';
import { styled, theme } from 'theme';

import { Checkbox } from '@core/components';

export const Container = styled(Card, {
  width: '22.5rem',
  padding: '$6 $4',
  height: '100%',

  '&:has(button[data-state="checked"]:not(:disabled))': {
    outline: `1px solid ${theme.colors.semanticInfoBasis}`,
  },
});

export const PaymentCheckbox = styled(Checkbox, {
  '&&&': {
    margin: 'unset',
  },
});

export const Content = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  '& > div:nth-child(2)': {
    marginTop: '2px',
  },
});
