/* eslint-disable max-params */
export const queriesKeys = {
  details: (vendorId: string, country?: string) => ['vendor-details', vendorId, country] as const,
  tiers: (country: string) => ['tiers', country] as const,
  countrySettings: (country: string) => ['countrySettings', country] as const,
  settingsV2: (vendorId: string, country?: string) =>
    ['vendor-settings', 'v2', vendorId, country] as const,
  company: (country?: string) => ['add-company', country] as const,
  allVendors: (page?: number, pageSize?: number, country?: string, searchQuery?: string) =>
    ['all-vendors', page, pageSize, country, searchQuery] as const,
  stores: (country: string) => ['get-stores', country] as const,
  storesByVendorId: (country: string, vendorId: string, page: number, pageSize: number) =>
    ['get-stores-by-vendor-id', country, vendorId, page, pageSize] as const,
};
