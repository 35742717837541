import { Radio as CoreRadio, RadioGroup as CoreRadioGroup } from '@core/components';
import { H5 } from '@core/components/typography';
import { styled } from 'theme';

export const Container = styled('div', {
  marginTop: '$4',
});

export const InfoContainer = styled('div', {
  display: 'flex',
});

export const RadioGroup = styled(CoreRadioGroup, {
  display: 'flex',
  flexFlow: 'row',
  gap: '$4',
});

export const Radio = styled(CoreRadio, {
  '&&': {
    margin: 'unset',
  },
});

export const InfoEmpties = styled(H5, {
  '&&': {
    marginRight: '$2',
  },
});
