import { styled } from 'theme';

export const ButtonGrid = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '$4',
  '& > button': {
    margin: 0,
  },
});

export const ModalContainer = styled('div', {
  '& [role="alertdialog"]': {
    '& [class*="image-false"]': {
      height: 'auto',
      margin: '$6 0',
    },
    '& [class*="shouldAdjustPadding"]': {
      padding: '$1 $8 0 $8',
    },
  },
});

export const HeaderCover = styled('div', {
  position: 'fixed',
  width: '100%',
  height: '56px',
  top: 0,
  left: 0,
  zIndex: 2000,
});
