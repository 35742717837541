import { OrderTypes, PaymentMethodTypes } from '@company-settings/enums';
import type { CompanySettingsV2Form } from '@company-settings/types';

export const UNACCEPTED_MINIMUM_ORDER_VALUE = -1;
export const EXPIRES_IN_DAYS_MAXIMUM_LENGTH = 2;
export const EXPIRES_IN_DAYS_MAXIMUM_VALUE = 99;
export const AVAILABLE_PAYMENT_METHODS = Object.values(PaymentMethodTypes);

export const DEFAULT_VALUES_V2: CompanySettingsV2Form = {
  vendorId: '',
  orderNotification: {
    bccEmails: [],
    orderNotificationEmails: undefined,
  },
  checkoutSettings: {
    minimumOrder: {
      type: OrderTypes.OrderTotal,
      value: 0,
    },
    paymentMethods: [],
  },
  imageSettings: {
    thumbnailUrl: undefined,
  },
};
