import { useHasPermission } from 'admin-portal-shared-services';
import permissions from 'config/permissions';

export const useShowPayAndRedeem = (): boolean => {
  const havePermission = useHasPermission(
    [
      permissions.CompanySettings.PaymentMethod.READ,
      permissions.CompanySettings.PaymentMethod.WRITE,
    ],
    'OR'
  );

  return havePermission;
};
