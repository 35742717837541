/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import {
  VENDOR_APP_TIER_BASE_URL,
  VENDOR_APP_TIER_BASE_URL_V2
} from '../../config/constants';
import { IVendorList, IVendorMS } from '../../domains/Vendor';
import Api, { IRequest } from '../Api';

export const loadAllVendors = async (
  country?: string,
  page?: number,
  pageSize?: number,
  partialSearch?: string
): Promise<
  AxiosResponse<IVendorList> & {
    hasError?: boolean;
    error?: any;
  }
> => {
  const requestBody = {} as IRequest;
  requestBody.url = `${VENDOR_APP_TIER_BASE_URL_V2}?page=${page}&pageSize=${pageSize}${partialSearch}`;
  requestBody.config = { headers: { country } };

  const res = await Api.get<IVendorList>(requestBody);
  return { ...res, ...res.data };
};

export const createVendor = (body: IVendorMS): Promise<any> => {
  const url = VENDOR_APP_TIER_BASE_URL;

  return Api.post({
    url,
    body,
    config: {
      headers: {
        country: body.country,
      },
    },
  });
};

export default loadAllVendors;