import React, { createContext, useContext, useMemo } from 'react';
import { useClusterService } from 'admin-portal-shared-services';
import { Clusters } from 'domains/Clusters';

interface ClusterContextData {
  clusterName: Clusters;
  isDTC: boolean;
  isUS: boolean;
}

interface ClusterProviderProps {
  children: React.ReactNode;
}

const ClusterContext = createContext<ClusterContextData>({} as ClusterContextData);

const ClusterProvider = ({ children }: ClusterProviderProps): JSX.Element => {
  const clusterName = useClusterService().getCluster() as Clusters;
  const isDTC = clusterName === Clusters.Global_DTC;
  const isUS = clusterName === Clusters.Restricted_US;

  const value = useMemo(
    () => ({
      clusterName,
      isDTC,
      isUS,
    }),
    [clusterName, isDTC, isUS]
  );

  return <ClusterContext.Provider value={value}>{children}</ClusterContext.Provider>;
};

const useClusterContext = (): ClusterContextData => {
  const context = useContext(ClusterContext);

  if (!Object.keys(context).length) {
    throw new Error('useClusterContext must be used within a ClusterProvider');
  }

  return context;
};

export { ClusterProvider, useClusterContext };
