import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllCountries } from 'domains/Countries';
import { PatchStore } from 'domains/Stores';

export enum EndpointsToLog {
  updateVendorStores = 'updateVendorStores',
}

interface LogAssociateDissociate {
  vendorId: string;
  vendorName: string;
  stores: PatchStore[];
}

export interface LogUpdateVendorStores {
  endpoint: EndpointsToLog.updateVendorStores;
  data: LogAssociateDissociate;
  country: AllCountries;
}

export type LogData =
  | LogUpdateVendorStores
  | {
      endpoint: null;
      data: undefined;
      country?: AllCountries;
    };

const initialState = {
  endpoint: null,
  data: undefined,
  country: undefined,
} as LogData;

export const logSlice = createSlice({
  initialState,
  name: 'log',
  reducers: {
    startLog: (state, { payload }: PayloadAction<LogData>) => {
      state.endpoint = payload.endpoint;
      state.data = payload.data;
      state.country = payload.country;
    },
    resetLog: (state) => {
      state.endpoint = null;
      state.data = undefined;
      state.country = undefined;
    },
  },
});

export default logSlice.reducer;
export const { startLog, resetLog } = logSlice.actions;
