import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Prompt } from 'react-router-dom';
import { useFooterButtons } from '@company-settings/hooks';
import { Button, Heading, Modal, Paragraph } from '@hexa-ui/components';
import { settingsCanceled } from 'tracking/generated';
import { ButtonGrid, HeaderCover, ModalContainer } from './CancellationModal.styles';

export const CancellationModal = (): JSX.Element => {
  const { isSaveButtonEnabled, isCancelButtonEnabled } = useFooterButtons();
  const hasUnsavedChanges = useMemo(() => isSaveButtonEnabled || isCancelButtonEnabled, [
    isSaveButtonEnabled,
    isCancelButtonEnabled,
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    return false;
  };

  const handleKeepEditing = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isCancelButtonEnabled && !isSaveButtonEnabled) {
      setIsModalOpen(false);
    }
  }, [isCancelButtonEnabled, isSaveButtonEnabled]);

  useEffect(() => {
    const mainContentElement = document.querySelector('#mfe-content');
    const companySettingsElement = document.querySelector('#company-settings');
    const allButtons = Array.from(
      mainContentElement?.querySelectorAll('button') as ArrayLike<HTMLButtonElement>
    );
    const filteredButtons = allButtons.filter(
      (button) => !companySettingsElement?.contains(button)
    );

    const cancelButton = document.querySelector('#cancel-button') as HTMLButtonElement;

    const allClickableElements = [...filteredButtons, cancelButton];
    if (hasUnsavedChanges) {
      allClickableElements.forEach((element) => {
        element?.addEventListener('mousedown', handleOpenModal);
        element?.addEventListener('click', handleOpenModal);
        element?.addEventListener('keydown', handleOpenModal);
      });
    }
    return () => {
      allClickableElements.forEach((element) => {
        element?.removeEventListener('mousedown', handleOpenModal);
        element?.removeEventListener('click', handleOpenModal);
        element?.removeEventListener('keydown', handleOpenModal);
      });
    };
  }, [hasUnsavedChanges]);

  const ModalTitle = (
    <Heading size="H2">
      <FormattedMessage id="COMPANY_SETTINGS.CANCELLATION_MODAL_TITLE" />
    </Heading>
  );

  const ModalActions = (
    <ButtonGrid>
      <Modal.Cancel>
        <Button data-testid="keep-editing-button" onClick={handleKeepEditing} variant="secondary">
          <FormattedMessage id="COMPANY_SETTINGS.CANCELLATION_MODAL_KEEP_EDITING_BTN" />
        </Button>
      </Modal.Cancel>

      <Modal.Action>
        <Button
          data-testid="proceed-button"
          type="reset"
          onClick={() => settingsCanceled({ is_settings_cancelled: true })}
        >
          <FormattedMessage id="COMPANY_SETTINGS.CANCELLATION_MODAL_PROCEED_BUTTON" />
        </Button>
      </Modal.Action>
    </ButtonGrid>
  );

  return (
    <>
      <Prompt when={hasUnsavedChanges} message={handleOpenModal} />
      <ModalContainer>
        <Modal.Root open={isModalOpen} title={ModalTitle} actions={ModalActions}>
          <Paragraph>
            <FormattedMessage id="COMPANY_SETTINGS.CANCELLATION_MODAL_CONTENT" />
          </Paragraph>
        </Modal.Root>
      </ModalContainer>
      {hasUnsavedChanges && (
        <HeaderCover
          role="button"
          data-testid="stop-upload-header-cover"
          aria-label="Header Cover"
          onMouseDown={handleOpenModal}
          tabIndex={0}
        />
      )}
    </>
  );
};
