import { CompanySettingsV2Form, UpdateCompanySettingsV2Params } from '@company-settings/types';
import Api from 'Api/Api';
import type { CompanyBFFApiError, IGenericDataWithError, IRequest } from 'Api/Api.d';
import { COMPANY_MANAGEMENT_BFF_BASE_URL } from 'config/constants';
import { andThen, pipe } from 'ramda';

const buildUrl = (
  settings: CompanySettingsV2Form,
  country?: string
): UpdateCompanySettingsV2Params => ({
  url: `${COMPANY_MANAGEMENT_BFF_BASE_URL}/v2/vendors/${settings.vendorId}/settings`,
  settings,
  country,
});

const updateCompanySettings = async ({
  url,
  settings,
  country,
}: UpdateCompanySettingsV2Params): Promise<IGenericDataWithError<void | CompanyBFFApiError>> => {
  const requestData: IRequest = {
    url,
    body: settings,
    config: { headers: { country } },
  };

  return Api.patch(requestData);
};

const handleResponse = (response: IGenericDataWithError<void | CompanyBFFApiError>): void => {
  const { hasError, response: errorResponse } = response;

  if (hasError && errorResponse) {
    throw errorResponse.data;
  }
};

export const patchCompanySettingsV2 = pipe(
  buildUrl,
  updateCompanySettings,
  andThen(handleResponse)
);
