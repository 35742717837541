import { Heading } from '@hexa-ui/components';
import React from 'react';

import type { HeadingProps } from '@core/components/typography/types';

export const H5 = ({ children, ...props }: HeadingProps): JSX.Element => (
  <Heading size="H5" {...props}>
    {children}
  </Heading>
);
