import { useMemo } from 'react';
import { useHasPermission } from 'admin-portal-shared-services';
import permissions from 'config/permissions';
import useIsUS from 'hooks/useIsUS';

export const useShowOrderNotifications = (): boolean => {
  const isUS = useIsUS();
  const userHasScopes = useHasPermission(
    [
      permissions.CompanySettings.OrderNotification.READ,
      permissions.CompanySettings.OrderNotification.WRITE,
    ],
    'OR'
  );

  const hasPermissionToAccess = useMemo(() => isUS && userHasScopes, [isUS, userHasScopes]);

  return hasPermissionToAccess;
};
