import { PaymentMethodTypes } from '@company-settings/enums';
import type {
  UseGenericPaymentMethodProps,
  UseGenericPaymentMethodResult,
} from '@company-settings/hooks/types';
import { useCompanySettingsFormV2 } from '@company-settings/hooks/useCompanySettingsFormV2';
import type { CheckoutSettingsV2, GenericPaymentMethod } from '@company-settings/types';
import { useField } from 'formik';
import { hasValue } from 'utils';

const getValues = (
  type: PaymentMethodTypes,
  checkoutSettings?: CheckoutSettingsV2
): GenericPaymentMethod | undefined => {
  const paymentMethods = checkoutSettings?.paymentMethods ?? [];

  return paymentMethods.find((paymentMethod) => paymentMethod.type === type);
};

export const useGenericPaymentMethod = ({
  type,
  paymentIndex,
  onSelectionChange,
}: UseGenericPaymentMethodProps): UseGenericPaymentMethodResult => {
  const fieldNamePrefix = `checkoutSettings.paymentMethods[${paymentIndex}]`;
  const { initialValues } = useCompanySettingsFormV2();
  const [, paymentMethodMeta, paymentMethodHelpers] = useField(fieldNamePrefix);
  const values = getValues(type, initialValues.checkoutSettings);
  const isEnabled = Boolean(paymentMethodMeta.value?.enabled ?? values?.enabled);

  const handleSelectionChange = (selected: boolean) => {
    const previousData = selected ? paymentMethodMeta.value : values;
    paymentMethodHelpers.setValue({
      ...previousData,
      type,
      enabled: selected,
    });

    if (hasValue(onSelectionChange)) {
      onSelectionChange(selected);
    }
  };

  return {
    fieldNamePrefix,
    isEnabled,
    values,
    handleSelectionChange,
  };
};
