import type { CompanySettingsV2, ParamsRequest } from '@company-settings/types';
import Api from 'Api/Api';
import type { CompanyBFFApiError, IGenericDataWithError, IRequest } from 'Api/Api.d';
import { COMPANY_MANAGEMENT_BFF_BASE_URL } from 'config/constants';
import { andThen, pipe } from 'ramda';

const buildUrl = (vendorId: string, country?: string): ParamsRequest => ({
  url: `${COMPANY_MANAGEMENT_BFF_BASE_URL}/v2/vendors/${vendorId}/settings`,
  country,
});

const createParams = ({ url, country }: ParamsRequest): IRequest => ({
  url,
  config: { headers: { country } },
});

const fetchCompanySettings = (
  request: IRequest
): Promise<IGenericDataWithError<CompanySettingsV2, CompanyBFFApiError>> => Api.get(request);

const extractResponse = (
  response: IGenericDataWithError<CompanySettingsV2, CompanyBFFApiError>
): CompanySettingsV2 => {
  const { data, hasError, response: errorResponse } = response;

  if (hasError && errorResponse) {
    throw errorResponse.data;
  }

  return data;
};

export const getCompanySettingsV2 = pipe(
  buildUrl,
  createParams,
  fetchCompanySettings,
  andThen(extractResponse)
);
