import { TypeToast, useToast as ToastService } from 'admin-portal-shared-services';

const notifyFactory = (type: TypeToast) => (message: string): void => {
  const notificationValues = {
    type,
    message,
  };

  ToastService().notify(notificationValues);
};

export const notifySuccess = notifyFactory(TypeToast.SUCCESS);
export const notifyError = notifyFactory(TypeToast.ERROR);
