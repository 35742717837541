import {
  ContainerCard,
  Content,
  FormsContainer,
} from '@company-settings/components/company-settings-tab/CompanySettingsTab.styles';
import { CancellationModal } from '@company-settings/components/company-settings-tab/cancellation-modal/CancellationModal';
import { EmptiesConfiguration } from '@company-settings/components/company-settings-tab/empties-configuration/EmptiesConfiguration';
import { FooterButtons } from '@company-settings/components/company-settings-tab/footer-buttons/FooterButtons';
import { OrderNotifications } from '@company-settings/components/company-settings-tab/order-notifications/OrderNotifications';
import { PayAndRedeem } from '@company-settings/components/company-settings-tab/pay-and-redeem/PayAndRedeem';
import {
  useCompanySettingsFormV2,
  useShowEmptiesConfiguration,
  useShowOrderNotifications,
  useShowPayAndRedeem,
} from '@company-settings/hooks';
import { Divider } from '@hexa-ui/components';
import { Form, Formik } from 'formik';
import React from 'react';

export const CompanySettingsTab = (): JSX.Element => {
  const showOrderNotifications = useShowOrderNotifications();
  const showPayAndRedeem = useShowPayAndRedeem();
  const showEmptiesConfiguration = useShowEmptiesConfiguration();

  const {
    initialValues,
    handleSubmit,
    validationSchema,
    isLoading,
    isUpdating,
    orderNotificationOptions,
    availablePaymentMethods,
  } = useCompanySettingsFormV2();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <CancellationModal />
        <ContainerCard
          data-testid="company-settings"
          id="company-settings"
          elevated="small"
          border="medium"
        >
          <Content>
            <FormsContainer data-testid="forms-container" xs={12}>
              {showPayAndRedeem && (
                <PayAndRedeem
                  availablePaymentMethods={availablePaymentMethods}
                  isLoading={isLoading}
                />
              )}
              {showEmptiesConfiguration && (
                <>
                  <Divider orientation="horizontal" />
                  <EmptiesConfiguration />
                </>
              )}
              {showOrderNotifications && (
                <>
                  <Divider orientation="horizontal" />
                  <OrderNotifications orderNotificationOptions={orderNotificationOptions} />
                </>
              )}
            </FormsContainer>
          </Content>
          <FooterButtons isUpdating={isUpdating} />
        </ContainerCard>
      </Form>
    </Formik>
  );
};
