import { styled } from 'theme';

import { Radio as CoreRadio, RadioGroup as CoreRadioGroup } from '@core/components';

export const RadioGroup = styled(CoreRadioGroup, {
  display: 'flex',
  flexFlow: 'row',
  gap: '$4',
});

export const Radio = styled(CoreRadio, {
  '&&': {
    margin: 'unset',
  },
});
