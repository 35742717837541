import { Info } from '@hexa-ui/icons';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Container,
  InfoTooltip,
  PaymentInfoContainer,
  Title,
} from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/cash-payment-method/CashPaymentMethod.styles';
import { PaymentMethodWrapper } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/payment-method-wrapper/PaymentMethodWrapper';
import type { PaymentMethodComponentProps } from '@company-settings/components/types';
import { PaymentMethodTypes } from '@company-settings/enums';
import { useCashPaymentMethod } from '@company-settings/hooks';
import { NumberField } from '@core/components';

export const CashPaymentMethod = ({ paymentIndex }: PaymentMethodComponentProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    fieldNamePrefix,
    handleSelectionChange,
    isPaymentCheckboxEnabled,
    isMinimumOrderEnabled,
  } = useCashPaymentMethod({ paymentIndex });

  return (
    <PaymentMethodWrapper
      type={PaymentMethodTypes.Cash}
      paymentIndex={paymentIndex}
      onSelectionChange={handleSelectionChange}
      disableSelection={!isPaymentCheckboxEnabled}
    >
      <Container>
        <PaymentInfoContainer>
          <Title>
            <FormattedMessage id="COMPANY_SETTINGS.CASH_PAYMENT_METHOD_NAME" />
          </Title>
          <InfoTooltip
            placement="right"
            text={formatMessage({
              id: 'COMPANY_SETTINGS.CASH_PAYMENT_METHOD_DESCRIPTION',
            })}
          >
            <Info size="medium" fr="100%" />
          </InfoTooltip>
        </PaymentInfoContainer>
        <NumberField
          type="number"
          id={`cash-payment-method-${paymentIndex}`}
          name={`${fieldNamePrefix}.minimumOrder.value`}
          label={formatMessage({ id: 'COMPANY_SETTINGS.PAYMENT_METHOD_INPUT_LABEL' })}
          prefix="$"
          width="100%"
          min="0.00"
          step=".01"
          disabled={!isMinimumOrderEnabled}
          pattern="[\d\.,]"
        />
      </Container>
    </PaymentMethodWrapper>
  );
};
