import { Card, Grid, Heading as HeadingDS } from '@hexa-ui/components';
import { styled } from 'theme';

const { Item } = Grid;

export const ContainerCard = styled(Card, {
  display: 'flex',
  width: '100%',
  height: '100%',
  padding: '$10 $6',
  position: 'relative',
  button: {
    margin: 0,
  },
  '&&&': {
    flexDirection: 'column',
  },
});

export const Content = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
});

export const FormsContainer = styled(Item, {
  flexDirection: 'column',

  '&&': {
    paddingLeft: '$4',
  },
});

export const Heading = styled(HeadingDS, {
  marginBottom: '$0.5',
});
