import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonsContainer } from '@company-settings/components/company-settings-tab/footer-buttons/FooterButtons.styles';
import { useFooterButtons } from '@company-settings/hooks';
import { PrimaryButton, SecondaryButton } from '@core/components';

export type FooterButtonsProps = {
  isUpdating?: boolean;
};

export const FooterButtons = ({ isUpdating }: FooterButtonsProps): JSX.Element => {
  const { isSaveButtonEnabled, isCancelButtonEnabled } = useFooterButtons();

  return (
    <ButtonsContainer data-testid="buttons-container">
      <SecondaryButton
        id="cancel-button"
        type="reset"
        disabled={!isCancelButtonEnabled || isUpdating}
      >
        <FormattedMessage id="COMPANY_SETTINGS.CANCEL" />
      </SecondaryButton>

      <PrimaryButton
        data-testid="save-button"
        type="submit"
        disabled={!isSaveButtonEnabled || isUpdating}
        isLoading={isUpdating}
      >
        <FormattedMessage id="COMPANY_SETTINGS.SAVE_CHANGES" />
      </PrimaryButton>
    </ButtonsContainer>
  );
};
