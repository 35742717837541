import { useHasPermission } from 'admin-portal-shared-services';
import permissions from 'config/permissions';

export const useSaveSettingsPermission = (): boolean => {
  const hasWritePermissions = useHasPermission(
    [
      permissions.CompanySettings.OrderNotification.WRITE,
      permissions.CompanySettings.PaymentMethod.WRITE,
    ],
    'OR'
  );

  return hasWritePermissions;
};
