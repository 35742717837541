import { OrderNotificationOptions } from '@company-settings/enums';
import { GenericPaymentMethod } from '@company-settings/types';
import { always, ifElse, isNil } from 'ramda';
import { PaymentMethodsItem, SettingsUpdated } from 'tracking/generated';
import { Action, Category, OrderNotificationType } from 'tracking/types.deprecated';
import { GetSettingsUpdatedProps, UseParseSegmentEventProps } from './types.d';

export const useParseSegmentEventProps = (): UseParseSegmentEventProps => ({
  getSettingsUpdatedProps,
});

export const getSettingsUpdatedProps = ({
  values,
  preparedValues,
}: GetSettingsUpdatedProps): SettingsUpdated => {
  const removeDefaultCash = values.checkoutSettings?.paymentMethods?.filter(
    (paymentMethod) => !isDefaultCash(paymentMethod)
  );

  const paymentMethods: PaymentMethodsItem[] =
    removeDefaultCash?.map((paymentMethod) => ({
      id: getStringOrNull(paymentMethod.id),
      enabled: paymentMethod.enabled,
      type: paymentMethod.type,
      label: paymentMethod.label,
      description: paymentMethod.description,
      action: getAction(paymentMethod?.id, preparedValues?.checkoutSettings?.paymentMethods),
      category: getCategory(paymentMethod.type, paymentMethod.label),
      days_to_expire: getNumberOrNull(paymentMethod.expiresInDays),
    })) ?? [];

  return {
    payment_methods: paymentMethods,
    has_new_payment_method: paymentMethods?.some(
      (paymentMethod) => paymentMethod.action === Action.Insert
    ),
    minimum_order_amount: getNumberOrNull(values.checkoutSettings?.minimumOrder?.value),
    club_b_participation: getBooleanOrNull(
      values.checkoutSettings?.zeroOrderSettings?.redemptionsEnabled
    ),
    empties: getBooleanOrNull(values.checkoutSettings?.zeroOrderSettings?.emptiesEnabled),
    order_notification_type: getOrderNotificationType(
      values.orderNotification?.orderNotificationEmails
    ),
    order_notification_bcc_email: !!values.orderNotification?.bccEmails?.length,
  };
};

const getCategory = (type: string, label: string) => {
  if (type === 'CASH' && label !== 'Cash') return Category.Custom;
  return Category.Available;
};

const isDefaultCash = (paymentMethod?: GenericPaymentMethod) =>
  !paymentMethod?.id &&
  paymentMethod?.type === 'CASH' &&
  paymentMethod.label === 'Cash' &&
  !paymentMethod.enabled;

const getAction = (id?: string, newPaymentMethods?: GenericPaymentMethod[]) => {
  if (!id) return Action.Insert;
  if (newPaymentMethods?.some((newPaymentMethod) => newPaymentMethod?.id === id)) {
    return Action.Update;
  }
  return (null as unknown) as Action;
};

const getOrderNotificationType = (orderNotificationEmails?: OrderNotificationOptions) =>
  ((orderNotificationEmails as unknown) as OrderNotificationType) || null;

const getStringOrNull = ifElse(isNil, always(null), String);

const getNumberOrNull = ifElse(isNil, always(null), Number);

const getBooleanOrNull = ifElse(isNil, always(null), Boolean);
