import { AvailablePaymentMethod } from '@company-settings/types';
import { Button, ButtonProps, Dialog, Heading, Paragraph, Select } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  ButtonContainer,
  ButtonGroup,
  SelectContainer,
  StyledDialog,
} from './PaymentManagerModal.style';

export interface PaymentManagerModalProps {
  confirmModal: (option: AvailablePaymentMethod) => void;
  availablePaymentMethods?: Array<AvailablePaymentMethod>;
  toggleModal: (modal: 'modalInfo' | 'customPayment') => void;
  isOpen: boolean;
}

const PlusIcon = (): JSX.Element => <Plus fr={1} size="medium" />;

function PaymentManagerModal({
  isOpen,
  confirmModal,
  toggleModal,
  availablePaymentMethods = [],
}: PaymentManagerModalProps): JSX.Element {
  const { formatMessage } = useIntl();

  const [selectedOption, setSelectedOption] = React.useState('');

  const onSelectOption = (option: string) => {
    setSelectedOption(option);
  };

  const handleCancelModal = () => {
    setSelectedOption('');
    toggleModal('modalInfo');
  };

  const handleConfirmModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const option = availablePaymentMethods.find((method) => method.type === selectedOption);

    confirmModal(option as AvailablePaymentMethod);
    setSelectedOption('');
    toggleModal('modalInfo');
  };

  const handleChangeModal = () => {
    toggleModal('modalInfo');
    toggleModal('customPayment');
  };

  const modalBtns = [
    {
      title: formatMessage({
        id: 'COMPANY_SETTINGS.PAYMENT_MANAGER_MODAL_CANCEL',
      }),
      variant: 'secondary' as ButtonProps['variant'],
      action: handleCancelModal,
      type: 'button' as const,
      disabled: false,
    },
    {
      title: formatMessage({
        id: 'COMPANY_SETTINGS.PAYMENT_MANAGER_MODAL_CONFIRM',
      }),
      variant: 'primary' as ButtonProps['variant'],
      action: handleConfirmModal,
      type: 'submit' as const,
      disabled: selectedOption.length === 0,
    },
  ];

  const actionButtons = () => (
    <ButtonGroup>
      {modalBtns.map((btn, index) => {
        const key = `${btn.title}-${index}`;
        return (
          <Button
            key={key}
            data-testid={key}
            size="medium"
            variant={btn.variant}
            onClick={btn.action}
            disabled={btn.disabled}
            type={btn.type}
          >
            {btn.title}
          </Button>
        );
      })}
    </ButtonGroup>
  );

  return (
    <StyledDialog>
      <Dialog.Root
        open={isOpen}
        onClose={handleCancelModal}
        title={
          <Heading size="H2">
            {formatMessage({
              id: 'COMPANY_SETTINGS.PAYMENT_MANAGER_MODAL_TITLE',
            })}
          </Heading>
        }
        actions={actionButtons()}
      >
        <Paragraph size="basis">
          {formatMessage({
            id: 'COMPANY_SETTINGS.PAYMENT_MANAGER_MODAL_DESCRIPTION',
          })}
        </Paragraph>

        <SelectContainer>
          <Select.Root
            data-testid="select-payment-method"
            label={formatMessage({
              id: 'COMPANY_SETTINGS.PAYMENT_MANAGER_MODAL_EXISTING_LABEL',
            })}
            disabled={availablePaymentMethods.length === 0}
            onChange={onSelectOption}
            value={selectedOption || undefined}
            placeholder={formatMessage({
              id:
                availablePaymentMethods.length === 0
                  ? 'COMPANY_SETTINGS.PAYMENT_MANAGER_MODAL_NONE_AVAILABLE_METHODS'
                  : 'COMPANY_SETTINGS.PAYMENT_MANAGER_MODAL_SELECT_PLACEHOLDER',
            })}
          >
            {availablePaymentMethods.map((option) => (
              <Select.Option
                key={`${option.type}_${option.label}`}
                data-testid="payment-method-option"
                value={option.type}
              >
                {option.label}
              </Select.Option>
            ))}
          </Select.Root>
        </SelectContainer>

        <ButtonContainer>
          <Paragraph weight="semibold" size="small">
            {formatMessage({
              id: 'COMPANY_SETTINGS.PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_LABEL',
            })}
          </Paragraph>

          <Button
            type="button"
            icon={PlusIcon}
            leading
            size="medium"
            variant="secondary"
            onClick={handleChangeModal}
          >
            {formatMessage({
              id: 'COMPANY_SETTINGS.PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_BTN',
            })}
          </Button>
        </ButtonContainer>
      </Dialog.Root>
    </StyledDialog>
  );
}

export default PaymentManagerModal;
