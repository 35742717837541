/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox as HexaCheckbox } from '@hexa-ui/components';
import { useField } from 'formik';
import { isNil } from 'ramda';
import React, { useEffect } from 'react';

import type { CheckboxProps } from '@core/components/fields/types';

export const Checkbox = ({
  name,
  value,
  onCheckedChange,
  ...props
}: CheckboxProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const isChecked = props.checked ?? value === meta.value;

  const handleCheckedChange = (checked: boolean) => {
    const newValue = checked ? value : undefined;

    helpers.setValue(newValue);
    helpers.setTouched(!!newValue);

    if (onCheckedChange) {
      onCheckedChange(checked);
    }
  };

  useEffect(() => {
    if (isNil(meta.value)) {
      handleCheckedChange(false);
    }
  }, [meta.value]);

  return (
    <HexaCheckbox {...field} {...props} onCheckedChange={handleCheckedChange} checked={isChecked} />
  );
};
