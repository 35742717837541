import { useIntl } from 'react-intl';
import type { UseUpsertCompanySettingsV2Result } from '@company-settings/hooks/types';
import type { CompanySettingsV2Form } from '@company-settings/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchCompanySettingsV2 } from 'Api/query/patchCompanySettingsV2';
import { useStore } from 'effector-react';
import { usePermissions } from 'hooks/usePermissions';
import VendorDetailStore from 'stores/vendorDetail/VendorDetailStore';
import { notifyError, notifySuccess } from 'utils/notification';
import { queriesKeys } from './queriesKeys';

export const useUpsertCompanySettingsV2 = (): UseUpsertCompanySettingsV2Result => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const { vendor } = useStore(VendorDetailStore);
  const { canEditPaymentAndOrders } = usePermissions();
  const country = vendor?.country;

  const mutation = useMutation({
    mutationFn: (settings: CompanySettingsV2Form) => patchCompanySettingsV2(settings, country),
    onSuccess: (_, { vendorId }: Pick<CompanySettingsV2Form, 'vendorId'>) => {
      queryClient.invalidateQueries(queriesKeys.settingsV2(vendorId, country));
      notifySuccess(formatMessage({ id: 'SUCCESS_MESSAGES.CHANGES_SAVED_SUCCESSFULLY' }));
    },
    onError: (_, { vendorId }: Pick<CompanySettingsV2Form, 'vendorId'>) => {
      queryClient.invalidateQueries(queriesKeys.settingsV2(vendorId, country));
      notifyError(formatMessage({ id: 'ERROR_MESSAGES.UNABLE_TO_SAVE_CHANGES' }));
    },
  });

  const upsertCompanySettings = (settings: CompanySettingsV2Form) =>
    canEditPaymentAndOrders && mutation.mutate(settings);

  return {
    upsertCompanySettings,
    isError: mutation.isError,
    isUpdating: mutation.isLoading,
  };
};
