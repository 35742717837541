import { useIntl } from 'react-intl';

export interface UseGetNumberPartsResult {
  currencySymbol: string;
  decimalSymbol: string;
}

export const useGetCurrencyParts = (): UseGetNumberPartsResult => {
  const { formatNumberToParts, formatMessage } = useIntl();
  const result = {} as UseGetNumberPartsResult;
  const parts = formatNumberToParts(0, {
    style: 'currency',
    currency: formatMessage({ id: 'FORMAT.CURRENCY' }),
    currencyDisplay: 'narrowSymbol',
  });
  parts.forEach((part) => {
    if (part.type === 'currency') result.currencySymbol = part.value;
    if (part.type === 'decimal') result.decimalSymbol = part.value;
  });
  return result;
};
