import { DEFAULT_LANGUAGE } from 'config/constants';
import { usePreferredLanguage } from 'hooks/usePreferredLanguage';
import React, { ReactNode } from 'react';
import { IntlConfig, IntlShape, RawIntlProvider, createIntl, createIntlCache } from 'react-intl';
import MessageMap from './i18n';
import enUS from './languages/en-US';
import es419 from './languages/es-419';
import ptBR from './languages/pt-BR';

interface IIntlProvider {
  children: ReactNode;
}

export const allMessages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es419,
};

export const intl = {} as IntlShape;

const IntlProvider = ({ children }: IIntlProvider): JSX.Element => {
  const userLanguage = usePreferredLanguage();
  const intlOptions = getIntlOptions(userLanguage);

  const cache = createIntlCache();
  const intlShape = createIntl(intlOptions, cache);
  Object.assign(intl, intlShape);

  return <RawIntlProvider value={intlShape}>{children}</RawIntlProvider>;
};

export const getIntlOptions = (userLanguage: string): IntlConfig => {
  const mergedMessages = mergeMessages((allMessages as unknown) as MessageMap, userLanguage);

  return { locale: userLanguage, messages: mergedMessages, defaultLocale: DEFAULT_LANGUAGE };
};

export const flattenObject = (ob: Record<string, unknown>): Record<string, string> => {
  const toReturn: { [key: string]: unknown } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i] as Record<string, unknown>);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn as Record<string, string>;
};

export const mergeMessages = (
  messagesInput: MessageMap,
  selectedLocale: string
): Record<string, string> => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
