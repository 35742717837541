import { TextArea } from '@hexa-ui/components';
import { useField } from 'formik';
import { isEmpty, map, pipe, reject, trim } from 'ramda';
import React, { FocusEvent } from 'react';
import { useIntl } from 'react-intl';

import { Container } from '@company-settings/components/company-settings-tab/order-notifications/email-list-field/EmailListField.styles';

const removeEmptySpaces = map(trim);
const removeEmptyValues = reject(isEmpty);

const prepareEmails = pipe(removeEmptySpaces, removeEmptyValues);

export const EmailListField = (): React.ReactElement => {
  const fieldName = 'orderNotification.bccEmails';
  const [field, , helpers] = useField(fieldName);
  const { formatMessage } = useIntl();

  const handleBlur = ({ currentTarget }: FocusEvent<HTMLTextAreaElement>) => {
    const emails = currentTarget.value.split(',');

    helpers.setValue(prepareEmails(emails));
  };

  return (
    <Container>
      <TextArea
        {...field}
        id="text-area-not-required"
        label={`${formatMessage({
          id: 'COMPANY_SETTINGS.ORDER_NOTIFICATIONS_EMAIL_LIST',
        })}`}
        required={false}
        width="100%"
        hint={formatMessage({
          id: 'COMPANY_SETTINGS.ORDER_NOTIFICATIONS_EMAIL_LIST_SUBTITLE',
        })}
        placeholder=""
        onBlur={handleBlur}
      />
    </Container>
  );
};
