export enum AccountTier {
  Gold = 'GOLD',
  Silver = 'SILVER',
  Null = 'null',
}

export enum ScreenName {
  CompanyManagement = 'COMPANY_MANAGEMENT',
}

export enum TabName {
  CompanyManagementDetails = 'COMPANY_MANAGEMENT_DETAILS',
  CompanyManagementDistributionCenter = 'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
  CompanyManagementSettings = 'COMPANY_MANAGEMENT_SETTINGS',
  CompanyManagementUser = 'COMPANY_MANAGEMENT_USER',
}

export enum Country {
  Ar = 'AR',
  Br = 'BR',
  Ca = 'CA',
  Cl = 'CL',
  Co = 'CO',
  Do = 'DO',
  Ec = 'EC',
  Hn = 'HN',
  Mx = 'MX',
  Pa = 'PA',
  Pe = 'PE',
  Py = 'PY',
  Sv = 'SV',
  Tz = 'TZ',
  Uy = 'UY',
  Za = 'ZA',
}

export enum FailureReason {
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
}

export enum ServiceModel {
  I1P = '1P',
  I1P3L = '1P-3L',
  I3P1L = '3P-1L',
  I3P3LSf = '3P-3L-SF',
  I3P3LSs = '3P-3L-SS',
  Null = 'null',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Null = 'null',
}

export enum AccessLevel {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Viewer = 'VIEWER',
  Null = 'null',
}

export enum ListType {
  CompanyList = 'COMPANY_LIST',
  UsersList = 'USERS_LIST',
}

export enum FilterCriteria {
  Country = 'COUNTRY',
}

export enum FilterValue {
  Argentina = 'ARGENTINA',
  Brazil = 'BRAZIL',
  Canada = 'CANADA',
  Chile = 'CHILE',
  Colombia = 'COLOMBIA',
  DominicanRepublic = 'DOMINICAN_REPUBLIC',
  Ecuador = 'ECUADOR',
  ElSalvador = 'EL_SALVADOR',
  Honduras = 'HONDURAS',
  Mexico = 'MEXICO',
  Panama = 'PANAMA',
  Paraguay = 'PARAGUAY',
  Peru = 'PERU',
  SouthAfrica = 'SOUTH_AFRICA',
  Tanzania = 'TANZANIA',
  Uruguay = 'URUGUAY',
}

export enum Action {
  Insert = 'INSERT',
  Update = 'UPDATE',
  Null = 'null',
}

export enum Category {
  Available = 'AVAILABLE',
  Custom = 'CUSTOM',
  Null = 'null',
}

export enum OrderNotificationType {
  AllSalesReps = 'ALL_SALES_REPS',
  AllSalesRepsSupervisor = 'ALL_SALES_REPS_SUPERVISOR',
  NoSalesRep = 'NO_SALES_REP',
  PrimarySalesRep = 'PRIMARY_SALES_REP',
  Null = 'null',
}
