import { onChangePaginationInterface } from 'components/Paginate/Paginate.d';
import { AccountTier } from 'domains/AccountTier';
import { AllCountries } from 'domains/Countries';
import { createEvent } from 'effector';
import { IVendor, IVendorList } from '../../domains/Vendor';

export const loadAllVendors = createEvent<null>('loadAllVendors');
export const loadAllVendorsDone = createEvent<IVendorList>('loadVendorsDone');
export const loadAllVendorsFail = createEvent<null>('loadVendorsFail');

export const selectCountry = createEvent<{
  selectedCountry: AllCountries;
  history: unknown;
}>('selectCountry');

export const changePagination = createEvent<{
  pagination: onChangePaginationInterface;
  history: unknown;
}>('changePagination');

export const changeSearchType = createEvent<{
  searchType: string;
  history: unknown;
}>('changeSearchType');

export const changeSearchValue = createEvent<{
  searchValue: string;
  history: unknown;
}>('changeSearchValue');

export const searchVendors = createEvent<{
  history: unknown;
}>('searchVendors');

export const resetSearch = createEvent('resetSearch');

export const createVendor = createEvent('createVendor');
export const createVendorDone = createEvent<{
  history: unknown;
}>('createVendorDone');
export const createVendorFail = createEvent('createVendorFail');

export const addNewVendor = createEvent<IVendor>('addNewVendor');

export const incrementTierFetchErrorTimes = createEvent('incrementTierFetchErrorTimes');
export const resetTierFetchErrorTimes = createEvent('resetTierFetchErrorTimes');

export const setAvailableTiers = createEvent<Array<AccountTier>>('setAvailableTiers');
