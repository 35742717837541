import { styled } from 'theme';

export const FileUploaderContainer = styled('div', {
  '& button': {
    '&[aria-label="Delete and clear"]': {
      display: 'none',
    },
    '&[aria-label="Edit"]': {
      '&&&': {
        margin: '0',
      },
    },
  },
  variants: {
    hasPermission: {
      false: {
        '& button': {
          display: 'none',
        },
        '& .dropzone-ui > section': {
          pointerEvents: 'none',
          paddingBottom: '$16',
          '& > section': {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '$2',
            '& > section': {
              display: 'none',
            },
          },
        },
      },
    },
  },
});
