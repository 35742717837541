enum FeatureTogglesV2 {
  SHOW_COMPANY_SETTINGS_TAB = 'vendor-management-show-company-settings-tab',
  SHOW_EMPTIES_FEATURE = 'vendor-management-empties-feature',
  SHOW_MANAGE_PAYMENT_METHODS = 'company-ddc-new-payment-methods',
  SHOW_STORES = 'company-ddc-stores',
  SHOW_VENDORS_GROUP = 'company-ddc-vendors-group',
  ENABLE_POLYGON_FEATURE = 'vendor-management-polygon-feature',
  SHOW_MANAGE_STORES = 'company-manage-stores',
}

export default FeatureTogglesV2;
