import { PaymentMethodTypes } from '@company-settings/enums';
import {
  AvailablePaymentMethod,
  GenericPaymentMethod,
  Translations,
} from '@company-settings/types';
import { Button, ButtonProps, Dialog, Heading } from '@hexa-ui/components';
import { DEFAULT_LANGUAGE, paymentLabels, paymentLanguages } from 'config/constants';
import { clone, prepend } from 'ramda';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { hasValue } from 'utils';
import { ButtonGroup, InputsContainer, Paragraph, StyledDialog } from './CustomPaymentModal.styles';
import { TranslationInput } from './translation-input/TranslationInput';

export interface CustomPaymentModalProps {
  country?: string;
  isOpen: boolean;
  toggleModal: (modal: 'modalInfo' | 'customPayment') => void;
  addToPaymentMethods: (option: AvailablePaymentMethod | GenericPaymentMethod) => void;
}

function CustomPaymentModal({
  country,
  isOpen,
  toggleModal,
  addToPaymentMethods,
}: CustomPaymentModalProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [values, setValues] = useState<Record<string, string>>({});

  const countryTranslations = country
    ? paymentLanguages[country as keyof typeof paymentLanguages] || []
    : [];
  const allTranslations = prepend(DEFAULT_LANGUAGE, countryTranslations);

  const isAnyValueEmpty =
    Object.keys(values).length === 0 ||
    Object.values(values).some(
      (value, _, array) => !hasValue(value) || array.length < allTranslations.length
    );

  const getLanguageData = (iso: string) =>
    paymentLabels(formatMessage).find(({ languages }) => languages.includes(iso));

  const otherEnglishLanguages = countryTranslations.filter((iso) => {
    const language = getLanguageData(iso);
    return paymentLabels(formatMessage)[0].label === language?.label;
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const iso = e.target.dataset.iso as string;
    const otherEnglishValues = otherEnglishLanguages.length
      ? otherEnglishLanguages.reduce<Record<string, string>>((acc, curr) => {
          acc[curr] = e.target.value;
          return acc;
        }, {})
      : {};
    setValues((prevValues) => ({ ...prevValues, [iso]: e.target.value, ...otherEnglishValues }));
  };

  const getTranslations = (): Translations => {
    const translations = clone<Translations | Record<string, string>>(values);
    for (const key in translations) {
      if (key === DEFAULT_LANGUAGE) {
        delete translations[key];
      } else {
        (translations as Translations)[key] = { label: (translations[key] as string).trim() };
      }
    }
    return translations as Translations;
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const customPaymentMethod = {
      type: PaymentMethodTypes.Cash,
      enabled: true,
      label: values[DEFAULT_LANGUAGE].trim(),
      description: '',
      translations: getTranslations(),
    } as GenericPaymentMethod;

    addToPaymentMethods(customPaymentMethod);
    toggleModal('customPayment');
    setValues({});
  };

  const handleBack = () => {
    toggleModal('customPayment');
    toggleModal('modalInfo');
  };

  const modalBtns = [
    {
      title: formatMessage({
        id: 'COMPANY_SETTINGS.CUSTOM_PAYMENT_MODAL_BACK',
      }),
      variant: 'secondary' as ButtonProps['variant'],
      disabled: false,
      type: 'button' as const,
      action: handleBack,
    },
    {
      title: formatMessage({
        id: 'COMPANY_SETTINGS.CUSTOM_PAYMENT_MODAL_CONFIRM',
      }),
      variant: 'primary' as ButtonProps['variant'],
      disabled: isAnyValueEmpty,
      type: 'submit' as const,
      action: handleConfirm,
    },
  ];

  const actionButtons = () => (
    <ButtonGroup>
      {modalBtns.map((btn) => (
        <Button
          key={btn.title}
          size="medium"
          variant={btn.variant}
          onClick={btn.action}
          disabled={btn.disabled}
          type={btn.type}
        >
          {btn.title}
        </Button>
      ))}
    </ButtonGroup>
  );

  return (
    <StyledDialog>
      <Dialog.Root
        open={isOpen}
        onClose={handleBack}
        title={
          <Heading size="H2">
            {formatMessage({
              id: 'COMPANY_SETTINGS.CUSTOM_PAYMENT_MODAL_TITLE',
            })}
          </Heading>
        }
        actions={actionButtons()}
      >
        <Paragraph size="basis">
          {formatMessage({
            id: 'COMPANY_SETTINGS.CUSTOM_PAYMENT_MODAL_DESCRIPTION',
          })}
        </Paragraph>

        <InputsContainer>
          {allTranslations?.map((iso) => {
            const language = getLanguageData(iso);
            const isEnglishLanguage = otherEnglishLanguages.includes(iso);

            return (
              <TranslationInput
                key={iso}
                iso={iso}
                language={language}
                onChange={onChange}
                value={values[iso]}
                hidden={isEnglishLanguage}
                hasTooltip={iso !== DEFAULT_LANGUAGE}
              />
            );
          })}
        </InputsContainer>
      </Dialog.Root>
    </StyledDialog>
  );
}

export default CustomPaymentModal;
