import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { RedeemOptions } from '@company-settings/enums';
import { useShowManagePaymentMethods } from '@company-settings/hooks';
import type { UseRedeemResult } from '@company-settings/hooks/types';
import { RedeemOption } from '@company-settings/types';
import { ChangeEvent } from 'react';

export const useRedeem = (): UseRedeemResult => {
  const shouldUseZeroOrderSettings = useShowManagePaymentMethods();
  const zeroOrderSettingsFieldName = 'checkoutSettings.zeroOrderSettings.redemptionsEnabled';
  const orderPaidFullyWithPointsFieldName = 'checkoutSettings.orderPaidFullyWithPoints';

  const fieldName = shouldUseZeroOrderSettings
    ? zeroOrderSettingsFieldName
    : orderPaidFullyWithPointsFieldName;

  const { formatMessage } = useIntl();
  const [, meta, helper] = useField(fieldName);

  const redeemChosenOption = meta.value;

  const YES = formatMessage({ id: `COMPANY_SETTINGS.REDEEM_OPTION_YES` });
  const NO = formatMessage({ id: `COMPANY_SETTINGS.REDEEM_OPTION_NO` });

  const redeemOptions: Array<RedeemOption> = [
    {
      label: YES,
      value: Boolean(RedeemOptions.YES),
    },
    {
      label: NO,
      value: Boolean(RedeemOptions.NO),
    },
  ];

  const handlerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    const isRedeemOptionYes = value === String(Boolean(RedeemOptions.YES));

    helper.setValue(isRedeemOptionYes);
  };

  return {
    handlerChange,
    redeemChosenOption,
    redeemOptions,
    redeemRadioGroupName: fieldName,
  };
};
