/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import * as faker from 'faker';
import { cloneDeep } from 'lodash';
import { Model, Response } from 'miragejs';
import { VENDOR_APP_TIER_BASE_URL, VENDOR_APP_TIER_BASE_URL_V2 } from '../../config/constants';

const HttpCodes = {
  notAuthorized: 401,
  forbidden: 403,
  notFound: 404,
  UnprocessableEntity: 422,
  internalServerError: 500,
};

const VendorServiceErrors = {
  unauthorized_access_401: 'unauthorized_access_401',
  access_denied_403: 'access_denied_403',
  not_found_404: 'not_found_404',
  sever_error_500: 'sever_error_500',
};

const Vendor = Model.extend({});

const vendorsRoutes = (context) => {
  function showErrorResponse(errorId) {
    let status;
    switch (errorId) {
      case VendorServiceErrors.access_denied_403:
        status = HttpCodes.forbidden;
        break;
      case VendorServiceErrors.not_found_404:
        status = HttpCodes.notFound;
        break;
      case VendorServiceErrors.unauthorized_access_401:
        status = HttpCodes.notAuthorized;
        break;
      default:
        status = HttpCodes.internalServerError;
        break;
    }
    return new Response(status, { some: 'header' }, { error: [errorId] });
  }

  const patchHandler = async (schema, request) => {
    if (!request || !request.requestBody) {
      return undefined;
    }
    const { id: vendorId } = request.params;
    const attrs = JSON.parse(request.requestBody);
    const currentModel = cloneDeep(schema.all('vendor').models[0].attrs);

    if (Object.keys(VendorServiceErrors).includes(vendorId)) {
      return showErrorResponse(vendorId);
    }

    return { ...currentModel, ...attrs, vendorId };
  };

  const postHandler = async (schema, request) => {
    if (!request || !request.requestBody) {
      return undefined;
    }
    const attrs = JSON.parse(request.requestBody);
    attrs.vendorId = faker.datatype.uuid();
    attrs.createdAt = Date.now();
    attrs.updatedAt = Date.now();

    schema.create('vendor', attrs);

    return attrs;
  };

  const getAllHandler = async (schema, request) => {
    const { page, pageSize } = request.queryParams;

    return {
      content: schema.vendors.all().models.map((model) => model.attrs),
      pagination: {
        pageNumber: page,
        pageSize,
        pages: 10,
        total: 100,
      },
    };
  };

  const getByIdHandler = async (schema, request) => {
    const { vendorId } = request.params;
    const vendor = schema.findBy('vendor', {
      vendorId,
    });
    return vendor.attrs;
  };

  context.patch(`/api/items/:skuId`, async (schema, request) => {
    if (!request || !request.requestBody) {
      return;
    }
    const { skuId } = request.params;

    schema.create('skuItem', skuId);
  });

  context.get(`${VENDOR_APP_TIER_BASE_URL}/:vendorId`, getByIdHandler);

  context.get(`${VENDOR_APP_TIER_BASE_URL_V2}`, getAllHandler);
  context.get(`${VENDOR_APP_TIER_BASE_URL}`, getAllHandler);

  context.post(`${VENDOR_APP_TIER_BASE_URL}`, postHandler);

  context.patch(`${VENDOR_APP_TIER_BASE_URL}/:id`, patchHandler);
};

export default vendorsRoutes;
