import { useLogService } from 'admin-portal-shared-services';
import { isNil } from 'ramda';

import { BankDepositPaymentMethod } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/bank-deposit-payment-method/BankDepositPaymentMethod';
import { BankTransferPaymentMethod } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/bank-transfer-payment-method/BankTransferPaymentMethod';
import { BankSlipPaymentMethod } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/bankslip-payment-method/BankSlipPaymentMethod';
import { CashDepositPaymentMethod } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/cash-deposit-payment-method/CashDepositPaymentMethod';
import { CashPaymentMethod } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/cash-payment-method/CashPaymentMethod';
import { EFTPaymentMethod } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/eft-payment-method/EFTPaymentMethod';
import { PaymentMethodTypes } from '@company-settings/enums';
import type {
  PaymentMethodComponent,
  UseGetPaymentMethodComponentProps,
  UseGetPaymentMethodComponentResult,
} from '@company-settings/hooks/types';

const registeredPaymentMethodComponents = new Map<PaymentMethodTypes, PaymentMethodComponent>([
  [PaymentMethodTypes.Cash, CashPaymentMethod],
  [PaymentMethodTypes.CashDeposit, CashDepositPaymentMethod],
  [PaymentMethodTypes.BankSlip, BankSlipPaymentMethod],
  [PaymentMethodTypes.BankDeposit, BankDepositPaymentMethod],
  [PaymentMethodTypes.BankTransfer, BankTransferPaymentMethod],
  [PaymentMethodTypes.EFT, EFTPaymentMethod],
]);

const getPaymentMethodComponent = (type: PaymentMethodTypes): PaymentMethodComponent | undefined =>
  registeredPaymentMethodComponents.get(type);

export const useGetPaymentMethodComponent = ({
  type,
}: UseGetPaymentMethodComponentProps): UseGetPaymentMethodComponentResult => {
  const component = getPaymentMethodComponent(type);
  const log = useLogService();

  if (isNil(component)) {
    log.warn(`The payment method type ${type} does not have a registered payment method.`);
  }

  return {
    PaymentMethodComponent: component,
  };
};
