/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import * as Segment from './segment'

export interface AccountTierEditionSubmissionFailed {
	/**
	 * The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
	 */
	account_tier: AccountTier
	/**
	 * The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG
	 */
	failure_reason: FailureReason
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
	/**
	 * The date in which the tier was changed.
	 */
	update_date: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
}
export interface AccountTierEditionSubmissionSuccessful {
	/**
	 * The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
	 */
	account_tier: AccountTier
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
	/**
	 * The date in which the tier was changed.
	 */
	update_date: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
}
export interface AccountTierEditionSubmitted {
	/**
	 * The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
	 */
	account_tier: AccountTier
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
	/**
	 * The date in which the tier was changed.
	 */
	update_date: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
}
export interface CompanyDetailsEditionSubmissionFailed {
	/**
	 * The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
	 */
	city: string
	/**
	 * The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
	 */
	country: Country
	/**
	 * The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG
	 */
	failure_reason: FailureReason
	/**
	 * The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
	 */
	government_id: string
	/**
	 * The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
	 */
	legal_address: string
	/**
	 * Company name in the given context. Samples: 'Socio Cerveceria'
	 */
	legal_name: string
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
	 */
	state: string
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
	/**
	 * The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
	 */
	zip_code: string
}
export interface CompanyDetailsEditionSubmissionSuccessful {
	/**
	 * The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
	 */
	city: string
	/**
	 * The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
	 */
	country: Country
	/**
	 * The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
	 */
	government_id: string
	/**
	 * The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
	 */
	legal_address: string
	/**
	 * Company name in the given context. Samples: 'Socio Cerveceria'
	 */
	legal_name: string
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
	 */
	state: string
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
	/**
	 * The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
	 */
	zip_code: string
}
export interface CompanyDetailsEditionSubmitted {
	/**
	 * The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
	 */
	city: string
	/**
	 * The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
	 */
	country: Country
	/**
	 * The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
	 */
	government_id: string
	/**
	 * The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
	 */
	legal_address: string
	/**
	 * Company name in the given context. Samples: 'Socio Cerveceria'
	 */
	legal_name: string
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
	 */
	state: string
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
	/**
	 * The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
	 */
	zip_code: string
}
export interface CompanyDetailsViewed {
	/**
	 * The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
	 */
	account_tier: AccountTier
	/**
	 * The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
	 */
	city: string
	/**
	 * The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
	 */
	country: Country
	/**
	 * The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
	 */
	government_id: string
	/**
	 * The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
	 */
	legal_address: string
	/**
	 * Company name in the given context. Samples: 'Socio Cerveceria'
	 */
	legal_name: string
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
	 */
	state: string
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
	/**
	 * The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
	 */
	zip_code: string
}
export interface CompaniesItem {
	/**
	 * The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
	 */
	account_tier: AccountTier
	/**
	 * The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
	 */
	country: Country
	/**
	 * The governmental registration id in the given context. Samples: '09.208.699/0001-54' (CNPJ for BR)
	 */
	government_id: string
	/**
	 * Company legal name in the given context. Samples: Anheuser-Busch InBev
	 */
	legal_name: string
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel1
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
}
export interface CompanyListViewed {
	/**
	 * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	companies: CompaniesItem[]
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number
	/**
	 * The number of the page in the given context. Samples: 1, 3, 5
	 */
	page_number: number
	/**
	 * The number of Rows per page. Samples: 10, 15, 30
	 */
	rows_per_page: number
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
}
export interface CompanyRegistrationSubmissionFailed {
	/**
	 * The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
	 */
	account_tier: AccountTier
	/**
	 * The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
	 */
	city: string
	/**
	 * The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
	 */
	country: Country
	/**
	 * The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG
	 */
	failure_reason: FailureReason
	/**
	 * The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
	 */
	government_id: string
	/**
	 * The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
	 */
	legal_address: string
	/**
	 * Company name in the given context. Samples: 'Socio Cerveceria'
	 */
	legal_name: string
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
	 */
	state: string
	/**
	 * The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
	 */
	zip_code: string
}
export interface CompanyRegistrationSubmissionSuccessful {
	/**
	 * The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
	 */
	account_tier: AccountTier
	/**
	 * The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
	 */
	city: string
	/**
	 * The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
	 */
	country: Country
	/**
	 * The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
	 */
	government_id: string
	/**
	 * The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
	 */
	legal_address: string
	/**
	 * Company name in the given context. Samples: 'Socio Cerveceria'
	 */
	legal_name: string
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
	 */
	state: string
	/**
	 * The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
	 */
	zip_code: string
}
export interface CompanyRegistrationSubmitted {
	/**
	 * The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
	 */
	account_tier: AccountTier
	/**
	 * The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
	 */
	city: string
	/**
	 * The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
	 */
	country: Country
	/**
	 * The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
	 */
	government_id: string
	/**
	 * The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
	 */
	legal_address: string
	/**
	 * Company name in the given context. Samples: 'Socio Cerveceria'
	 */
	legal_name: string
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
	 */
	state: string
	/**
	 * The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
	 */
	zip_code: string
}
export interface DcListViewed {
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number
	/**
	 * The number of the page in the given context. Samples: 1, 3, 5
	 */
	page_number: number
	/**
	 * The number of Rows per page. Samples: 10, 15, 30
	 */
	rows_per_page: number
}
export interface ElementClicked {
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_display_name: string | null
	/**
	 * The unique id of the app where the event was triggered.
	 */
	app_id: string | null
	/**
	 * The element text on the front end with localization.
	 */
	element_label: string | null
	/**
	 * The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name.
	 */
	element_location_name: string | null
	/**
	 * The category of the object that anchors the button.
	 */
	element_location_type: string | null
	/**
	 * The element name on the back end.
	 */
	element_name: string | null
	/**
	 * The element type.
	 */
	element_type: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action.
	 */
	is_core_event: boolean | null
	/**
	 * The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The country of the store that is executing the action or having it executed for them.
	 */
	store_country: string | null
	/**
	 * The sellers associated as default of the specific store.
	 */
	store_defaultseller: string | null
	/**
	 * The id of the store that is executing the action or having it executed for them.
	 */
	store_id: string | null
	/**
	 * The name of the store that is executing the action or having it executed for them.
	 */
	store_name: string | null
	/**
	 * The status can be enable, disable or in experimentation
	 */
	store_status: StoreStatus
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * The view level the action is being executed.
	 */
	view_level: string | null
}
export interface CompanyUsersItem {
	/**
	 * The user access level in a given context. Permitted Values: Admin, Editor, Viewer
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
	 */
	activation_date: string | null
	/**
	 * The identificator of the user associated with a partner of BEES. Samples: 'x'
	 */
	company_user_id: string | null
	/**
	 * The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
	 */
	created_date: string | null
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
	 */
	user_status: UserStatus
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string | null
}
export interface InviteResent {
	/**
	 * The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	company_users: CompanyUsersItem[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface LinkClicked {
	/**
	 * Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
	 */
	destination_url: string
	/**
	 * The link identification name. Permitted Values: COMPANY_MANAGEMENT
	 */
	link_name: ScreenName
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
}
export interface ListFiltered {
	/**
	 * The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country'
	 */
	filter_criteria: FilterCriteria
	/**
	 * The value chosen to filter the list. Which variable the user is using to perform the filtering action. Permitted values: ARGENTINA, BRAZIL, CANADA, CHILE, COLOMBIA, DOMINICAN_REPUBLIC, ECUADOR, HONDURAS, MEXICO, PANAMA, PERU, PARAGUAY, EL SALVADOR, TANZANIA, URUGUAY, SOUTH_AFRICA
	 */
	filter_value: FilterValue
	/**
	 * The identifier of the list, which list this event is related to. Permitted Values: COMPANY_LIST, USERS_LIST
	 */
	list_type: ListType
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
}
export interface SearchTagsItem {
	/**
	 * A free form field to include all variable search tags available.
	 */
	tag: string
}
export interface ListSearched {
	/**
	 * The identifier of the list, which list this event is related to. Permitted Values: COMPANY_LIST, USERS_LIST
	 */
	list_type: ListType
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Price', 'Date'
	 */
	search_criteria: string
	/**
	 * The array of search_tags. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	search_tags: SearchTagsItem[]
}
export interface ListSorted {
	/**
	 * The identifier of the list, which list this event is related to. Permitted Values: COMPANY_LIST, USERS_LIST
	 */
	list_type: ListType
	/**
	 * The column that is being sorted. Which variable the user is using to perform the sorting action. Samples: 'Price', 'Date'
	 */
	sort_criteria: string
	/**
	 * The sorting type when the user performs a sorting action. Permitted Values: Ascending, Descending
	 */
	sort_type: SortType
}
export interface MenuInteraction {
	/**
	 * Top level side menu. Sample: Settings, Users, Distribution Centers

	 */
	menu_category: string | null
	/**
	 * Option chosen from the side menu . If the menu does NOT have a subcategory, log null. Sample Values: Cu...

	 */
	menu_subcategory: string | null
}
export interface PageSelected {
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number
	/**
	 * The number of the page in the given context. Samples: 1, 3, 5
	 */
	page_number: number
	/**
	 * The number of Rows per page. Samples: 10, 15, 30
	 */
	rows_per_page: number
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
}
export interface PageViewed {
	/**
	 * The unique id of the app where the event was triggered.
	 */
	app_id: string | null
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_name: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action.
	 */
	is_core_event: boolean | null
	/**
	 * The country of the store that is executing the action or having it executed for them.
	 */
	store_country: string | null
	/**
	 * The id of the store that is executing the action or having it executed for them.
	 */
	store_id: string | null
	/**
	 * The name of the store that is executing the action or having it executed for them.
	 */
	store_name: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * The view level the action is being executed.
	 */
	view_level: string | null
}
export interface PaginationRowSelected {
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number
	/**
	 * The number of the page in the given context. Samples: 1, 3, 5
	 */
	page_number: number
	/**
	 * The number of Rows per page. Samples: 10, 15, 30
	 */
	rows_per_page: number
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
}
export interface SeachApplied {
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_display_name: string | null
	/**
	 * The unique id of the app where the event was triggered.
	 */
	app_id: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action.
	 */
	is_core_event: boolean | null
	/**
	 * The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The number of results from that search.
	 */
	number_of_results: number | null
	/**
	 * The string that was searched.
	 */
	search_query: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * The view level the action is being executed.
	 */
	view_level: string | null
}
export interface ServiceModelSelected {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
}
export interface SettingsCanceled {
	/**
	 * Indicates whether the user has clicked the "CANCEL" button, signifying the cancellation and disregard of all settings.
	 */
	is_settings_cancelled: boolean | null
}
export interface MaximumOrderItem {
	/**
	 * Represents the minimum required order value that a end user needs to meet in order to proceed with the checkout process.
	 */
	amount: number | null
	/**
	 * Represents the type the minimum order is based on
	 */
	type: Type
}
export interface MinimumOrderItem {
	/**
	 * Represents the minimum required order value that a end user needs to meet in order to proceed with the checkout process.
	 */
	amount: number | null
	/**
	 * Represents the type the minimum order is based on
	 */
	type: Type
}
export interface PaymentMethodsItem {
	/**
	 * Indicates if a payment method was just updated or created as new.
	 */
	action: Action
	/**
	 * Represents the category of new payment methods if selected from available list or created new
	 */
	category: Category
	/**
	 * Represents the number of days within which end users must make payment for bank slips when the "Bank Slip" payment method is selected.
	 */
	days_to_expire: number | null
	/**
	 * Represents the payment method description
	 */
	description: string | null
	/**
	 * Indicates wheter a payment method is enabled or not
	 */
	enabled: boolean | null
	/**
	 * Represents the payment method id
	 */
	id: string | null
	/**
	 * Represents the payment method name
	 */
	label: string | null
	/**
	 * Represents the payment method type
	 */
	type: string | null
}
export interface RecommendationItem {
	/**
	 * Indicates if Out of Stock replecement is enabled
	 */
	has_oos_replacement: boolean | null
	/**
	 * Indicates if Upselling pop up is enabled
	 */
	has_upselling: boolean | null
	/**
	 * Indicates whether the carousel is default or personalized
	 */
	suggested_order_type: SuggestedOrderType
}
export interface SettingsUpdated {
	/**
	 * Indicates what type of customers are eligible to see partner's products
	 */
	allowed_customers: AllowedCustomers
	/**
	 * Indicates whether the company's end users can participate in the Club B program and checkout orders by paying with points only.
	 */
	club_b_participation: boolean | null
	/**
	 * Indicates whether the company's end users can complete an order with empties only
	 */
	empties: boolean | null
	/**
	 * Indicates if the saving transaction contains any new payment method
	 */
	has_new_payment_method: boolean | null
	/**
	 * Indicates what type of inventory solutions is in place
	 */
	inventory_solutions: InventorySolutions
	/**
	 * The array of maximum orders. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	maximum_order: MaximumOrderItem[] | null
	/**
	 * The array of minimum orders. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	minimum_order: MinimumOrderItem[] | null
	/**
	 * Indicates if bcc emails list should receive order notifications
	 */
	order_notification_bcc_email: boolean | null
	/**
	 * Indicates if and what sales representatives associated with the company should receive order notifications.
	 */
	order_notification_type: OrderNotificationType
	/**
	 * The array of payments methods. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	payment_methods: PaymentMethodsItem[] | null
	/**
	 * The array of recommendation. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	recommendation: RecommendationItem[] | null
}
export interface TabInteraction {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface CompanyUsersItem1 {
	/**
	 * The user access level in a given context. Permitted Values: Admin, Editor, Viewer
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
	 */
	activation_date: string | null
	/**
	 * The identificator of the user associated with a partner of BEES. Samples: 'x'
	 */
	company_user_id: string | null
	/**
	 * The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
	 */
	created_date: string | null
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
	 */
	user_status: UserStatus
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string | null
}
export interface UserActivated {
	/**
	 * The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	company_users: CompanyUsersItem1[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface CompanyUsersItem2 {
	/**
	 * The user access level in a given context. Permitted Values: Admin, Editor, Viewer
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
	 */
	activation_date: string | null
	/**
	 * The identificator of the user associated with a partner of BEES. Samples: 'x'
	 */
	company_user_id: string | null
	/**
	 * The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
	 */
	created_date: string | null
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
	 */
	user_status: UserStatus
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string | null
}
export interface UserActivationCanceled {
	/**
	 * The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	company_users: CompanyUsersItem2[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface CompanyUsersItem3 {
	/**
	 * The user access level in a given context. Permitted Values: Admin, Editor, Viewer
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
	 */
	activation_date: string | null
	/**
	 * The identificator of the user associated with a partner of BEES. Samples: 'x'
	 */
	company_user_id: string | null
	/**
	 * The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
	 */
	created_date: string | null
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
	 */
	user_status: UserStatus
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string | null
}
export interface UserDeactivated {
	/**
	 * The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	company_users: CompanyUsersItem3[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface CompanyUsersItem4 {
	/**
	 * The user access level in a given context. Permitted Values: Admin, Editor, Viewer
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
	 */
	activation_date: string | null
	/**
	 * The identificator of the user associated with a partner of BEES. Samples: 'x'
	 */
	company_user_id: string | null
	/**
	 * The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
	 */
	created_date: string | null
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
	 */
	user_status: UserStatus
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string | null
}
export interface UserDeactivationCanceled {
	/**
	 * The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	company_users: CompanyUsersItem4[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface CompanyUsersItem5 {
	/**
	 * The user access level in a given context. Permitted Values: Admin, Editor, Viewer
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
	 */
	activation_date: string | null
	/**
	 * The identificator of the user associated with a partner of BEES. Samples: 'x'
	 */
	company_user_id: string | null
	/**
	 * The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
	 */
	created_date: string | null
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
	 */
	user_status: UserStatus
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string | null
}
export interface UserManagementListViewed {
	/**
	 * The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	company_users: CompanyUsersItem5[] | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number
	/**
	 * The number of the page in the given context. Samples: 1, 3, 5
	 */
	page_number: number
	/**
	 * The number of Rows per page. Samples: 10, 15, 30
	 */
	rows_per_page: number
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface UserRegistrationErrorsTriggered {
	/**
	 * The error feedback message displayed to the user in the given context.
	 */
	error_message: string
	/**
	 * The error information type in the given context. Permitted Values: INVALID_EMAIL_FORMAT, DUPLICATED_EMAILS_SAME_VENDOR, DUPLICATED_EMAILS_DIFFERENT_VENDORS
	 */
	error_type: ErrorType
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface CompanyUsersItem6 {
	/**
	 * The user access level in a given context. Permitted Values: Admin, Editor, Viewer
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
	 */
	activation_date: string | null
	/**
	 * The identificator of the user associated with a partner of BEES. Samples: 'x'
	 */
	company_user_id: string | null
	/**
	 * The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
	 */
	created_date: string | null
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
	 */
	user_status: UserStatus
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string | null
}
export interface UserRegistrationSubmissionFailed {
	/**
	 * The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	company_users: CompanyUsersItem6[] | null
	/**
	 * The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG
	 */
	failure_reason: FailureReason
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface CompanyUsersItem7 {
	/**
	 * The user access level in a given context. Permitted Values: Admin, Editor, Viewer
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
	 */
	activation_date: string | null
	/**
	 * The identificator of the user associated with a partner of BEES. Samples: 'x'
	 */
	company_user_id: string | null
	/**
	 * The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
	 */
	created_date: string | null
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
	 */
	user_status: UserStatus
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string | null
}
export interface UserRegistrationSubmissionSuccessful {
	/**
	 * The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	company_users: CompanyUsersItem7[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface CompanyUsersItem8 {
	/**
	 * The user access level in a given context. Permitted Values: Admin, Editor, Viewer
	 */
	access_level: AccessLevel
	/**
	 * The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
	 */
	activation_date: string | null
	/**
	 * The identificator of the user associated with a partner of BEES. Samples: 'x'
	 */
	company_user_id: string | null
	/**
	 * The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
	 */
	created_date: string | null
	/**
	 * The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
	 */
	service_model: ServiceModel
	/**
	 * The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
	 */
	user_status: UserStatus
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	vendor_id: string | null
}
export interface UserRegistrationSubmitted {
	/**
	 * The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	company_users: CompanyUsersItem8[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
	/**
	 * The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
	 */
	tab_label: string
	/**
	 * The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
	 */
	tab_name: TabName
}
export interface VendorIdCopied {
	/**
	 * Copied value in the given context. Sample: '6096c481-a3c5-4cf4-9c4b-8a2d08dac26e'
	 */
	copied_value: string
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
	 */
	screen_name: ScreenName
}

export enum AccountTier {
	Gold = 'GOLD',
	Silver = 'SILVER',
	Null = 'null',
}
export enum FailureReason {
	SomethingWentWrong = 'SOMETHING_WENT_WRONG',
}
export enum ScreenName {
	CompanyManagement = 'COMPANY_MANAGEMENT',
}
export enum ServiceModel {
	I1P = '1P',
	I1P3L = '1P-3L',
	I3P1L = '3P-1L',
	I3P3LSf = '3P-3L-SF',
	I3P3LSs = '3P-3L-SS',
	Null = 'null',
}
export enum TabName {
	CompanyManagementDetails = 'COMPANY_MANAGEMENT_DETAILS',
	CompanyManagementDistributionCenter = 'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
	CompanyManagementSettings = 'COMPANY_MANAGEMENT_SETTINGS',
	CompanyManagementUser = 'COMPANY_MANAGEMENT_USER',
}
export enum Country {
	Ar = 'AR',
	Br = 'BR',
	Ca = 'CA',
	Cl = 'CL',
	Co = 'CO',
	Do = 'DO',
	Ec = 'EC',
	Hn = 'HN',
	Mx = 'MX',
	Pa = 'PA',
	Pe = 'PE',
	Py = 'PY',
	Sv = 'SV',
	Tz = 'TZ',
	Uy = 'UY',
	Za = 'ZA',
}
export enum ServiceModel1 {
	I1P1 = '1P',
	I1P3L1 = '1P-3L',
	I3P1L1 = '3P-1L',
	I3P3LSf1 = '3P-3L-SF',
	I3P3LSs1 = '3P-3L-SS',
}
export enum StoreStatus {
	Enable = 'enable',
	Disable = 'disable',
	Experimentation = 'experimentation',
}
export enum AccessLevel {
	Admin = 'ADMIN',
	Editor = 'EDITOR',
	Viewer = 'VIEWER',
	Null = 'null',
}
export enum UserStatus {
	Active = 'ACTIVE',
	Blocked = 'BLOCKED',
	Expired = 'EXPIRED',
	Inactive = 'INACTIVE',
	Pending = 'PENDING',
	Null = 'null',
}
export enum FilterCriteria {
	Country = 'COUNTRY',
}
export enum FilterValue {
	Argentina = 'ARGENTINA',
	Brazil = 'BRAZIL',
	Canada = 'CANADA',
	Chile = 'CHILE',
	Colombia = 'COLOMBIA',
	DominicanRepublic = 'DOMINICAN_REPUBLIC',
	Ecuador = 'ECUADOR',
	ElSalvador = 'EL_SALVADOR',
	Honduras = 'HONDURAS',
	Mexico = 'MEXICO',
	Panama = 'PANAMA',
	Paraguay = 'PARAGUAY',
	Peru = 'PERU',
	SouthAfrica = 'SOUTH_AFRICA',
	Tanzania = 'TANZANIA',
	Uruguay = 'URUGUAY',
}
export enum ListType {
	CompanyList = 'COMPANY_LIST',
	UsersList = 'USERS_LIST',
}
export enum SortType {
	Ascending = 'ASCENDING',
	Descending = 'DESCENDING',
}
export enum AllowedCustomers {
	CnpjCpf = 'CNPJ_CPF',
	Cpf = 'CPF',
	Null = 'null',
}
export enum InventorySolutions {
	Backorder = 'BACKORDER',
	Limited = 'LIMITED',
	Null = 'null',
}
export enum Type {
	OrderTotal = 'ORDER_TOTAL',
	ProductQuantity = 'PRODUCT_QUANTITY',
	Null = 'null',
}
export enum OrderNotificationType {
	AllSalesReps = 'ALL_SALES_REPS',
	AllSalesRepsSupervisor = 'ALL_SALES_REPS_SUPERVISOR',
	NoSalesRep = 'NO_SALES_REP',
	PrimarySalesRep = 'PRIMARY_SALES_REP',
	Null = 'null',
}
export enum Action {
	Insert = 'INSERT',
	Update = 'UPDATE',
	Null = 'null',
}
export enum Category {
	Available = 'AVAILABLE',
	Custom = 'CUSTOM',
	Null = 'null',
}
export enum SuggestedOrderType {
	Default = 'DEFAULT',
	Personalized = 'PERSONALIZED',
	Null = 'null',
}
export enum ErrorType {
	DuplicatedEmailsDifferentVendors = 'DUPLICATED_EMAILS_DIFFERENT_VENDORS',
	DuplicatedEmailsSameVendor = 'DUPLICATED_EMAILS_SAME_VENDOR',
	InvalidEmailFormat = 'INVALID_EMAIL_FORMAT',
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef AccountTierEditionSubmissionFailed
 * @property {AccountTier} account_tier - The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
 * @property {FailureReason} failure_reason - The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 * @property {string | null} update_date - The date in which the tier was changed.
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef AccountTierEditionSubmissionSuccessful
 * @property {AccountTier} account_tier - The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 * @property {string | null} update_date - The date in which the tier was changed.
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef AccountTierEditionSubmitted
 * @property {AccountTier} account_tier - The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 * @property {string | null} update_date - The date in which the tier was changed.
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CompanyDetailsEditionSubmissionFailed
 * @property {string} city - The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
 * @property {Country} country - The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
 * @property {FailureReason} failure_reason - The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG
 * @property {string} government_id - The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
 * @property {string} legal_address - The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
 * @property {string} legal_name - Company name in the given context. Samples: 'Socio Cerveceria'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} state - The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string} zip_code - The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
 */
/**
 * @typedef CompanyDetailsEditionSubmissionSuccessful
 * @property {string} city - The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
 * @property {Country} country - The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
 * @property {string} government_id - The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
 * @property {string} legal_address - The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
 * @property {string} legal_name - Company name in the given context. Samples: 'Socio Cerveceria'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} state - The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string} zip_code - The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
 */
/**
 * @typedef CompanyDetailsEditionSubmitted
 * @property {string} city - The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
 * @property {Country} country - The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
 * @property {string} government_id - The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
 * @property {string} legal_address - The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
 * @property {string} legal_name - Company name in the given context. Samples: 'Socio Cerveceria'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} state - The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string} zip_code - The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
 */
/**
 * @typedef CompanyDetailsViewed
 * @property {AccountTier} account_tier - The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
 * @property {string} city - The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
 * @property {Country} country - The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
 * @property {string} government_id - The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
 * @property {string} legal_address - The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
 * @property {string} legal_name - Company name in the given context. Samples: 'Socio Cerveceria'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} state - The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string} zip_code - The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
 */
/**
 * @typedef CompaniesItem
 * @property {AccountTier} account_tier - The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
 * @property {Country} country - The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
 * @property {string} government_id - The governmental registration id in the given context. Samples: '09.208.699/0001-54' (CNPJ for BR)
 * @property {string} legal_name - Company legal name in the given context. Samples: Anheuser-Busch InBev
 * @property {ServiceModel1} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CompanyListViewed
 * @property {CompaniesItem[]} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {number} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {number} page_number - The number of the page in the given context. Samples: 1, 3, 5
 * @property {number} rows_per_page - The number of Rows per page. Samples: 10, 15, 30
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 */
/**
 * @typedef CompanyRegistrationSubmissionFailed
 * @property {AccountTier} account_tier - The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
 * @property {string} city - The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
 * @property {Country} country - The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
 * @property {FailureReason} failure_reason - The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG
 * @property {string} government_id - The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
 * @property {string} legal_address - The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
 * @property {string} legal_name - Company name in the given context. Samples: 'Socio Cerveceria'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} state - The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
 * @property {string} zip_code - The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
 */
/**
 * @typedef CompanyRegistrationSubmissionSuccessful
 * @property {AccountTier} account_tier - The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
 * @property {string} city - The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
 * @property {Country} country - The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
 * @property {string} government_id - The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
 * @property {string} legal_address - The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
 * @property {string} legal_name - Company name in the given context. Samples: 'Socio Cerveceria'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} state - The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
 * @property {string} zip_code - The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
 */
/**
 * @typedef CompanyRegistrationSubmitted
 * @property {AccountTier} account_tier - The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold
 * @property {string} city - The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'
 * @property {Country} country - The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA
 * @property {string} government_id - The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user
 * @property {string} legal_address - The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'
 * @property {string} legal_name - Company name in the given context. Samples: 'Socio Cerveceria'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {string} state - The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'
 * @property {string} zip_code - The zip code name inputed by the user in the given context. Samples '02567-125', '10036'
 */
/**
 * @typedef DcListViewed
 * @property {number} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {number} page_number - The number of the page in the given context. Samples: 1, 3, 5
 * @property {number} rows_per_page - The number of Rows per page. Samples: 10, 15, 30
 */
/**
 * @typedef ElementClicked
 * @property {string | null} app_display_name - The current name of the app where the event was triggered.
 * @property {string | null} app_id - The unique id of the app where the event was triggered.
 * @property {string | null} element_label - The element text on the front end with localization.
 * @property {string | null} element_location_name - The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name.
 * @property {string | null} element_location_type - The category of the object that anchors the button.
 * @property {string | null} element_name - The element name on the back end.
 * @property {string | null} element_type - The element type.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action.
 * @property {string | null} manufacturer_country - The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} store_country - The country of the store that is executing the action or having it executed for them.
 * @property {string | null} store_defaultseller - The sellers associated as default of the specific store.
 * @property {string | null} store_id - The id of the store that is executing the action or having it executed for them.
 * @property {string | null} store_name - The name of the store that is executing the action or having it executed for them.
 * @property {StoreStatus} store_status - The status can be enable, disable or in experimentation
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 * @property {string | null} vendor_country - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - The name of the vendor that is executing the action or having it executed for them.
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} vendor_service_model - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - The view level the action is being executed.
 */
/**
 * @typedef CompanyUsersItem
 * @property {AccessLevel} access_level - The user access level in a given context. Permitted Values: Admin, Editor, Viewer
 * @property {string | null} activation_date - The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
 * @property {string | null} company_user_id - The identificator of the user associated with a partner of BEES. Samples: 'x'
 * @property {string | null} created_date - The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
 * @property {string | null} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef InviteResent
 * @property {CompanyUsersItem[] | null} company_users - The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef LinkClicked
 * @property {string} destination_url - Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
 * @property {ScreenName} link_name - The link identification name. Permitted Values: COMPANY_MANAGEMENT
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 */
/**
 * @typedef ListFiltered
 * @property {FilterCriteria} filter_criteria - The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country'
 * @property {FilterValue} filter_value - The value chosen to filter the list. Which variable the user is using to perform the filtering action. Permitted values: ARGENTINA, BRAZIL, CANADA, CHILE, COLOMBIA, DOMINICAN_REPUBLIC, ECUADOR, HONDURAS, MEXICO, PANAMA, PERU, PARAGUAY, EL SALVADOR, TANZANIA, URUGUAY, SOUTH_AFRICA
 * @property {ListType} list_type - The identifier of the list, which list this event is related to. Permitted Values: COMPANY_LIST, USERS_LIST
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 */
/**
 * @typedef SearchTagsItem
 * @property {string} tag - A free form field to include all variable search tags available.
 */
/**
 * @typedef ListSearched
 * @property {ListType} list_type - The identifier of the list, which list this event is related to. Permitted Values: COMPANY_LIST, USERS_LIST
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Price', 'Date'
 * @property {SearchTagsItem[]} search_tags - The array of search_tags. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 */
/**
 * @typedef ListSorted
 * @property {ListType} list_type - The identifier of the list, which list this event is related to. Permitted Values: COMPANY_LIST, USERS_LIST
 * @property {string} sort_criteria - The column that is being sorted. Which variable the user is using to perform the sorting action. Samples: 'Price', 'Date'
 * @property {SortType} sort_type - The sorting type when the user performs a sorting action. Permitted Values: Ascending, Descending
 */
/**
 * @typedef MenuInteraction
 * @property {string | null} menu_category - Top level side menu. Sample: Settings, Users, Distribution Centers

 * @property {string | null} menu_subcategory - Option chosen from the side menu . If the menu does NOT have a subcategory, log null. Sample Values: Cu...

 */
/**
 * @typedef PageSelected
 * @property {number} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {number} page_number - The number of the page in the given context. Samples: 1, 3, 5
 * @property {number} rows_per_page - The number of Rows per page. Samples: 10, 15, 30
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 */
/**
 * @typedef PageViewed
 * @property {string | null} app_id - The unique id of the app where the event was triggered.
 * @property {string | null} app_name - The current name of the app where the event was triggered.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action.
 * @property {string | null} store_country - The country of the store that is executing the action or having it executed for them.
 * @property {string | null} store_id - The id of the store that is executing the action or having it executed for them.
 * @property {string | null} store_name - The name of the store that is executing the action or having it executed for them.
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 * @property {string | null} vendor_country - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - The name of the vendor that is executing the action or having it executed for them.
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} vendor_service_model - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - The view level the action is being executed.
 */
/**
 * @typedef PaginationRowSelected
 * @property {number} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {number} page_number - The number of the page in the given context. Samples: 1, 3, 5
 * @property {number} rows_per_page - The number of Rows per page. Samples: 10, 15, 30
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 */
/**
 * @typedef SeachApplied
 * @property {string | null} app_display_name - The current name of the app where the event was triggered.
 * @property {string | null} app_id - The unique id of the app where the event was triggered.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action.
 * @property {string | null} manufacturer_country - The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {number | null} number_of_results - The number of results from that search.
 * @property {string | null} search_query - The string that was searched.
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 * @property {string | null} vendor_country - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - The name of the vendor that is executing the action or having it executed for them.
 * @property {string} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} vendor_service_model - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - The view level the action is being executed.
 */
/**
 * @typedef ServiceModelSelected
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 */
/**
 * @typedef SettingsCanceled
 * @property {boolean | null} is_settings_cancelled - Indicates whether the user has clicked the "CANCEL" button, signifying the cancellation and disregard of all settings.
 */
/**
 * @typedef MaximumOrderItem
 * @property {number | null} amount - Represents the minimum required order value that a end user needs to meet in order to proceed with the checkout process.
 * @property {Type} type - Represents the type the minimum order is based on
 */
/**
 * @typedef MinimumOrderItem
 * @property {number | null} amount - Represents the minimum required order value that a end user needs to meet in order to proceed with the checkout process.
 * @property {Type} type - Represents the type the minimum order is based on
 */
/**
 * @typedef PaymentMethodsItem
 * @property {Action} action - Indicates if a payment method was just updated or created as new.
 * @property {Category} category - Represents the category of new payment methods if selected from available list or created new
 * @property {number | null} days_to_expire - Represents the number of days within which end users must make payment for bank slips when the "Bank Slip" payment method is selected.
 * @property {string | null} description - Represents the payment method description
 * @property {boolean | null} enabled - Indicates wheter a payment method is enabled or not
 * @property {string | null} id - Represents the payment method id
 * @property {string | null} label - Represents the payment method name
 * @property {string | null} type - Represents the payment method type
 */
/**
 * @typedef RecommendationItem
 * @property {boolean | null} has_oos_replacement - Indicates if Out of Stock replecement is enabled
 * @property {boolean | null} has_upselling - Indicates if Upselling pop up is enabled
 * @property {SuggestedOrderType} suggested_order_type - Indicates whether the carousel is default or personalized
 */
/**
 * @typedef SettingsUpdated
 * @property {AllowedCustomers} allowed_customers - Indicates what type of customers are eligible to see partner's products
 * @property {boolean | null} club_b_participation - Indicates whether the company's end users can participate in the Club B program and checkout orders by paying with points only.
 * @property {boolean | null} empties - Indicates whether the company's end users can complete an order with empties only
 * @property {boolean | null} has_new_payment_method - Indicates if the saving transaction contains any new payment method
 * @property {InventorySolutions} inventory_solutions - Indicates what type of inventory solutions is in place
 * @property {MaximumOrderItem[] | null} maximum_order - The array of maximum orders. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {MinimumOrderItem[] | null} minimum_order - The array of minimum orders. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {boolean | null} order_notification_bcc_email - Indicates if bcc emails list should receive order notifications
 * @property {OrderNotificationType} order_notification_type - Indicates if and what sales representatives associated with the company should receive order notifications.
 * @property {PaymentMethodsItem[] | null} payment_methods - The array of payments methods. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {RecommendationItem[] | null} recommendation - The array of recommendation. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 */
/**
 * @typedef TabInteraction
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef CompanyUsersItem1
 * @property {AccessLevel} access_level - The user access level in a given context. Permitted Values: Admin, Editor, Viewer
 * @property {string | null} activation_date - The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
 * @property {string | null} company_user_id - The identificator of the user associated with a partner of BEES. Samples: 'x'
 * @property {string | null} created_date - The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
 * @property {string | null} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserActivated
 * @property {CompanyUsersItem1[] | null} company_users - The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef CompanyUsersItem2
 * @property {AccessLevel} access_level - The user access level in a given context. Permitted Values: Admin, Editor, Viewer
 * @property {string | null} activation_date - The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
 * @property {string | null} company_user_id - The identificator of the user associated with a partner of BEES. Samples: 'x'
 * @property {string | null} created_date - The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
 * @property {string | null} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserActivationCanceled
 * @property {CompanyUsersItem2[] | null} company_users - The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef CompanyUsersItem3
 * @property {AccessLevel} access_level - The user access level in a given context. Permitted Values: Admin, Editor, Viewer
 * @property {string | null} activation_date - The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
 * @property {string | null} company_user_id - The identificator of the user associated with a partner of BEES. Samples: 'x'
 * @property {string | null} created_date - The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
 * @property {string | null} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserDeactivated
 * @property {CompanyUsersItem3[] | null} company_users - The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef CompanyUsersItem4
 * @property {AccessLevel} access_level - The user access level in a given context. Permitted Values: Admin, Editor, Viewer
 * @property {string | null} activation_date - The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
 * @property {string | null} company_user_id - The identificator of the user associated with a partner of BEES. Samples: 'x'
 * @property {string | null} created_date - The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
 * @property {string | null} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserDeactivationCanceled
 * @property {CompanyUsersItem4[] | null} company_users - The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef CompanyUsersItem5
 * @property {AccessLevel} access_level - The user access level in a given context. Permitted Values: Admin, Editor, Viewer
 * @property {string | null} activation_date - The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
 * @property {string | null} company_user_id - The identificator of the user associated with a partner of BEES. Samples: 'x'
 * @property {string | null} created_date - The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
 * @property {string | null} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserManagementListViewed
 * @property {CompanyUsersItem5[] | null} company_users - The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {number} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {number} page_number - The number of the page in the given context. Samples: 1, 3, 5
 * @property {number} rows_per_page - The number of Rows per page. Samples: 10, 15, 30
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef UserRegistrationErrorsTriggered
 * @property {string} error_message - The error feedback message displayed to the user in the given context.
 * @property {ErrorType} error_type - The error information type in the given context. Permitted Values: INVALID_EMAIL_FORMAT, DUPLICATED_EMAILS_SAME_VENDOR, DUPLICATED_EMAILS_DIFFERENT_VENDORS
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef CompanyUsersItem6
 * @property {AccessLevel} access_level - The user access level in a given context. Permitted Values: Admin, Editor, Viewer
 * @property {string | null} activation_date - The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
 * @property {string | null} company_user_id - The identificator of the user associated with a partner of BEES. Samples: 'x'
 * @property {string | null} created_date - The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
 * @property {string | null} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserRegistrationSubmissionFailed
 * @property {CompanyUsersItem6[] | null} company_users - The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {FailureReason} failure_reason - The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef CompanyUsersItem7
 * @property {AccessLevel} access_level - The user access level in a given context. Permitted Values: Admin, Editor, Viewer
 * @property {string | null} activation_date - The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
 * @property {string | null} company_user_id - The identificator of the user associated with a partner of BEES. Samples: 'x'
 * @property {string | null} created_date - The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
 * @property {string | null} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserRegistrationSubmissionSuccessful
 * @property {CompanyUsersItem7[] | null} company_users - The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef CompanyUsersItem8
 * @property {AccessLevel} access_level - The user access level in a given context. Permitted Values: Admin, Editor, Viewer
 * @property {string | null} activation_date - The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '
 * @property {string | null} company_user_id - The identificator of the user associated with a partner of BEES. Samples: 'x'
 * @property {string | null} created_date - The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 '
 * @property {ServiceModel} service_model - The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L
 * @property {UserStatus} user_status - The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked
 * @property {string | null} vendor_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserRegistrationSubmitted
 * @property {CompanyUsersItem8[] | null} company_users - The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 * @property {string} tab_label - The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed
 * @property {TabName} tab_name - The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS
COMPANY_MANAGEMENT_DISTRIBUTION_CENTER
COMPANY_MANAGEMENT_USER
COMPANY_MANAGEMENT_SETTINGS
 */
/**
 * @typedef VendorIdCopied
 * @property {string} copied_value - Copied value in the given context. Sample: '6096c481-a3c5-4cf4-9c4b-8a2d08dac26e'
 * @property {ScreenName} screen_name - The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT
 */

/**
 * Triggered when the user clicks on "Cancel” button on the change tier modal
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountTierEditionCanceled(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on "Cancel” button on the change tier modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Tier Edition Canceled',
		type: 'object',
	}
	const message = {
		event: 'Account Tier Edition Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Tier Edition Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on “…” symbol to open change tier dropdown
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountTierEditionSelected(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on “…” symbol to open change tier dropdown',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Tier Edition Selected',
		type: 'object',
	}
	const message = {
		event: 'Account Tier Edition Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Tier Edition Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on "Change tier” button to open the change tier modal
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountTierEditionStarted(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on "Change tier” button to open the change tier modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Tier Edition Started',
		type: 'object',
	}
	const message = {
		event: 'Account Tier Edition Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Tier Edition Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the change tier process is failed
 *
 * @param {AccountTierEditionSubmissionFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountTierEditionSubmissionFailed(
	props: AccountTierEditionSubmissionFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the change tier process is failed',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_tier: {
						description:
							'The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold',
						enum: ['GOLD', 'SILVER', null],
						type: ['string', 'null'],
					},
					failure_reason: {
						description:
							'The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG',
						enum: ['SOMETHING_WENT_WRONG'],
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
					update_date: {
						description: 'The date in which the tier was changed.',
						format: 'date-time',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
				},
				required: [
					'account_tier',
					'failure_reason',
					'screen_name',
					'service_model',
					'tab_label',
					'tab_name',
					'update_date',
					'vendor_id',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Tier Edition Submission Failed',
		type: 'object',
	}
	const message = {
		event: 'Account Tier Edition Submission Failed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Tier Edition Submission Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the change tier is successful
 *
 * @param {AccountTierEditionSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountTierEditionSubmissionSuccessful(
	props: AccountTierEditionSubmissionSuccessful,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the process of the change tier is successful',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_tier: {
						description:
							'The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold',
						enum: ['GOLD', 'SILVER', null],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
					update_date: {
						description: 'The date in which the tier was changed.',
						format: 'date-time',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
				},
				required: [
					'account_tier',
					'screen_name',
					'service_model',
					'tab_label',
					'tab_name',
					'update_date',
					'vendor_id',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Tier Edition Submission Successful',
		type: 'object',
	}
	const message = {
		event: 'Account Tier Edition Submission Successful',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Tier Edition Submission Successful',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on "Save” button on the change tier modal
 *
 * @param {AccountTierEditionSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountTierEditionSubmitted(
	props: AccountTierEditionSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on "Save” button on the change tier modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_tier: {
						description:
							'The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold',
						enum: ['GOLD', 'SILVER', null],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
					update_date: {
						description: 'The date in which the tier was changed.',
						format: 'date-time',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
				},
				required: [
					'account_tier',
					'screen_name',
					'service_model',
					'tab_label',
					'tab_name',
					'update_date',
					'vendor_id',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Tier Edition Submitted',
		type: 'object',
	}
	const message = {
		event: 'Account Tier Edition Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Tier Edition Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user cancels the Company Edition journey by clicking on the "Cancel" button
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyDetailsEditionCanceled(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user cancels the Company Edition journey by clicking on the "Cancel" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Details Edition Canceled',
		type: 'object',
	}
	const message = {
		event: 'Company Details Edition Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Details Edition Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the Company Edition journey by clicking on the "Edit" button
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyDetailsEditionStarted(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the Company Edition journey by clicking on the "Edit" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Details Edition Started',
		type: 'object',
	}
	const message = {
		event: 'Company Details Edition Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Details Edition Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the Company Edition is failed
 *
 * @param {CompanyDetailsEditionSubmissionFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyDetailsEditionSubmissionFailed(
	props: CompanyDetailsEditionSubmissionFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the process of the Company Edition is failed',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					city: {
						description:
							"The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'",
						type: ['string'],
					},
					country: {
						description:
							'The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA',
						enum: [
							'AR',
							'BR',
							'CA',
							'CL',
							'CO',
							'DO',
							'EC',
							'HN',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					failure_reason: {
						description:
							'The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG',
						enum: ['SOMETHING_WENT_WRONG'],
						type: ['string'],
					},
					government_id: {
						description:
							'The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user',
						type: ['string'],
					},
					legal_address: {
						description:
							"The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'",
						type: ['string'],
					},
					legal_name: {
						description:
							"Company name in the given context. Samples: 'Socio Cerveceria'",
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					state: {
						description:
							"The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'",
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
					zip_code: {
						description:
							"The zip code name inputed by the user in the given context. Samples '02567-125', '10036'",
						type: ['string'],
					},
				},
				required: [
					'city',
					'country',
					'failure_reason',
					'government_id',
					'legal_address',
					'legal_name',
					'screen_name',
					'service_model',
					'state',
					'tab_label',
					'tab_name',
					'vendor_id',
					'zip_code',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Details Edition Submission Failed',
		type: 'object',
	}
	const message = {
		event: 'Company Details Edition Submission Failed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Details Edition Submission Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the Company Edition is successful.
 *
 * @param {CompanyDetailsEditionSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyDetailsEditionSubmissionSuccessful(
	props: CompanyDetailsEditionSubmissionSuccessful,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the Company Edition is successful.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					city: {
						description:
							"The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'",
						type: ['string'],
					},
					country: {
						description:
							'The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA',
						enum: [
							'AR',
							'BR',
							'CA',
							'CL',
							'CO',
							'DO',
							'EC',
							'HN',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					government_id: {
						description:
							'The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user',
						type: ['string'],
					},
					legal_address: {
						description:
							"The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'",
						type: ['string'],
					},
					legal_name: {
						description:
							"Company name in the given context. Samples: 'Socio Cerveceria'",
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					state: {
						description:
							"The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'",
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
					zip_code: {
						description:
							"The zip code name inputed by the user in the given context. Samples '02567-125', '10036'",
						type: ['string'],
					},
				},
				required: [
					'city',
					'country',
					'government_id',
					'legal_address',
					'legal_name',
					'screen_name',
					'service_model',
					'state',
					'tab_label',
					'tab_name',
					'vendor_id',
					'zip_code',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Details Edition Submission Successful',
		type: 'object',
	}
	const message = {
		event: 'Company Details Edition Submission Successful',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Details Edition Submission Successful',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user finishes to fill-out the Company Edition forms and clicks on the "Save" button
 *
 * @param {CompanyDetailsEditionSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyDetailsEditionSubmitted(
	props: CompanyDetailsEditionSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user finishes to fill-out the Company Edition forms and clicks on the "Save" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					city: {
						description:
							"The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'",
						type: ['string'],
					},
					country: {
						description:
							'The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA',
						enum: [
							'AR',
							'BR',
							'CA',
							'CL',
							'CO',
							'DO',
							'EC',
							'HN',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					government_id: {
						description:
							'The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user',
						type: ['string'],
					},
					legal_address: {
						description:
							"The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'",
						type: ['string'],
					},
					legal_name: {
						description:
							"Company name in the given context. Samples: 'Socio Cerveceria'",
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					state: {
						description:
							"The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'",
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
					zip_code: {
						description:
							"The zip code name inputed by the user in the given context. Samples '02567-125', '10036'",
						type: ['string'],
					},
				},
				required: [
					'city',
					'country',
					'government_id',
					'legal_address',
					'legal_name',
					'screen_name',
					'service_model',
					'state',
					'tab_label',
					'tab_name',
					'vendor_id',
					'zip_code',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Details Edition Submitted',
		type: 'object',
	}
	const message = {
		event: 'Company Details Edition Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Details Edition Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the Details Tab of a Company's Page loads
 *
 * @param {CompanyDetailsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyDetailsViewed(
	props: CompanyDetailsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: "Triggered when the Details Tab of a Company's Page loads",
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_tier: {
						description:
							'The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold',
						enum: ['GOLD', 'SILVER', null],
						type: ['string', 'null'],
					},
					city: {
						description:
							"The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'",
						type: ['string'],
					},
					country: {
						description:
							'The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA',
						enum: [
							'AR',
							'BR',
							'CA',
							'CL',
							'CO',
							'DO',
							'EC',
							'HN',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					government_id: {
						description:
							'The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user',
						type: ['string'],
					},
					legal_address: {
						description:
							"The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'",
						type: ['string'],
					},
					legal_name: {
						description:
							"Company name in the given context. Samples: 'Socio Cerveceria'",
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					state: {
						description:
							"The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'",
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
					zip_code: {
						description:
							"The zip code name inputed by the user in the given context. Samples '02567-125', '10036'",
						type: ['string'],
					},
				},
				required: [
					'account_tier',
					'city',
					'country',
					'government_id',
					'legal_address',
					'legal_name',
					'screen_name',
					'service_model',
					'state',
					'tab_label',
					'tab_name',
					'vendor_id',
					'zip_code',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Details Viewed',
		type: 'object',
	}
	const message = {
		event: 'Company Details Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered every time the Company List is loaded (including when the rows per page is changed or when the user navigates forward through the pages)
 *
 * @param {CompanyListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyListViewed(
	props: CompanyListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered every time the Company List is loaded (including when the rows per page is changed or when the user navigates forward through the pages)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					companies: {
						description:
							"The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								account_tier: {
									description:
										'The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold',
									enum: ['GOLD', 'SILVER', null],
									type: ['string', 'null'],
								},
								country: {
									description:
										'The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA',
									enum: [
										'AR',
										'BR',
										'CA',
										'CL',
										'CO',
										'DO',
										'EC',
										'HN',
										'MX',
										'PA',
										'PE',
										'PY',
										'SV',
										'TZ',
										'UY',
										'ZA',
									],
									type: ['string'],
								},
								government_id: {
									description:
										"The governmental registration id in the given context. Samples: '09.208.699/0001-54' (CNPJ for BR)",
									type: ['string'],
								},
								legal_name: {
									description:
										'Company legal name in the given context. Samples: Anheuser-Busch InBev',
									type: ['string'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS'],
									type: ['string'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string'],
								},
							},
							required: [
								'account_tier',
								'country',
								'government_id',
								'legal_name',
								'service_model',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer'],
					},
					page_number: {
						description:
							'The number of the page in the given context. Samples: 1, 3, 5',
						type: ['integer'],
					},
					rows_per_page: {
						description: 'The number of Rows per page. Samples: 10, 15, 30',
						type: ['integer'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
				},
				required: [
					'companies',
					'listed_items',
					'page_number',
					'rows_per_page',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company List Viewed',
		type: 'object',
	}
	const message = {
		event: 'Company List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user cancels the Company Registration journey by clicking on the "Cancel" button of the Company Registration Modal
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyRegistrationCanceled(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user cancels the Company Registration journey by clicking on the "Cancel" button of the Company Registration Modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Registration Canceled',
		type: 'object',
	}
	const message = {
		event: 'Company Registration Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Registration Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the Company Registration journey by clicking on the "Add Company" button
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyRegistrationStarted(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the Company Registration journey by clicking on the "Add Company" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Registration Started',
		type: 'object',
	}
	const message = {
		event: 'Company Registration Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Registration Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the company registration is failed.
 *
 * @param {CompanyRegistrationSubmissionFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyRegistrationSubmissionFailed(
	props: CompanyRegistrationSubmissionFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the company registration is failed. ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_tier: {
						description:
							'The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold',
						enum: ['GOLD', 'SILVER', null],
						type: ['string', 'null'],
					},
					city: {
						description:
							"The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'",
						type: ['string'],
					},
					country: {
						description:
							'The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA',
						enum: [
							'AR',
							'BR',
							'CA',
							'CL',
							'CO',
							'DO',
							'EC',
							'HN',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					failure_reason: {
						description:
							'The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG',
						enum: ['SOMETHING_WENT_WRONG'],
						type: ['string'],
					},
					government_id: {
						description:
							'The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user',
						type: ['string'],
					},
					legal_address: {
						description:
							"The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'",
						type: ['string'],
					},
					legal_name: {
						description:
							"Company name in the given context. Samples: 'Socio Cerveceria'",
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					state: {
						description:
							"The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'",
						type: ['string'],
					},
					zip_code: {
						description:
							"The zip code name inputed by the user in the given context. Samples '02567-125', '10036'",
						type: ['string'],
					},
				},
				required: [
					'account_tier',
					'city',
					'country',
					'failure_reason',
					'government_id',
					'legal_address',
					'legal_name',
					'screen_name',
					'service_model',
					'state',
					'zip_code',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Registration Submission Failed',
		type: 'object',
	}
	const message = {
		event: 'Company Registration Submission Failed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Registration Submission Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the company registration is successful.
 *
 * @param {CompanyRegistrationSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyRegistrationSubmissionSuccessful(
	props: CompanyRegistrationSubmissionSuccessful,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the company registration is successful.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_tier: {
						description:
							'The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold',
						enum: ['GOLD', 'SILVER', null],
						type: ['string', 'null'],
					},
					city: {
						description:
							"The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'",
						type: ['string'],
					},
					country: {
						description:
							'The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA',
						enum: [
							'AR',
							'BR',
							'CA',
							'CL',
							'CO',
							'DO',
							'EC',
							'HN',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					government_id: {
						description:
							'The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user',
						type: ['string'],
					},
					legal_address: {
						description:
							"The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'",
						type: ['string'],
					},
					legal_name: {
						description:
							"Company name in the given context. Samples: 'Socio Cerveceria'",
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					state: {
						description:
							"The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'",
						type: ['string'],
					},
					zip_code: {
						description:
							"The zip code name inputed by the user in the given context. Samples '02567-125', '10036'",
						type: ['string'],
					},
				},
				required: [
					'account_tier',
					'city',
					'country',
					'government_id',
					'legal_address',
					'legal_name',
					'screen_name',
					'service_model',
					'state',
					'zip_code',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Registration Submission Successful',
		type: 'object',
	}
	const message = {
		event: 'Company Registration Submission Successful',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Registration Submission Successful',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user finishes to fill-out the company registration forms and clicks on the "Add Company" button
 *
 * @param {CompanyRegistrationSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyRegistrationSubmitted(
	props: CompanyRegistrationSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user finishes to fill-out the company registration forms and clicks on the "Add Company" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_tier: {
						description:
							'The tier associated with the company, some tiers allow paid features. Permitted values: Silver, Gold',
						enum: ['GOLD', 'SILVER', null],
						type: ['string', 'null'],
					},
					city: {
						description:
							"The city name inputed by the user in the given context. Samples 'São Paulo', 'Buenos Aires'",
						type: ['string'],
					},
					country: {
						description:
							'The country in the given context. Permitted values: AR, BR, CA, CL, CO, DO, EC, HN, MX, PA, PE, PY, SV, TZ, UY, ZA',
						enum: [
							'AR',
							'BR',
							'CA',
							'CL',
							'CO',
							'DO',
							'EC',
							'HN',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					government_id: {
						description:
							'The governmental ID of the Sales Structure / The org ID of the DDC (CNPJ for BR) inputed by the user',
						type: ['string'],
					},
					legal_address: {
						description:
							"The legal address of the company in the given context. Samples: 'Rua Canarinho, 878'",
						type: ['string'],
					},
					legal_name: {
						description:
							"Company name in the given context. Samples: 'Socio Cerveceria'",
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
					state: {
						description:
							"The state name inputed by the user in the given context. Samples 'Minas Gerais', 'Arizona'",
						type: ['string'],
					},
					zip_code: {
						description:
							"The zip code name inputed by the user in the given context. Samples '02567-125', '10036'",
						type: ['string'],
					},
				},
				required: [
					'account_tier',
					'city',
					'country',
					'government_id',
					'legal_address',
					'legal_name',
					'screen_name',
					'service_model',
					'state',
					'zip_code',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Company Registration Submitted',
		type: 'object',
	}
	const message = {
		event: 'Company Registration Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Company Registration Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigged when the Distribution Centers List page loads



 *
 * @param {DcListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dcListViewed(
	props: DcListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Trigged when the Distribution Centers List page loads\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer'],
					},
					page_number: {
						description:
							'The number of the page in the given context. Samples: 1, 3, 5',
						type: ['integer'],
					},
					rows_per_page: {
						description: 'The number of Rows per page. Samples: 10, 15, 30',
						type: ['integer'],
					},
				},
				required: ['listed_items', 'page_number', 'rows_per_page'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'DC List Viewed',
		type: 'object',
	}
	const message = {
		event: 'DC List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'DC List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired when an element is clicked.
 *
 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementClicked(
	props: ElementClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Fired when an element is clicked.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					element_label: {
						description: 'The element text on the front end with localization.',
						type: ['string', 'null'],
					},
					element_location_name: {
						description:
							'The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name.',
						type: ['string', 'null'],
					},
					element_location_type: {
						description: 'The category of the object that anchors the button.',
						type: ['string', 'null'],
					},
					element_name: {
						description: 'The element name on the back end.',
						type: ['string', 'null'],
					},
					element_type: {
						description: 'The element type.',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action.',
						type: ['boolean', 'null'],
					},
					manufacturer_country: {
						description:
							'The country of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_display_name: {
						description:
							'The name of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							'The id of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_service_model: {
						description:
							'The Service model of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_tier_name: {
						description:
							'The tier of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					store_country: {
						description:
							'The country of the store that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					store_defaultseller: {
						description:
							'The sellers associated as default of the specific store.',
						type: ['string', 'null'],
					},
					store_id: {
						description:
							'The id of the store that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					store_name: {
						description:
							'The name of the store that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					store_status: {
						description:
							'The status can be enable, disable or in experimentation',
						enum: ['enable', 'disable', 'experimentation'],
						type: ['string', 'null'],
					},
					user_permission_group: {
						description:
							'The user permission group that relates to the application and the feature where the action is being executed.',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					view_level: {
						description: 'The view level the action is being executed.',
						type: ['string', 'null'],
					},
				},
				required: [
					'app_display_name',
					'app_id',
					'is_core_event',
					'manufacturer_country',
					'manufacturer_display_name',
					'manufacturer_id',
					'manufacturer_service_model',
					'manufacturer_tier_name',
					'user_permission_group',
					'vendor_country',
					'vendor_display_name',
					'vendor_id',
					'vendor_service_model',
					'vendor_tier_name',
					'store_id',
					'store_country',
					'store_name',
					'view_level',
					'element_location_type',
					'element_location_name',
					'element_label',
					'element_name',
					'element_type',
					'store_defaultseller',
					'store_status',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Element Clicked',
		type: 'object',
	}
	const message = {
		event: 'Element Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Element Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigged when the user inserts a new Company image
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function imageInserted(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Trigged when the user inserts a new Company image',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Image Inserted',
		type: 'object',
	}
	const message = {
		event: 'Image Inserted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Image Inserted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigged when the user updates a Company image
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function imageUpdated(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Trigged when the user updates a Company image',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Image Updated',
		type: 'object',
	}
	const message = {
		event: 'Image Updated',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Image Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to resend the registration email
 *
 * @param {InviteResent} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteResent(
	props: InviteResent,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to resend the registration email',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					company_users: {
						description:
							"The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								access_level: {
									description:
										'The user access level in a given context. Permitted Values: Admin, Editor, Viewer',
									enum: ['ADMIN', 'EDITOR', 'VIEWER', null],
									type: ['string', 'null'],
								},
								activation_date: {
									description:
										"The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '",
									format: 'date-time',
									type: ['string', 'null'],
								},
								company_user_id: {
									description:
										"The identificator of the user associated with a partner of BEES. Samples: 'x'",
									type: ['string', 'null'],
								},
								created_date: {
									description:
										"The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 ' ",
									format: 'date-time',
									type: ['string', 'null'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked',
									enum: [
										'ACTIVE',
										'BLOCKED',
										'EXPIRED',
										'INACTIVE',
										'PENDING',
										null,
									],
									type: ['string', 'null'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: [
								'access_level',
								'activation_date',
								'company_user_id',
								'created_date',
								'service_model',
								'user_status',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: ['company_users', 'screen_name', 'tab_label', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Invite Resent',
		type: 'object',
	}
	const message = {
		event: 'Invite Resent',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Invite Resent',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in a specific link
 *
 * @param {LinkClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function linkClicked(
	props: LinkClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'When the user clicks in a specific link',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					destination_url: {
						description:
							'Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html',
						type: ['string'],
					},
					link_name: {
						description:
							'The link identification name. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
				},
				required: ['destination_url', 'link_name', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Link Clicked',
		type: 'object',
	}
	const message = {
		event: 'Link Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Link Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects a value to filter a list based on a given criteria
 *
 * @param {ListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function listFiltered(
	props: ListFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects a value to filter a list based on a given criteria',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					filter_criteria: {
						description:
							"The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country'",
						enum: ['COUNTRY'],
						type: ['string'],
					},
					filter_value: {
						description:
							'The value chosen to filter the list. Which variable the user is using to perform the filtering action. Permitted values: ARGENTINA, BRAZIL, CANADA, CHILE, COLOMBIA, DOMINICAN_REPUBLIC, ECUADOR, HONDURAS, MEXICO, PANAMA, PERU, PARAGUAY, EL SALVADOR, TANZANIA, URUGUAY, SOUTH_AFRICA ',
						enum: [
							'ARGENTINA',
							'BRAZIL',
							'CANADA',
							'CHILE',
							'COLOMBIA',
							'DOMINICAN_REPUBLIC',
							'ECUADOR',
							'EL_SALVADOR',
							'HONDURAS',
							'MEXICO',
							'PANAMA',
							'PARAGUAY',
							'PERU',
							'SOUTH_AFRICA',
							'TANZANIA',
							'URUGUAY',
						],
						type: ['string'],
					},
					list_type: {
						description:
							'The identifier of the list, which list this event is related to. Permitted Values: COMPANY_LIST, USERS_LIST',
						enum: ['COMPANY_LIST', 'USERS_LIST'],
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
				},
				required: [
					'filter_criteria',
					'filter_value',
					'list_type',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'List Filtered',
		type: 'object',
	}
	const message = {
		event: 'List Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects terms to search a list based on a given criteria
 *
 * @param {ListSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function listSearched(
	props: ListSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects terms to search a list based on a given criteria',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					list_type: {
						description:
							'The identifier of the list, which list this event is related to. Permitted Values: COMPANY_LIST, USERS_LIST',
						enum: ['COMPANY_LIST', 'USERS_LIST'],
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					search_criteria: {
						description:
							"The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Price', 'Date'",
						type: ['string'],
					},
					search_tags: {
						description:
							"The array of search_tags. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								tag: {
									description:
										'A free form field to include all variable search tags available.',
									type: ['string'],
								},
							},
							required: ['tag'],
							type: ['object'],
						},
						type: ['array'],
					},
				},
				required: [
					'list_type',
					'screen_name',
					'search_criteria',
					'search_tags',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'List Searched',
		type: 'object',
	}
	const message = {
		event: 'List Searched',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'List Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired when the user sorts the user list
 *
 * @param {ListSorted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function listSorted(
	props: ListSorted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Fired when the user sorts the user list',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					list_type: {
						description:
							'The identifier of the list, which list this event is related to. Permitted Values: COMPANY_LIST, USERS_LIST',
						enum: ['COMPANY_LIST', 'USERS_LIST'],
						type: ['string'],
					},
					sort_criteria: {
						description:
							"The column that is being sorted. Which variable the user is using to perform the sorting action. Samples: 'Price', 'Date'",
						type: ['string'],
					},
					sort_type: {
						description:
							'The sorting type when the user performs a sorting action. Permitted Values: Ascending, Descending',
						enum: ['ASCENDING', 'DESCENDING'],
						type: ['string'],
					},
				},
				required: ['list_type', 'sort_criteria', 'sort_type'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'List Sorted',
		type: 'object',
	}
	const message = {
		event: 'List Sorted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'List Sorted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigged when the user clicks on the Distribution Center in the side menu


 *
 * @param {MenuInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteraction(
	props: MenuInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Trigged when the user clicks on the Distribution Center in the side menu\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					menu_category: {
						description:
							'Top level side menu. Sample: Settings, Users, Distribution Centers\n',
						type: ['string', 'null'],
					},
					menu_subcategory: {
						description:
							'Option chosen from the side menu . If the menu does NOT have a subcategory, log null. Sample Values: Cu...\n',
						type: ['string', 'null'],
					},
				},
				required: ['menu_category', 'menu_subcategory'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Menu Interaction',
		type: 'object',
	}
	const message = {
		event: 'Menu Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on a  page number to view other a second page for example (1, 2, 3, 4, 5, 6, … , 10)
 *
 * @param {PageSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageSelected(
	props: PageSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on a  page number to view other a second page for example (1, 2, 3, 4, 5, 6, … , 10)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer'],
					},
					page_number: {
						description:
							'The number of the page in the given context. Samples: 1, 3, 5',
						type: ['integer'],
					},
					rows_per_page: {
						description: 'The number of Rows per page. Samples: 10, 15, 30',
						type: ['integer'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
				},
				required: [
					'listed_items',
					'page_number',
					'rows_per_page',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Page Selected',
		type: 'object',
	}
	const message = {
		event: 'Page Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired when a page is viewed.
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Fired when a page is viewed.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_id: {
						description:
							'The unique id of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					app_name: {
						description:
							'The current name of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action.',
						type: ['boolean', 'null'],
					},
					store_country: {
						description:
							'The country of the store that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					store_id: {
						description:
							'The id of the store that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					store_name: {
						description:
							'The name of the store that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					user_permission_group: {
						description:
							'The user permission group that relates to the application and the feature where the action is being executed.',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					view_level: {
						description: 'The view level the action is being executed.',
						type: ['string', 'null'],
					},
				},
				required: [
					'app_name',
					'app_id',
					'is_core_event',
					'user_permission_group',
					'vendor_country',
					'vendor_display_name',
					'vendor_id',
					'vendor_service_model',
					'vendor_tier_name',
					'view_level',
					'store_id',
					'store_country',
					'store_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects new pagination row to list more/less items (10, 20, 50, 100)
 *
 * @param {PaginationRowSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paginationRowSelected(
	props: PaginationRowSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects new pagination row to list more/less items (10, 20, 50, 100)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer'],
					},
					page_number: {
						description:
							'The number of the page in the given context. Samples: 1, 3, 5',
						type: ['integer'],
					},
					rows_per_page: {
						description: 'The number of Rows per page. Samples: 10, 15, 30',
						type: ['integer'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
				},
				required: [
					'listed_items',
					'page_number',
					'rows_per_page',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Pagination Row Selected',
		type: 'object',
	}
	const message = {
		event: 'Pagination Row Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Pagination Row Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired when a search is applied.
 *
 * @param {SeachApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seachApplied(
	props: SeachApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Fired when a search is applied.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action.',
						type: ['boolean', 'null'],
					},
					manufacturer_country: {
						description:
							'The country of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_display_name: {
						description:
							'The name of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							'The id of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_service_model: {
						description:
							'The Service model of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_tier_name: {
						description:
							'The tier of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					number_of_results: {
						description: 'The number of results from that search.',
						type: ['integer', 'null'],
					},
					search_query: {
						description: 'The string that was searched.',
						type: ['string', 'null'],
					},
					user_permission_group: {
						description:
							'The user permission group that relates to the application and the feature where the action is being executed.',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string'],
					},
					vendor_service_model: {
						description:
							'The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					view_level: {
						description: 'The view level the action is being executed.',
						type: ['string', 'null'],
					},
				},
				required: [
					'app_display_name',
					'app_id',
					'is_core_event',
					'manufacturer_country',
					'manufacturer_display_name',
					'manufacturer_id',
					'manufacturer_service_model',
					'manufacturer_tier_name',
					'user_permission_group',
					'vendor_country',
					'vendor_display_name',
					'vendor_id',
					'vendor_service_model',
					'vendor_tier_name',
					'view_level',
					'search_query',
					'number_of_results',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Seach Applied',
		type: 'object',
	}
	const message = {
		event: 'Seach Applied',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Seach Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects a service model on the Company Registration journey and clicks on the "Add Company" button
 *
 * @param {ServiceModelSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function serviceModelSelected(
	props: ServiceModelSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects a service model on the Company Registration journey and clicks on the "Add Company" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					service_model: {
						description:
							'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
						enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'service_model'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Service Model Selected ',
		type: 'object',
	}
	const message = {
		event: 'Service Model Selected ',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Service Model Selected ',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigged when the user clicks on the "Cancel"
 *
 * @param {SettingsCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function settingsCanceled(
	props: SettingsCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Trigged when the user clicks on the "Cancel"',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					is_settings_cancelled: {
						description:
							'Indicates whether the user has clicked the "CANCEL" button, signifying the cancellation and disregard of all settings.',
						type: ['boolean', 'null'],
					},
				},
				required: ['is_settings_cancelled'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Settings Canceled',
		type: 'object',
	}
	const message = {
		event: 'Settings Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Settings Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigged when the user clicks on the "Save changes"
 *
 * @param {SettingsUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function settingsUpdated(
	props: SettingsUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Trigged when the user clicks on the "Save changes"',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					allowed_customers: {
						description:
							"Indicates what type of customers are eligible to see partner's products",
						enum: ['CNPJ_CPF', 'CPF', null],
						type: ['string', 'null'],
					},
					club_b_participation: {
						description:
							"Indicates whether the company's end users can participate in the Club B program and checkout orders by paying with points only.",
						type: ['boolean', 'null'],
					},
					empties: {
						description:
							"Indicates whether the company's end users can complete an order with empties only",
						type: ['boolean', 'null'],
					},
					has_new_payment_method: {
						description:
							'Indicates if the saving transaction contains any new payment method',
						type: ['boolean', 'null'],
					},
					inventory_solutions: {
						description:
							'Indicates what type of inventory solutions is in place',
						enum: ['BACKORDER', 'LIMITED', null],
						type: ['string', 'null'],
					},
					maximum_order: {
						description:
							"The array of maximum orders. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								amount: {
									description:
										'Represents the minimum required order value that a end user needs to meet in order to proceed with the checkout process. ',
									type: ['number', 'null'],
								},
								type: {
									description:
										'Represents the type the minimum order is based on',
									enum: ['ORDER_TOTAL', 'PRODUCT_QUANTITY', null],
									type: ['string', 'null'],
								},
							},
							required: ['amount', 'type'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					minimum_order: {
						description:
							"The array of minimum orders. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								amount: {
									description:
										'Represents the minimum required order value that a end user needs to meet in order to proceed with the checkout process. ',
									type: ['number', 'null'],
								},
								type: {
									description:
										'Represents the type the minimum order is based on',
									enum: ['ORDER_TOTAL', 'PRODUCT_QUANTITY', null],
									type: ['string', 'null'],
								},
							},
							required: ['amount', 'type'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					order_notification_bcc_email: {
						description:
							'Indicates if bcc emails list should receive order notifications',
						type: ['boolean', 'null'],
					},
					order_notification_type: {
						description:
							'Indicates if and what sales representatives associated with the company should receive order notifications.',
						enum: [
							'ALL_SALES_REPS',
							'ALL_SALES_REPS_SUPERVISOR',
							'NO_SALES_REP',
							'PRIMARY_SALES_REP',
							null,
						],
						type: ['string', 'null'],
					},
					payment_methods: {
						description:
							"The array of payments methods. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								action: {
									description:
										'Indicates if a payment method was just updated or created as new. ',
									enum: ['INSERT', 'UPDATE', null],
									type: ['string', 'null'],
								},
								category: {
									description:
										'Represents the category of new payment methods if selected from available list or created new',
									enum: ['AVAILABLE', 'CUSTOM', null],
									type: ['string', 'null'],
								},
								days_to_expire: {
									description:
										'Represents the number of days within which end users must make payment for bank slips when the "Bank Slip" payment method is selected. ',
									type: ['number', 'null'],
								},
								description: {
									description: 'Represents the payment method description',
									type: ['string', 'null'],
								},
								enabled: {
									description:
										'Indicates wheter a payment method is enabled or not',
									type: ['boolean', 'null'],
								},
								id: {
									description: 'Represents the payment method id',
									type: ['string', 'null'],
								},
								label: {
									description: 'Represents the payment method name',
									type: ['string', 'null'],
								},
								type: {
									description: 'Represents the payment method type',
									type: ['string', 'null'],
								},
							},
							required: [
								'action',
								'category',
								'days_to_expire',
								'description',
								'enabled',
								'id',
								'label',
								'type',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					recommendation: {
						description:
							"The array of recommendation. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								has_oos_replacement: {
									description:
										'Indicates if Out of Stock replecement is enabled',
									type: ['boolean', 'null'],
								},
								has_upselling: {
									description: 'Indicates if Upselling pop up is enabled',
									type: ['boolean', 'null'],
								},
								suggested_order_type: {
									description:
										'Indicates whether the carousel is default or personalized',
									enum: ['DEFAULT', 'PERSONALIZED', null],
									type: ['string', 'null'],
								},
							},
							required: [
								'has_oos_replacement',
								'has_upselling',
								'suggested_order_type',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
				},
				required: [
					'allowed_customers',
					'club_b_participation',
					'empties',
					'has_new_payment_method',
					'inventory_solutions',
					'maximum_order',
					'minimum_order',
					'order_notification_bcc_email',
					'order_notification_type',
					'payment_methods',
					'recommendation',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Settings Updated',
		type: 'object',
	}
	const message = {
		event: 'Settings Updated',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Settings Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects one of several tabs inside a page
 *
 * @param {TabInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tabInteraction(
	props: TabInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects one of several tabs inside a page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: ['screen_name', 'tab_label', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Tab Interaction',
		type: 'object',
	}
	const message = {
		event: 'Tab Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Tab Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user status is unblocked by an admin user
 *
 * @param {UserActivated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userActivated(
	props: UserActivated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user status is unblocked by an admin user',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					company_users: {
						description:
							"The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								access_level: {
									description:
										'The user access level in a given context. Permitted Values: Admin, Editor, Viewer',
									enum: ['ADMIN', 'EDITOR', 'VIEWER', null],
									type: ['string', 'null'],
								},
								activation_date: {
									description:
										"The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '",
									format: 'date-time',
									type: ['string', 'null'],
								},
								company_user_id: {
									description:
										"The identificator of the user associated with a partner of BEES. Samples: 'x'",
									type: ['string', 'null'],
								},
								created_date: {
									description:
										"The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 ' ",
									format: 'date-time',
									type: ['string', 'null'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked',
									enum: [
										'ACTIVE',
										'BLOCKED',
										'EXPIRED',
										'INACTIVE',
										'PENDING',
										null,
									],
									type: ['string', 'null'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: [
								'access_level',
								'activation_date',
								'company_user_id',
								'created_date',
								'service_model',
								'user_status',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: ['company_users', 'screen_name', 'tab_label', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Activated',
		type: 'object',
	}
	const message = {
		event: 'User Activated',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Activated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to cancel the user activation
 *
 * @param {UserActivationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userActivationCanceled(
	props: UserActivationCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to cancel the user activation',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					company_users: {
						description:
							"The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								access_level: {
									description:
										'The user access level in a given context. Permitted Values: Admin, Editor, Viewer',
									enum: ['ADMIN', 'EDITOR', 'VIEWER', null],
									type: ['string', 'null'],
								},
								activation_date: {
									description:
										"The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '",
									format: 'date-time',
									type: ['string', 'null'],
								},
								company_user_id: {
									description:
										"The identificator of the user associated with a partner of BEES. Samples: 'x'",
									type: ['string', 'null'],
								},
								created_date: {
									description:
										"The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 ' ",
									format: 'date-time',
									type: ['string', 'null'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked',
									enum: [
										'ACTIVE',
										'BLOCKED',
										'EXPIRED',
										'INACTIVE',
										'PENDING',
										null,
									],
									type: ['string', 'null'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: [
								'access_level',
								'activation_date',
								'company_user_id',
								'created_date',
								'service_model',
								'user_status',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: ['company_users', 'screen_name', 'tab_label', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Activation Canceled',
		type: 'object',
	}
	const message = {
		event: 'User Activation Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Activation Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user status is blocked by an admin user
 *
 * @param {UserDeactivated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userDeactivated(
	props: UserDeactivated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user status is blocked by an admin user',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					company_users: {
						description:
							"The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								access_level: {
									description:
										'The user access level in a given context. Permitted Values: Admin, Editor, Viewer',
									enum: ['ADMIN', 'EDITOR', 'VIEWER', null],
									type: ['string', 'null'],
								},
								activation_date: {
									description:
										"The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '",
									format: 'date-time',
									type: ['string', 'null'],
								},
								company_user_id: {
									description:
										"The identificator of the user associated with a partner of BEES. Samples: 'x'",
									type: ['string', 'null'],
								},
								created_date: {
									description:
										"The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 ' ",
									format: 'date-time',
									type: ['string', 'null'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked',
									enum: [
										'ACTIVE',
										'BLOCKED',
										'EXPIRED',
										'INACTIVE',
										'PENDING',
										null,
									],
									type: ['string', 'null'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: [
								'access_level',
								'activation_date',
								'company_user_id',
								'created_date',
								'service_model',
								'user_status',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: ['company_users', 'screen_name', 'tab_label', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Deactivated',
		type: 'object',
	}
	const message = {
		event: 'User Deactivated',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Deactivated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to cancel the user deactivation
 *
 * @param {UserDeactivationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userDeactivationCanceled(
	props: UserDeactivationCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to cancel the user deactivation',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					company_users: {
						description:
							"The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								access_level: {
									description:
										'The user access level in a given context. Permitted Values: Admin, Editor, Viewer',
									enum: ['ADMIN', 'EDITOR', 'VIEWER', null],
									type: ['string', 'null'],
								},
								activation_date: {
									description:
										"The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '",
									format: 'date-time',
									type: ['string', 'null'],
								},
								company_user_id: {
									description:
										"The identificator of the user associated with a partner of BEES. Samples: 'x'",
									type: ['string', 'null'],
								},
								created_date: {
									description:
										"The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 ' ",
									format: 'date-time',
									type: ['string', 'null'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked',
									enum: [
										'ACTIVE',
										'BLOCKED',
										'EXPIRED',
										'INACTIVE',
										'PENDING',
										null,
									],
									type: ['string', 'null'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: [
								'access_level',
								'activation_date',
								'company_user_id',
								'created_date',
								'service_model',
								'user_status',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: ['company_users', 'screen_name', 'tab_label', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Deactivation Canceled',
		type: 'object',
	}
	const message = {
		event: 'User Deactivation Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Deactivation Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered every time the Users List is loaded (including when the rows per page is changed or when the user navigates forward through the pages)
 *
 * @param {UserManagementListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userManagementListViewed(
	props: UserManagementListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered every time the Users List is loaded (including when the rows per page is changed or when the user navigates forward through the pages)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					company_users: {
						description:
							"The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								access_level: {
									description:
										'The user access level in a given context. Permitted Values: Admin, Editor, Viewer',
									enum: ['ADMIN', 'EDITOR', 'VIEWER', null],
									type: ['string', 'null'],
								},
								activation_date: {
									description:
										"The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '",
									format: 'date-time',
									type: ['string', 'null'],
								},
								company_user_id: {
									description:
										"The identificator of the user associated with a partner of BEES. Samples: 'x'",
									type: ['string', 'null'],
								},
								created_date: {
									description:
										"The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 ' ",
									format: 'date-time',
									type: ['string', 'null'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked',
									enum: [
										'ACTIVE',
										'BLOCKED',
										'EXPIRED',
										'INACTIVE',
										'PENDING',
										null,
									],
									type: ['string', 'null'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: [
								'access_level',
								'activation_date',
								'company_user_id',
								'created_date',
								'service_model',
								'user_status',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer'],
					},
					page_number: {
						description:
							'The number of the page in the given context. Samples: 1, 3, 5',
						type: ['integer'],
					},
					rows_per_page: {
						description: 'The number of Rows per page. Samples: 10, 15, 30',
						type: ['integer'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: [
					'company_users',
					'listed_items',
					'page_number',
					'rows_per_page',
					'screen_name',
					'tab_label',
					'tab_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Management List Viewed',
		type: 'object',
	}
	const message = {
		event: 'User Management List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Management List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts to change the user status by clicking on the "..." button on the Users Tab
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userMenuSelected(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts to change the user status by clicking on the "..." button on the Users Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Menu Selected',
		type: 'object',
	}
	const message = {
		event: 'User Menu Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Menu Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user cancels the User Registration journey by clicking on the "Cancel" button on the User Registration Modal
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userRegistrationCanceled(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user cancels the User Registration journey by clicking on the "Cancel" button on the User Registration Modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Registration Canceled',
		type: 'object',
	}
	const message = {
		event: 'User Registration Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Registration Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user gets a feedback error registering the user's list
 *
 * @param {UserRegistrationErrorsTriggered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userRegistrationErrorsTriggered(
	props: UserRegistrationErrorsTriggered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"Triggered when the user gets a feedback error registering the user's list",
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					error_message: {
						description:
							'The error feedback message displayed to the user in the given context.',
						type: ['string'],
					},
					error_type: {
						description:
							'The error information type in the given context. Permitted Values: INVALID_EMAIL_FORMAT, DUPLICATED_EMAILS_SAME_VENDOR, DUPLICATED_EMAILS_DIFFERENT_VENDORS',
						enum: [
							'DUPLICATED_EMAILS_DIFFERENT_VENDORS',
							'DUPLICATED_EMAILS_SAME_VENDOR',
							'INVALID_EMAIL_FORMAT',
						],
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: [
					'error_message',
					'error_type',
					'screen_name',
					'tab_label',
					'tab_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Registration Errors Triggered',
		type: 'object',
	}
	const message = {
		event: 'User Registration Errors Triggered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Registration Errors Triggered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the User Registration journey by clicking on the "Add user" button on the Users Tab
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userRegistrationStarted(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the User Registration journey by clicking on the "Add user" button on the Users Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Registration Started',
		type: 'object',
	}
	const message = {
		event: 'User Registration Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Registration Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the user registration is failed.
 *
 * @param {UserRegistrationSubmissionFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userRegistrationSubmissionFailed(
	props: UserRegistrationSubmissionFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the user registration is failed. ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					company_users: {
						description:
							"The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								access_level: {
									description:
										'The user access level in a given context. Permitted Values: Admin, Editor, Viewer',
									enum: ['ADMIN', 'EDITOR', 'VIEWER', null],
									type: ['string', 'null'],
								},
								activation_date: {
									description:
										"The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '",
									format: 'date-time',
									type: ['string', 'null'],
								},
								company_user_id: {
									description:
										"The identificator of the user associated with a partner of BEES. Samples: 'x'",
									type: ['string', 'null'],
								},
								created_date: {
									description:
										"The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 ' ",
									format: 'date-time',
									type: ['string', 'null'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked',
									enum: [
										'ACTIVE',
										'BLOCKED',
										'EXPIRED',
										'INACTIVE',
										'PENDING',
										null,
									],
									type: ['string', 'null'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: [
								'access_level',
								'activation_date',
								'company_user_id',
								'created_date',
								'service_model',
								'user_status',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					failure_reason: {
						description:
							'The reason of failure in the given context. Permitted Values: SOMETHING_WENT_WRONG',
						enum: ['SOMETHING_WENT_WRONG'],
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: [
					'company_users',
					'failure_reason',
					'screen_name',
					'tab_label',
					'tab_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Registration Submission Failed',
		type: 'object',
	}
	const message = {
		event: 'User Registration Submission Failed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Registration Submission Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the user registration is successful.
 *
 * @param {UserRegistrationSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userRegistrationSubmissionSuccessful(
	props: UserRegistrationSubmissionSuccessful,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the user registration is successful.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					company_users: {
						description:
							"The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								access_level: {
									description:
										'The user access level in a given context. Permitted Values: Admin, Editor, Viewer',
									enum: ['ADMIN', 'EDITOR', 'VIEWER', null],
									type: ['string', 'null'],
								},
								activation_date: {
									description:
										"The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '",
									format: 'date-time',
									type: ['string', 'null'],
								},
								company_user_id: {
									description:
										"The identificator of the user associated with a partner of BEES. Samples: 'x'",
									type: ['string', 'null'],
								},
								created_date: {
									description:
										"The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 ' ",
									format: 'date-time',
									type: ['string', 'null'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked',
									enum: [
										'ACTIVE',
										'BLOCKED',
										'EXPIRED',
										'INACTIVE',
										'PENDING',
										null,
									],
									type: ['string', 'null'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: [
								'access_level',
								'activation_date',
								'company_user_id',
								'created_date',
								'service_model',
								'user_status',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: ['company_users', 'screen_name', 'tab_label', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Registration Submission Successful',
		type: 'object',
	}
	const message = {
		event: 'User Registration Submission Successful',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Registration Submission Successful',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user finishes to fill-out the user registration forms and clicks on the "Add" button
 *
 * @param {UserRegistrationSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userRegistrationSubmitted(
	props: UserRegistrationSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user finishes to fill-out the user registration forms and clicks on the "Add" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					company_users: {
						description:
							"The array of company_users. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								access_level: {
									description:
										'The user access level in a given context. Permitted Values: Admin, Editor, Viewer',
									enum: ['ADMIN', 'EDITOR', 'VIEWER', null],
									type: ['string', 'null'],
								},
								activation_date: {
									description:
										"The date in which a user activated the registration and finished the process successfully. Samples: '2021-10-19 '",
									format: 'date-time',
									type: ['string', 'null'],
								},
								company_user_id: {
									description:
										"The identificator of the user associated with a partner of BEES. Samples: 'x'",
									type: ['string', 'null'],
								},
								created_date: {
									description:
										"The date in which a user was added to the list of a company's allowed users. Samples: '2021-10-19 ' ",
									format: 'date-time',
									type: ['string', 'null'],
								},
								service_model: {
									description:
										'The business model in which the company operates on BEES Partner Marketplace. Permitted values: 1P1L, 1P3L_REWARDS, 3P3L_SAME_STORE, 3P3L_STORE_FRONT, 3P1L',
									enum: ['1P', '1P-3L', '3P-1L', '3P-3L-SF', '3P-3L-SS', null],
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, Pending, Expired, Blocked',
									enum: [
										'ACTIVE',
										'BLOCKED',
										'EXPIRED',
										'INACTIVE',
										'PENDING',
										null,
									],
									type: ['string', 'null'],
								},
								vendor_id: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: [
								'access_level',
								'activation_date',
								'company_user_id',
								'created_date',
								'service_model',
								'user_status',
								'vendor_id',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
					tab_label: {
						description:
							'The tab label displayed to the user. Samples: Resend OTP Link, Active, Closed',
						type: ['string'],
					},
					tab_name: {
						description:
							'The name that represents the tab in english, not necessary the name displayed to the user. Permitted Values: COMPANY_MANAGEMENT_DETAILS\nCOMPANY_MANAGEMENT_DISTRIBUTION_CENTER\nCOMPANY_MANAGEMENT_USER\nCOMPANY_MANAGEMENT_SETTINGS',
						enum: [
							'COMPANY_MANAGEMENT_DETAILS',
							'COMPANY_MANAGEMENT_DISTRIBUTION_CENTER',
							'COMPANY_MANAGEMENT_SETTINGS',
							'COMPANY_MANAGEMENT_USER',
						],
						type: ['string'],
					},
				},
				required: ['company_users', 'screen_name', 'tab_label', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Registration Submitted',
		type: 'object',
	}
	const message = {
		event: 'User Registration Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Registration Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the vendor id icon to copy the value
 *
 * @param {VendorIdCopied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorIdCopied(
	props: VendorIdCopied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the vendor id icon to copy the value',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					copied_value: {
						description:
							"Copied value in the given context. Sample: '6096c481-a3c5-4cf4-9c4b-8a2d08dac26e'",
						type: ['string'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: COMPANY_MANAGEMENT',
						enum: ['COMPANY_MANAGEMENT'],
						type: ['string'],
					},
				},
				required: ['copied_value', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Vendor Id Copied',
		type: 'object',
	}
	const message = {
		event: 'Vendor Id Copied',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Vendor Id Copied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Triggered when the user clicks on "Cancel” button on the change tier modal
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountTierEditionCanceled,
	/**
	 * Triggered when the user clicks on “…” symbol to open change tier dropdown
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountTierEditionSelected,
	/**
	 * Triggered when the user clicks on "Change tier” button to open the change tier modal
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountTierEditionStarted,
	/**
	 * Triggered when the change tier process is failed
	 *
	 * @param {AccountTierEditionSubmissionFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountTierEditionSubmissionFailed,
	/**
	 * Triggered when the process of the change tier is successful
	 *
	 * @param {AccountTierEditionSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountTierEditionSubmissionSuccessful,
	/**
	 * Triggered when the user clicks on "Save” button on the change tier modal
	 *
	 * @param {AccountTierEditionSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountTierEditionSubmitted,
	/**
	 * Triggered when the user cancels the Company Edition journey by clicking on the "Cancel" button
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyDetailsEditionCanceled,
	/**
	 * Triggered when the user starts the Company Edition journey by clicking on the "Edit" button
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyDetailsEditionStarted,
	/**
	 * Triggered when the process of the Company Edition is failed
	 *
	 * @param {CompanyDetailsEditionSubmissionFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyDetailsEditionSubmissionFailed,
	/**
	 * Triggered when the process of the Company Edition is successful.
	 *
	 * @param {CompanyDetailsEditionSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyDetailsEditionSubmissionSuccessful,
	/**
	 * Triggered when the user finishes to fill-out the Company Edition forms and clicks on the "Save" button
	 *
	 * @param {CompanyDetailsEditionSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyDetailsEditionSubmitted,
	/**
	 * Triggered when the Details Tab of a Company's Page loads
	 *
	 * @param {CompanyDetailsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyDetailsViewed,
	/**
	 * Triggered every time the Company List is loaded (including when the rows per page is changed or when the user navigates forward through the pages)
	 *
	 * @param {CompanyListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyListViewed,
	/**
	 * Triggered when the user cancels the Company Registration journey by clicking on the "Cancel" button of the Company Registration Modal
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyRegistrationCanceled,
	/**
	 * Triggered when the user starts the Company Registration journey by clicking on the "Add Company" button
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyRegistrationStarted,
	/**
	 * Triggered when the process of the company registration is failed.
	 *
	 * @param {CompanyRegistrationSubmissionFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyRegistrationSubmissionFailed,
	/**
	 * Triggered when the process of the company registration is successful.
	 *
	 * @param {CompanyRegistrationSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyRegistrationSubmissionSuccessful,
	/**
	 * Triggered when the user finishes to fill-out the company registration forms and clicks on the "Add Company" button
	 *
	 * @param {CompanyRegistrationSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyRegistrationSubmitted,
	/**
	 * Trigged when the Distribution Centers List page loads
	
	
	
	 *
	 * @param {DcListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dcListViewed,
	/**
	 * Fired when an element is clicked.
	 *
	 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	elementClicked,
	/**
	 * Trigged when the user inserts a new Company image
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	imageInserted,
	/**
	 * Trigged when the user updates a Company image
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	imageUpdated,
	/**
	 * Triggered when the user clicks to resend the registration email
	 *
	 * @param {InviteResent} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteResent,
	/**
	 * When the user clicks in a specific link
	 *
	 * @param {LinkClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	linkClicked,
	/**
	 * Triggered when the user selects a value to filter a list based on a given criteria
	 *
	 * @param {ListFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	listFiltered,
	/**
	 * Triggered when the user selects terms to search a list based on a given criteria
	 *
	 * @param {ListSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	listSearched,
	/**
	 * Fired when the user sorts the user list
	 *
	 * @param {ListSorted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	listSorted,
	/**
	 * Trigged when the user clicks on the Distribution Center in the side menu
	
	
	 *
	 * @param {MenuInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteraction,
	/**
	 * Triggered when the user clicks on a  page number to view other a second page for example (1, 2, 3, 4, 5, 6, … , 10)
	 *
	 * @param {PageSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageSelected,
	/**
	 * Fired when a page is viewed.
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Triggered when the user selects new pagination row to list more/less items (10, 20, 50, 100)
	 *
	 * @param {PaginationRowSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paginationRowSelected,
	/**
	 * Fired when a search is applied.
	 *
	 * @param {SeachApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	seachApplied,
	/**
	 * Triggered when the user selects a service model on the Company Registration journey and clicks on the "Add Company" button
	 *
	 * @param {ServiceModelSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	serviceModelSelected,
	/**
	 * Trigged when the user clicks on the "Cancel"
	 *
	 * @param {SettingsCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	settingsCanceled,
	/**
	 * Trigged when the user clicks on the "Save changes"
	 *
	 * @param {SettingsUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	settingsUpdated,
	/**
	 * Triggered when the user selects one of several tabs inside a page
	 *
	 * @param {TabInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tabInteraction,
	/**
	 * Triggered when the user status is unblocked by an admin user
	 *
	 * @param {UserActivated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userActivated,
	/**
	 * Triggered when the user clicks to cancel the user activation
	 *
	 * @param {UserActivationCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userActivationCanceled,
	/**
	 * Triggered when the user status is blocked by an admin user
	 *
	 * @param {UserDeactivated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userDeactivated,
	/**
	 * Triggered when the user clicks to cancel the user deactivation
	 *
	 * @param {UserDeactivationCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userDeactivationCanceled,
	/**
	 * Triggered every time the Users List is loaded (including when the rows per page is changed or when the user navigates forward through the pages)
	 *
	 * @param {UserManagementListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userManagementListViewed,
	/**
	 * Triggered when the user starts to change the user status by clicking on the "..." button on the Users Tab
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userMenuSelected,
	/**
	 * Triggered when the user cancels the User Registration journey by clicking on the "Cancel" button on the User Registration Modal
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userRegistrationCanceled,
	/**
	 * Triggered when the user gets a feedback error registering the user's list
	 *
	 * @param {UserRegistrationErrorsTriggered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userRegistrationErrorsTriggered,
	/**
	 * Triggered when the user starts the User Registration journey by clicking on the "Add user" button on the Users Tab
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userRegistrationStarted,
	/**
	 * Triggered when the process of the user registration is failed.
	 *
	 * @param {UserRegistrationSubmissionFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userRegistrationSubmissionFailed,
	/**
	 * Triggered when the process of the user registration is successful.
	 *
	 * @param {UserRegistrationSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userRegistrationSubmissionSuccessful,
	/**
	 * Triggered when the user finishes to fill-out the user registration forms and clicks on the "Add" button
	 *
	 * @param {UserRegistrationSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userRegistrationSubmitted,
	/**
	 * Triggered when the user clicks on the vendor id icon to copy the value
	 *
	 * @param {VendorIdCopied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorIdCopied,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_1s8VobEORP8Yp7gMEk6vXRbOBBL`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
