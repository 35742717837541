import { H5 } from '@core/components/typography';
import { Tooltip } from '@hexa-ui/components';
import { styled } from 'theme';

export const Container = styled('div', {
  marginTop: '$10',
});

export const InfoContainer = styled('div', {
  display: 'flex',
});

export const InfoTooltip = styled(Tooltip, {});

export const InfoRedeem = styled(H5, {
  '&&': {
    marginRight: '$2',
  },
});
