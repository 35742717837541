import React from 'react';
import { useIntl } from 'react-intl';
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_HEIGHT,
  MAX_FILE_SIZE,
} from '@core/components/file-uploader/constants';
import { useFileUploader } from '@core/hooks';
import {
  FileUploaderType,
  FileValidated,
  FileUploader as HexaFileUploader,
} from '@hexa-ui/components';
import { FileUploaderContainer } from './FileUploader.styles';
import { FileUploaderProps } from './types';

export const FileUploader = ({
  onDrop: onDropProps,
  initialImage,
  hasPermission = true,
  message,
  error,
}: FileUploaderProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    onDrop,
    isInvalidFile,
    uploadedFiles,
    currentErrorMessage,
    checkIfImageHasErrors,
  } = useFileUploader(initialImage);

  const UPLOAD_IMAGE_BTN = formatMessage({ id: 'COMPANY_DETAILS.UPLOAD_IMAGE_BTN' });
  const FILE_UPLOADER_LABEL = formatMessage({ id: 'COMPANY_DETAILS.IMAGE_LABEL' });
  const FILE_UPLOADER_UNAUTHORIZED_LABEL = formatMessage({
    id: 'COMPANY_DETAILS.IMAGE_UNAUTHORIZED_LABEL',
  });

  const errorInternal: Pick<FileUploaderType, 'error' | 'message'> | undefined = isInvalidFile
    ? {
        message: currentErrorMessage,
      }
    : undefined;

  const handleOnDrop = async (filesToValidate: FileValidated[]) => {
    if (!hasPermission) return;

    onDrop(filesToValidate);

    const currentFile = filesToValidate[0].file;

    const hasError = await checkIfImageHasErrors(currentFile);

    if (onDropProps && !hasError) onDropProps(filesToValidate);
  };

  return (
    <FileUploaderContainer hasPermission={hasPermission}>
      <HexaFileUploader
        accept={ACCEPTED_FILE_TYPES}
        maxFiles={1}
        imageFit="contain"
        maxFileSize={MAX_FILE_SIZE}
        buttonIntl={UPLOAD_IMAGE_BTN}
        message={
          message || (hasPermission ? FILE_UPLOADER_LABEL : FILE_UPLOADER_UNAUTHORIZED_LABEL)
        }
        imageHeight={`${MAX_FILE_HEIGHT}px`}
        value={uploadedFiles}
        error={error ?? errorInternal}
        onDrop={handleOnDrop}
        clickable={hasPermission}
      />
    </FileUploaderContainer>
  );
};
