import { useFeatureToggleV2 } from 'admin-portal-shared-services';

import type { UseFeatureToggleResult } from '@core/hooks/useFeatureToggle/types';
import { OPTIMIZELY_PROJECT_NAME } from 'config/constants';

export const useFeatureToggle = (featureToggleName: string): UseFeatureToggleResult => {
  const [featureToggleStatus, isFeatureToggleLoading] = useFeatureToggleV2(
    featureToggleName,
    OPTIMIZELY_PROJECT_NAME
  );

  const isFeatureToggleActivated = featureToggleStatus === true && !isFeatureToggleLoading;

  return {
    isFeatureToggleActivated,
    featureToggleStatus,
    isFeatureToggleLoading,
  };
};
