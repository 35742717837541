import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { Abbreviation } from 'admin-portal-shared-services/dist/services/businessModel/BusinessModelService';
import { DEFAULT_LANGUAGE } from 'config/constants';
import { allMessages } from 'i18n/provider';

export const usePreferredLanguage = (): Abbreviation => {
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();
  const language = (preferredLanguage ?? defaultLanguage) as Abbreviation;
  const hasMessages = allMessages[language];

  return hasMessages ? language : DEFAULT_LANGUAGE;
};

export default usePreferredLanguage;
