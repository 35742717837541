import {
  Container,
  InfoContainer,
  InfoRedeem,
  InfoTooltip,
} from '@company-settings/components/company-settings-tab/pay-and-redeem/redeem/Redeem.styles';
import { Info } from '@hexa-ui/icons';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RedeemOptions } from './redeem-options/RedeemOptions';

export const Redeem = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <InfoContainer>
        <InfoRedeem>
          <FormattedMessage id="COMPANY_SETTINGS.REDEEM_DESCRIPTION" />
        </InfoRedeem>
        <InfoTooltip
          placement="right"
          text={formatMessage({
            id: `COMPANY_SETTINGS.REDEEM_DESCRIPTION_TOOLTIP`,
          })}
        >
          <Info size="medium" fr="100%" />
        </InfoTooltip>
      </InfoContainer>

      <RedeemOptions />
    </Container>
  );
};
