import { H5 } from '@core/components';
import { styled } from 'theme';

export const Container = styled(`div`, {
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'flex-start',
  flex: '2',
  gap: '$4',
});

export const Title = styled(H5, {
  '&&': {
    display: 'inline-block',
    marginRight: '$2',
  },
});
