import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  SubTitle,
} from '@company-settings/components/company-settings-tab/order-notifications/OrderNotifications.styles';
import { EmailListField } from '@company-settings/components/company-settings-tab/order-notifications/email-list-field/EmailListField';
import { OrderNotificationOptions } from '@company-settings/components/company-settings-tab/order-notifications/order-notification-options/OrderNotificationOptions';
import { CompanySettingsV2 } from '@company-settings/types';
import { H3 } from '@core/components';

export interface OrderNotificationsProps {
  orderNotificationOptions: CompanySettingsV2['orderNotificationOptions'];
}

export const OrderNotifications = ({
  orderNotificationOptions,
}: OrderNotificationsProps): JSX.Element | null => (
  <Container>
    <H3>
      <FormattedMessage id="COMPANY_SETTINGS.ORDER_NOTIFICATIONS_TITLE" />
    </H3>
    <SubTitle>
      <FormattedMessage id="COMPANY_SETTINGS.ORDER_NOTIFICATIONS_SUBTITLE" />
    </SubTitle>
    <OrderNotificationOptions options={orderNotificationOptions} />
    <EmailListField />
  </Container>
);
