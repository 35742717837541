import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Container,
  EmptiesConfigurationTitle,
  EmptiesConfigurationTitleContainer,
  EmptiesConfigurationTooltip,
} from '@company-settings/components/company-settings-tab/empties-configuration/EmptiesConfiguration.styles';
import { Empties } from '@company-settings/components/company-settings-tab/empties-configuration/empties/Empties';
import { Info } from '@hexa-ui/icons';

export const EmptiesConfiguration = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <EmptiesConfigurationTitleContainer>
        <EmptiesConfigurationTitle>
          <FormattedMessage id="COMPANY_SETTINGS.EMPTIES_CONFIGURATION_TITLE" />
        </EmptiesConfigurationTitle>
        <EmptiesConfigurationTooltip
          placement="right"
          text={formatMessage({
            id: `COMPANY_SETTINGS.EMPTIES_DESCRIPTION_TOOLTIP`,
          })}
        >
          <Info size="large" fr="100%" />
        </EmptiesConfigurationTooltip>
      </EmptiesConfigurationTitleContainer>
      <Empties />
    </Container>
  );
};
