import { useEffect, useState } from 'react';
import {
  createFeatureToggleServiceV2,
  useFeatureToggleServiceV2 as featureToggleService,
} from 'admin-portal-shared-services';
import { OPTIMIZELY_PROJECT_NAME } from 'config/constants';

type UseInitializeFeatureToggleResult = {
  isReady: boolean;
};

const isFeatureToggleServiceLoaded = () =>
  window.services?.FeatureToggleOptimizelyV2 &&
  // @ts-expect-error allow type any for services object
  window.services?.FeatureToggleOptimizelyV2[OPTIMIZELY_PROJECT_NAME];

export const useInitializeFeatureToggle = (
  optimizelyKey: string
): UseInitializeFeatureToggleResult => {
  const [isReady, setIsReady] = useState<boolean>(false);

  const handleShowingVendorPage = (): void => {
    const toggleService = featureToggleService(OPTIMIZELY_PROJECT_NAME);
    if (toggleService.getIsReady()) {
      setIsReady(true);
      return;
    }
    toggleService.onReady().then(() => {
      setIsReady(true);
    });
  };

  useEffect(() => {
    if (isFeatureToggleServiceLoaded()) {
      handleShowingVendorPage();
      return;
    }

    createFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME, optimizelyKey);
    handleShowingVendorPage();
  }, [optimizelyKey]);

  return {
    isReady,
  };
};
