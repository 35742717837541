import { AllCountries } from 'domains/Countries';
import ISearchType from 'domains/SearchType';
import { IVendorMS } from 'domains/Vendor';
import { createStore } from 'effector';
import { Location } from 'history';
import { cloneDeep } from 'lodash';
import segmentAnalytics from 'tracking/generated';
import { ListType, ScreenName } from 'tracking/types.deprecated';
import { setLocalCountry } from 'utils/localData';
import { removeQueryParam } from 'utils/paginateUtils';
import { execute as LoadVendorsService } from '../../services/vendor/loadVendorsService';
import {
  addNewVendor,
  changePagination,
  changeSearchType,
  changeSearchValue,
  createVendor,
  createVendorDone,
  createVendorFail,
  incrementTierFetchErrorTimes,
  loadAllVendors,
  loadAllVendorsDone,
  loadAllVendorsFail,
  resetSearch,
  resetTierFetchErrorTimes,
  searchVendors,
  selectCountry,
  setAvailableTiers,
} from './VendorEvents';
import VendorState from './VendorState';

export const loadVendors = async (
  selectedCountry: AllCountries | null,
  page: number,
  pageSize: number,
  partialSearch?: string
): Promise<void> => {
  await LoadVendorsService(
    selectedCountry as string,
    page,
    pageSize,
    partialSearch
  );
};

const initialState = <VendorState>{
  isLoading: true,
  isUpdating: false,
  filters: {
    selectedCountry: null,
    page: 0,
    pageSize: 10,
    searchType: ISearchType.LEGAL_NAME,
    searchValue: '',
    partialGovernmentId: '',
    partialLegalName: '',
  },
  vendors: null,
  hasError: false,
  tierFetchErrorTimes: 0,
  availableTiers: [],
  hasPageSizeChanged: false,
  hasPageChanged: false,
};
const location = (window.location as unknown) as Location;
let searchTimeout: NodeJS.Timeout;

const VendorStore = createStore(initialState)
  .on(selectCountry, (state, payload) => {
    const newState = {
      ...state,
      filters: {
        ...state.filters,
        page: 0,
        selectedCountry: payload.selectedCountry,
        searchValue:
          state.filters.searchType === ISearchType.GOVERNMENT_ID ? '' : state.filters.searchValue,
      },
    };

    const partialSearch = getPartialSearch(newState, location, payload.history);

    loadVendors(
      payload.selectedCountry,
      newState.filters.page,
      newState.filters.pageSize,
      partialSearch
    );
    setLocalCountry(payload.selectedCountry);

    return newState;
  })
  .on(changePagination, (state, payload) => {
    const newState = {
      ...state,
      filters: {
        ...state.filters,
        page: payload.pagination.page,
        pageSize: payload.pagination.pageSize,
        partialGovernmentId: payload.pagination.partialGovernmentId,
        partialLegalName: payload.pagination.partialLegalName,
      },
    };

    const isTheSamePage = state.filters.page === payload.pagination.page;
    const isTheSamePageSize = state.filters.pageSize === payload.pagination.pageSize;
    const isTheSamePartialGovernmentId =
      state.filters.partialGovernmentId === payload.pagination.partialGovernmentId;
    const isTheSamePartialLegalName =
      state.filters.partialLegalName === payload.pagination.partialLegalName;
    if (!isTheSamePageSize) {
      newState.hasPageSizeChanged = true;
    }
    if (!isTheSamePage) {
      newState.hasPageChanged = true;
    }
    if (
      !isTheSamePage ||
      !isTheSamePageSize ||
      !isTheSamePartialGovernmentId ||
      !isTheSamePartialLegalName
    ) {
      const partialSearch = getPartialSearch(newState, location, payload.history);

      loadVendors(
        state.filters.selectedCountry,
        payload.pagination.page,
        payload.pagination.pageSize,
        partialSearch
      );
    }

    return newState;
  })
  .on(changeSearchType, (state, payload) => {
    const newState = {
      ...state,
      filters: {
        ...state.filters,
        page: 0,
        searchValue: '',
        searchType: payload.searchType as ISearchType,
      },
    };
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    clearParams(location, payload.history);

    loadVendors(newState.filters.selectedCountry, newState.filters.page, newState.filters.pageSize);

    return newState;
  })
  .on(changeSearchValue, (state, payload) => {
    const newState = {
      ...state,
      filters: { ...state.filters, page: 0, searchValue: payload.searchValue },
    };
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      const partialSearch = getPartialSearch(newState, location, payload.history);

      loadVendors(
        newState.filters.selectedCountry,
        newState.filters.page,
        newState.filters.pageSize,
        partialSearch
      );
    }, 1700);

    return newState;
  })
  .on(searchVendors, (state, payload) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const partialSearch = getPartialSearch(state, location, payload.history);

    loadVendors(
      state.filters.selectedCountry,
      state.filters.page,
      state.filters.pageSize,
      partialSearch
    );

    segmentAnalytics.listSearched({
      list_type: ListType.CompanyList,
      screen_name: ScreenName.CompanyManagement,
      search_criteria: state.filters.searchType as ISearchType,
      search_tags: [
        {
          tag: state.filters.searchValue,
        },
      ],
    });
  })
  .on(resetSearch, (state) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const newState = {
      ...initialState,
      filters: {
        ...initialState.filters,
        selectedCountry: state.filters.selectedCountry,
      },
    };
    return newState;
  })
  .on(loadAllVendors, (state) => ({ ...state, isLoading: true }))
  .on(loadAllVendorsDone, (state, vendors) => {
    const segmentBody = {
      listed_items: vendors?.content?.length,
      page_number: state.filters.page + 1,
      rows_per_page: state.filters.pageSize,
      screen_name: ScreenName.CompanyManagement,
    };
    if (state.hasPageSizeChanged) {
      segmentAnalytics.paginationRowSelected(segmentBody);
    }
    if (state.hasPageChanged) {
      segmentAnalytics.pageSelected(segmentBody);
    }

    return {
      ...state,
      isLoading: false,
      vendors,
      hasPageSizeChanged: false,
      hasPageChanged: false,
    };
  })
  .on(loadAllVendorsFail, (state) => ({ ...state, isLoading: false }))
  .on(createVendor, (state) => ({ ...state, isUpdating: true, hasError: false }))
  .on(createVendorDone, (state, payload) => {
    const newState = { ...state, isUpdating: false, hasError: false };

    const partialSearch = getPartialSearch(newState, location, payload.history);

    loadVendors(
      state.filters.selectedCountry,
      state.filters.page,
      state.filters.pageSize,
      partialSearch
    );

    return newState;
  })
  .on(createVendorFail, (state) => ({ ...state, isUpdating: false, hasError: true }))
  .on(addNewVendor, (state, vendor) => {
    const newState = cloneDeep(state);
    newState.vendors?.content.push(vendor as IVendorMS);
    return newState;
  })
  .on(incrementTierFetchErrorTimes, (state) => {
    const newState = {
      ...state,
      tierFetchErrorTimes: state.tierFetchErrorTimes + 1,
    };
    return newState;
  })
  .on(resetTierFetchErrorTimes, (state) => {
    const newState = {
      ...state,
      tierFetchErrorTimes: 0,
    };
    return newState;
  })
  .on(setAvailableTiers, (state, availableTiers) => ({
    ...state,
    availableTiers,
  }));

const clearParams = (loc: Location, history: unknown) => {
  removeQueryParam(loc, history, 'partialLegalName');
  removeQueryParam(loc, history, 'partialGovernmentId');
};
const getPartialSearch = (state: VendorState, loc: Location, history: unknown) => {
  let partialSearch = '';

  if (state.filters.searchValue) {
    clearParams(loc, history);
    if (state.filters.searchType === ISearchType.LEGAL_NAME) {
      partialSearch = `&partialLegalName=${state.filters.searchValue}`;
    }
    if (state.filters.searchType === ISearchType.GOVERNMENT_ID) {
      partialSearch = `&partialGovernmentId=${state.filters.searchValue}`;
    }
    if (history)
      (history as Array<any>).push({
        pathname: '',
        search: `${loc.search}${partialSearch}`,
      });
  } else {
    clearParams(loc, history);
  }

  return partialSearch;
};

export default VendorStore;
