import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  InfoContainer,
  InfoEmpties,
  Radio,
  RadioGroup,
} from '@company-settings/components/company-settings-tab/empties-configuration/empties/Empties.styles';
import { useEmpties } from '@company-settings/hooks';

export const Empties = (): JSX.Element => {
  const {
    emptiesChosenOption,
    emptiesOptions,
    handlerChange,
    emptiesRadioGroupName,
  } = useEmpties();

  return (
    <Container>
      <InfoContainer>
        <InfoEmpties>
          <FormattedMessage id="COMPANY_SETTINGS.EMPTIES_DESCRIPTION" />
        </InfoEmpties>
      </InfoContainer>
      <RadioGroup id={emptiesRadioGroupName} name={emptiesRadioGroupName} onChange={handlerChange}>
        {emptiesOptions.map((emptiesOption) => (
          <Radio
            key={emptiesOption.id}
            id={emptiesOption.id}
            label={emptiesOption.label}
            value={(emptiesOption.value as unknown) as string}
            checked={emptiesOption.value === emptiesChosenOption}
          />
        ))}
      </RadioGroup>
    </Container>
  );
};
