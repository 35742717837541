import React from 'react';
import { LoadingContainer, LoadingStyled } from './PageLoading.styles';

const PageLoading = (): JSX.Element => (
  <LoadingContainer>
    <LoadingStyled data-testid="pageLoader" />
  </LoadingContainer>
);

export default PageLoading;
