import { useMemo } from 'react';
import { useShowEmptiesConfiguration } from '@company-settings/hooks/useShowEmptiesConfiguration';
import { useShowOrderNotifications } from '@company-settings/hooks/useShowOrderNotifications';
import { useShowPayAndRedeem } from '@company-settings/hooks/useShowPayAndRedeem';
import { useFeatureToggle } from '@core/hooks/useFeatureToggle';
import FeatureTogglesV2 from 'config/featureTogglesV2';

export const useCompanySettingsTabAccess = (): boolean => {
  const { isFeatureToggleActivated } = useFeatureToggle(FeatureTogglesV2.SHOW_COMPANY_SETTINGS_TAB);

  const hasOrderNotification = useShowOrderNotifications();
  const hasEmptiesConfiguration = useShowEmptiesConfiguration();
  const hasPayAndRedeem = useShowPayAndRedeem();

  const hasAnyPermission = hasOrderNotification || hasEmptiesConfiguration || hasPayAndRedeem;

  const hasPermissionToAccess = useMemo(() => isFeatureToggleActivated && hasAnyPermission, [
    isFeatureToggleActivated,
    hasAnyPermission,
  ]);

  return hasPermissionToAccess;
};
