const permissions = {
  CompanyManagement: {
    READ: 'AdminPortalVendor.CompanyManagement.Read',
    WRITE: 'AdminPortalVendor.CompanyManagement.Write',
    ALL_COUNTRIES: 'AdminPortalVendor.CompanyManagement.Countries.All',
  },
  CompanyManagementGlobal: {
    WRITE: 'AdminPortalVendor.CompanyManagementGlobal.Write',
    READ: 'AdminPortalVendor.CompanyManagementGlobal.Read',
    ALL_COUNTRIES: 'AdminPortalVendor.CompanyManagementGlobal.Countries.All',
  },
  CompanyManagementLocal: {
    WRITE: 'AdminPortalVendor.CompanyManagementLocal.Write',
    READ: 'AdminPortalVendor.CompanyManagementLocal.Read',
  },
  CompanyManagementItems: {
    WRITE: 'AdminPortalVendor.CompanyManagementItems.Write',
  },
  PaymentAndOrders: {
    READ: 'AdminPortalVendor.PaymentAndOrders.Read',
    WRITE: 'AdminPortalVendor.PaymentAndOrders.Write',
  },
  Recommendations: {
    WRITE: 'AdminPortalVendor.Recommendations.Write',
    READ: 'AdminPortalVendor.Recommendations.Read',
  },
  StoreManagement: {
    READ: 'AdminPortalVendor.StoreManagement.Read',
    WRITE: 'AdminPortalVendor.StoreManagement.Write',
  },
  GeneralInformation: {
    WRITE: 'AdminPortalVendor.GeneralInformation.Write',
  },
  CompanySettings: {
    OrderNotification: {
      WRITE: 'CompanySettings.OrderNotification.Write',
      READ: 'CompanySettings.OrderNotification.Read',
    },
    PaymentMethod: {
      WRITE: 'CompanySettings.PaymentMethod.Write',
      READ: 'CompanySettings.PaymentMethod.Read',
    },
    Image: {
      WRITE: 'CompanySettings.Image.Write',
      READ: 'CompanySettings.Image.Read',
    },
  },
  AdminPortalDC: {
    DDC: {
      WRITE: 'AdminPortalDC.DDC.Write',
      READ: 'AdminPortalDC.DDC.Read',
    },
    DDCGlobal: {
      WRITE: 'AdminPortalDC.DDCGlobal.Write',
      READ: 'AdminPortalDC.DDCGlobal.Read',
    },
  },
  Group: {
    WRITE: 'AdminPortalVendor.Group.Write',
    READ: 'AdminPortalVendor.Group.Read',
  },
};

export default permissions;
