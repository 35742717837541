import { useField } from 'formik';

import { OrderTypes, PaymentMethodTypes } from '@company-settings/enums';
import type {
  UseCashPaymentMethodProps,
  UseCashPaymentMethodResult,
} from '@company-settings/hooks/types';
import { useGenericPaymentMethod } from '@company-settings/hooks/useGenericPaymentMethod';
import type { CashPaymentMethod } from '@company-settings/types';
import { hasValue } from 'utils';

const INVALID_MINIMUM_ORDER_VALUE = 0;

const canSelectPaymentMethod = (values?: CashPaymentMethod): boolean =>
  !hasValue(values) || values.minimumOrder.value <= INVALID_MINIMUM_ORDER_VALUE;

export const useCashPaymentMethod = ({
  paymentIndex,
}: UseCashPaymentMethodProps): UseCashPaymentMethodResult => {
  const { fieldNamePrefix, values, isEnabled: isMinimumOrderEnabled } = useGenericPaymentMethod({
    type: PaymentMethodTypes.Cash,
    paymentIndex,
  });
  const [, minimumOrderMeta, minimumOrderHelpers] = useField(`${fieldNamePrefix}.minimumOrder`);
  const isPaymentCheckboxEnabled = canSelectPaymentMethod(values as CashPaymentMethod);

  const handleSelectionChange = (selected: boolean) => {
    if (isPaymentCheckboxEnabled) {
      if (selected) {
        updateMinimumOrder();
        return;
      }

      resetMinimumOrder();
    }
  };

  const updateMinimumOrder = () => {
    const minimumOrderValue = {
      type: OrderTypes.OrderTotal,
      value: minimumOrderMeta.value?.value ?? INVALID_MINIMUM_ORDER_VALUE,
    };

    minimumOrderHelpers.setValue(minimumOrderValue);
  };

  const resetMinimumOrder = () => {
    const minimumOrderValue = { type: OrderTypes.OrderTotal, value: INVALID_MINIMUM_ORDER_VALUE };

    minimumOrderHelpers.setValue(minimumOrderValue);
  };

  return {
    fieldNamePrefix,
    handleSelectionChange,
    isMinimumOrderEnabled,
    isPaymentCheckboxEnabled,
  };
};
