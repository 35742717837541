import { H3, H5 } from '@core/components';
import { Card as BEESCard, Heading as BEESHeading } from '@hexa-ui/components';
import { styled } from 'theme';

export const Heading = styled(BEESHeading, {});

export const Card = styled(BEESCard, {
  height: '$20',
  width: '$20',
});

export const Container = styled('div', {
  display: 'flex',
  flexFlow: 'column',
  marginBottom: '$6',
});

export const Item = styled('div', {});

export const PayAndRedeemTitle = styled(H3, {
  '&&&': {
    marginBottom: '$4',
  },
});

export const PayAndRedeemSubtitle = styled(H5, {
  '&&&': {
    marginBottom: '$6',
  },
});

export const PaymentContainer = styled('div', {
  display: 'flex',
  gap: '$6',
  flexWrap: 'wrap',
});

export const NewPaymentContainer = styled('div', {
  display: 'grid',
  gap: '$6',
  gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 350px))',
  marginRight: '$8',
  marginBottom: '$10',
});
