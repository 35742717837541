import { useAnalyticsService, useAuthenticationService } from 'admin-portal-shared-services';
import { useEffect } from 'react';

type AnalyticsServiceInternal = ReturnType<typeof useAnalyticsService>;

const useLoadAnalytics = (analyticsService: AnalyticsServiceInternal, segmentKey: string): void => {
  useEffect(() => {
    analyticsService.load(segmentKey);
  }, [analyticsService, segmentKey]);
};

const useIdentifyAnalytics = (analyticsService: AnalyticsServiceInternal): void => {
  const authenticationService = useAuthenticationService();

  useEffect(() => {
    const userFullName = authenticationService.getUserFullNameB2C();
    const traits = {
      country: authenticationService.getCountryB2C(),
      email: authenticationService.getUserEmailB2C(),
      first_name: userFullName.given_name,
      last_name: userFullName.family_name,
    };

    analyticsService.identify(traits);
  }, [analyticsService, authenticationService]);
};

export const useSegmentAnalytics = (segmentKey: string): void => {
  const analyticsService = useAnalyticsService();

  useLoadAnalytics(analyticsService, segmentKey);
  useIdentifyAnalytics(analyticsService);
};

export default useSegmentAnalytics;
