import { useParams } from 'react-router-dom';
import { OrderNotificationOptions, PaymentMethodTypes } from '@company-settings/enums';
import { CompanySettingsFormV2Schema } from '@company-settings/hooks/schemas/CompanySettingsFormV2Schema';
import type { UseCompanySettingsFormV2Result } from '@company-settings/hooks/types';
import type {
  CheckoutSettingsV2,
  CompanySettingsV2Form,
  GenericPaymentMethod,
  ImageSettings,
  OrderNotification,
} from '@company-settings/types';
import { path, prop, propOr, whereEq } from 'ramda';
import { useUpsertCompanySettingsV2 } from 'services/query/useUpsertCompanySettingsV2';
import { settingsUpdated } from 'tracking/generated';
import { useCompanySettingsInitialValuesV2 } from './useCompanySettingsInitialValuesV2';
import { useParseSegmentEventProps } from './useParseSegmentEventProps';

const getChangedPaymentMethods = (
  initialCheckoutSettings?: CheckoutSettingsV2,
  checkoutSettings?: CheckoutSettingsV2
): GenericPaymentMethod[] => {
  const initialPaymentMethods: GenericPaymentMethod[] = propOr(
    [],
    'paymentMethods',
    initialCheckoutSettings
  );
  const selectedPaymentMethods: GenericPaymentMethod[] = propOr(
    [],
    'paymentMethods',
    checkoutSettings
  );

  const normalizedPaymentMethods = selectedPaymentMethods.map((method) => {
    if (method.type === PaymentMethodTypes.BankSlip) {
      const expiresInDays = prop('expiresInDays', method);
      return {
        ...method,
        expiresInDays: expiresInDays ?? 0,
      };
    }
    return method;
  });

  const isSelectedPaymentMethodChanged = (selectedPaymentMethod: GenericPaymentMethod): boolean => {
    const predicate = whereEq(selectedPaymentMethod);

    return !initialPaymentMethods.some(predicate);
  };

  return normalizedPaymentMethods.filter(isSelectedPaymentMethodChanged);
};

const getMinimumOrder = (
  initialCheckoutSettings?: CheckoutSettingsV2,
  checkoutSettings?: CheckoutSettingsV2
): CheckoutSettingsV2['minimumOrder'] => {
  const initialMinimumOrderValue = path<number>(['minimumOrder', 'value'], initialCheckoutSettings);
  const minimumOrderValue = path<number>(['minimumOrder', 'value'], checkoutSettings);

  const minimumOrderWasUpdated = initialMinimumOrderValue !== minimumOrderValue;

  if (minimumOrderWasUpdated) return checkoutSettings?.minimumOrder;
  return undefined;
};

const getZeroOrderSettings = (
  initialCheckoutSettings?: CheckoutSettingsV2,
  checkoutSettings?: CheckoutSettingsV2
): CheckoutSettingsV2['zeroOrderSettings'] => {
  const initialZeroOrderSettings = path<CheckoutSettingsV2['zeroOrderSettings']>(
    ['zeroOrderSettings'],
    initialCheckoutSettings
  );
  const zeroOrderSettings = path<CheckoutSettingsV2['zeroOrderSettings']>(
    ['zeroOrderSettings'],
    checkoutSettings
  );

  const zeroOrderSettingsWasUpdated =
    JSON.stringify(initialZeroOrderSettings) !== JSON.stringify(zeroOrderSettings);

  if (zeroOrderSettingsWasUpdated) return checkoutSettings?.zeroOrderSettings;
  return undefined;
};

const prepareCheckoutSettings = (
  initialCheckoutValues?: CheckoutSettingsV2,
  checkoutSettings?: CheckoutSettingsV2
): CheckoutSettingsV2 => {
  const paymentMethods = getChangedPaymentMethods(initialCheckoutValues, checkoutSettings);
  const minimumOrder = getMinimumOrder(initialCheckoutValues, checkoutSettings);
  const zeroOrderSettings = getZeroOrderSettings(initialCheckoutValues, checkoutSettings);

  return {
    ...checkoutSettings,
    paymentMethods,
    minimumOrder,
    zeroOrderSettings,
  };
};

const prepareImageSettings = (
  initialImageSettings?: ImageSettings,
  imageSettings?: ImageSettings
) => {
  const initialThumbnailValue = path<string>(['thumbnailUrl'], initialImageSettings);
  const thumbnailValue = path<string>(['thumbnailUrl'], imageSettings);

  const thumbnailWasUpdated = initialThumbnailValue !== thumbnailValue;

  if (thumbnailWasUpdated) return imageSettings;
  return undefined;
};

const prepareOrderNotification = (
  initialOrderNotification?: OrderNotification,
  orderNotification?: OrderNotification
) => {
  const initialBccEmails = path<string[]>(['bccEmails'], initialOrderNotification);
  const bccEmails = path<string[]>(['bccEmails'], orderNotification);

  const initialOrderNotificationEmails = path<OrderNotificationOptions>(
    ['orderNotificationEmails'],
    initialOrderNotification
  );
  const orderNotificationEmails = path<OrderNotificationOptions>(
    ['orderNotificationEmails'],
    orderNotification
  );

  const bccEmailsWasUpdated = JSON.stringify(initialBccEmails) !== JSON.stringify(bccEmails);
  const orderNotificationEmailsWasUpdated =
    initialOrderNotificationEmails !== orderNotificationEmails;

  if (bccEmailsWasUpdated || orderNotificationEmailsWasUpdated) return orderNotification;
  return undefined;
};

const prepareValuesToSubmit = (
  values: CompanySettingsV2Form,
  initialValues: CompanySettingsV2Form
): CompanySettingsV2Form => {
  const preparedCheckoutSettings = prepareCheckoutSettings(
    initialValues.checkoutSettings,
    values.checkoutSettings
  );
  const preparedImageSettings = prepareImageSettings(
    initialValues.imageSettings,
    values.imageSettings
  );
  const preparedOrderNotification = prepareOrderNotification(
    initialValues.orderNotification,
    values.orderNotification
  );

  return {
    ...values,
    checkoutSettings: preparedCheckoutSettings,
    imageSettings: preparedImageSettings,
    orderNotification: preparedOrderNotification,
  };
};

export const useCompanySettingsFormV2 = (): UseCompanySettingsFormV2Result => {
  const { id: vendorId } = useParams<{ id: string }>();
  const { upsertCompanySettings, isUpdating: isUpdatingPatch } = useUpsertCompanySettingsV2();
  const { getSettingsUpdatedProps } = useParseSegmentEventProps();
  const {
    isLoading,
    isUpdating: isUpdatingGet,
    initialValues,
    orderNotificationOptions,
    availablePaymentMethods,
  } = useCompanySettingsInitialValuesV2(vendorId);

  const handleSubmit = (values: CompanySettingsV2Form) => {
    const preparedValues = prepareValuesToSubmit(values, initialValues);

    const segmentProps = getSettingsUpdatedProps({ values, preparedValues });

    settingsUpdated(segmentProps);

    upsertCompanySettings(preparedValues);
  };

  return {
    initialValues,
    orderNotificationOptions,
    availablePaymentMethods,
    handleSubmit,
    isLoading,
    isUpdating: isUpdatingGet || isUpdatingPatch,
    validationSchema: CompanySettingsFormV2Schema,
  };
};
