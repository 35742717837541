import { RestSerializer } from 'miragejs';

const serializers = {
  vendor: RestSerializer.extend({ embed: true }),
  user: RestSerializer.extend({ embed: true }),
  tier: RestSerializer.extend({ embed: true }),
  distributionCenter: RestSerializer.extend({ embed: true }),
};

export default serializers;
