import { Info } from '@hexa-ui/icons';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Container,
  InfoTooltip,
  PaymentInfoContainer,
  Title,
} from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/bankslip-payment-method/BankSlipPaymentMethod.styles';
import { PaymentMethodWrapper } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/payment-method-wrapper/PaymentMethodWrapper';
import type { PaymentMethodComponentProps } from '@company-settings/components/types';
import { EXPIRES_IN_DAYS_MAXIMUM_LENGTH } from '@company-settings/constants';
import { PaymentMethodTypes } from '@company-settings/enums';
import { useBankSlipPaymentMethod } from '@company-settings/hooks';
import { NumberField } from '@core/components';

export const BankSlipPaymentMethod = ({
  paymentIndex,
}: PaymentMethodComponentProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const {
    fieldNamePrefix,
    isExpiresInDaysEnabled,
    handleSelectionChange,
  } = useBankSlipPaymentMethod({ paymentIndex });

  return (
    <PaymentMethodWrapper
      type={PaymentMethodTypes.BankSlip}
      paymentIndex={paymentIndex}
      onSelectionChange={handleSelectionChange}
    >
      <Container>
        <PaymentInfoContainer>
          <Title>
            <FormattedMessage id="COMPANY_SETTINGS.BANK_SLIP_PAYMENT_METHOD_NAME" />
          </Title>
          <InfoTooltip
            placement="right"
            text={formatMessage({
              id: 'COMPANY_SETTINGS.BANK_SLIP_PAYMENT_METHOD_DESCRIPTION',
            })}
          >
            <Info size="medium" fr="100%" />
          </InfoTooltip>
        </PaymentInfoContainer>
        <NumberField
          type="text"
          id={`bank-slip-payment-method-${paymentIndex}`}
          name={`${fieldNamePrefix}.expiresInDays`}
          label={formatMessage({ id: 'COMPANY_SETTINGS.BANK_SLIP_PAYMENT_METHOD_INPUT_LABEL' })}
          width="100%"
          disabled={!isExpiresInDaysEnabled}
          maxLength={EXPIRES_IN_DAYS_MAXIMUM_LENGTH}
          pattern="\d"
        />
      </Container>
    </PaymentMethodWrapper>
  );
};
