import { useState } from 'react';
import { OrderNotificationOptions } from '@company-settings/enums';
import type { UseShowOrderNotificationWarningResult } from '@company-settings/hooks/types';
import { includes } from 'ramda';

const ORDER_NOTIFICATIONS_OPTIONS_TO_DISPLAY_WARNING: Array<OrderNotificationOptions> = [
  OrderNotificationOptions.AllSalesRepresentatives,
  OrderNotificationOptions.PrimarySalesRepresentative,
  OrderNotificationOptions.AllSalesRepresentativesSupervisor,
];

export const useShowOrderNotificationWarning = (): UseShowOrderNotificationWarningResult => {
  const [showAlertMessage, setShowAlertMessage] = useState(false);

  const handleShowAlertMessage = (orderNotificationOptionValue: OrderNotificationOptions) => {
    const showWarningAlert = includes(
      orderNotificationOptionValue,
      ORDER_NOTIFICATIONS_OPTIONS_TO_DISPLAY_WARNING
    );

    setShowAlertMessage(showWarningAlert);
  };

  return {
    showAlertMessage,
    handleShowAlertMessage,
  };
};
