import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { EmptiesOptions } from '@company-settings/enums';
import type { UseEmptiesResult } from '@company-settings/hooks/types';
import { EmptiesOption } from '@company-settings/types';
import { ChangeEvent } from 'react';

export const useEmpties = (): UseEmptiesResult => {
  const fieldName = 'checkoutSettings.zeroOrderSettings.emptiesEnabled';

  const { formatMessage } = useIntl();
  const [, meta, helper] = useField(fieldName);

  const emptiesChosenOption = meta.value;

  const YES = formatMessage({ id: `COMPANY_SETTINGS.EMPTIES_OPTION_YES` });
  const NO = formatMessage({ id: `COMPANY_SETTINGS.EMPTIES_OPTION_NO` });

  const emptiesOptions: Array<EmptiesOption> = [
    {
      id: 'empties-yes',
      label: YES,
      value: Boolean(EmptiesOptions.YES),
    },
    {
      id: 'empties-no',
      label: NO,
      value: Boolean(EmptiesOptions.NO),
    },
  ];

  const handlerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    const isEmptiesOptionYes = value === String(Boolean(EmptiesOptions.YES));

    helper.setValue(isEmptiesOptionYes);
  };

  return {
    handlerChange,
    emptiesChosenOption,
    emptiesOptions,
    emptiesRadioGroupName: fieldName,
  };
};
