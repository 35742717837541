import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  Description,
  PaymentInfoContainer,
  Title,
} from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/bank-deposit-payment-method/BankDepositPaymentMethod.styles';
import { PaymentMethodWrapper } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/payment-method-wrapper/PaymentMethodWrapper';
import type { PaymentMethodComponentProps } from '@company-settings/components/types';
import { PaymentMethodTypes } from '@company-settings/enums';

export const BankDepositPaymentMethod = ({
  paymentIndex,
}: PaymentMethodComponentProps): JSX.Element => (
  <PaymentMethodWrapper type={PaymentMethodTypes.BankDeposit} paymentIndex={paymentIndex}>
    <Container>
      <PaymentInfoContainer>
        <Title>
          <FormattedMessage id="COMPANY_SETTINGS.BANK_DEPOSIT_PAYMENT_METHOD_NAME" />
        </Title>
      </PaymentInfoContainer>
      <Description colortype="secondary" size="xsmall">
        <FormattedMessage id="COMPANY_SETTINGS.BANK_DEPOSIT_PAYMENT_METHOD_DESCRIPTION" />
      </Description>
    </Container>
  </PaymentMethodWrapper>
);
