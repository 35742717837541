import { Input, Tooltip } from '@hexa-ui/components';
import { Info } from '@hexa-ui/icons';
import { PaymentLabel } from 'config/constants.d';

import React from 'react';
import { useIntl } from 'react-intl';

import { InputContainer, LabelContainer, Paragraph } from './TranslationInput.styles';

export interface TranslationInputProps {
  iso: string;
  language?: PaymentLabel;
  value: string;
  onChange: React.FormEventHandler<HTMLInputElement>;
  hidden?: boolean;
  hasTooltip?: boolean;
}

export function TranslationInput({
  iso,
  language,
  value,
  onChange,
  hidden = false,
  hasTooltip = false,
}: TranslationInputProps): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <InputContainer key={iso} hidden={hidden}>
      <LabelContainer>
        <Paragraph size="small" weight="semibold">
          {formatMessage(
            { id: 'COMPANY_SETTINGS.CUSTOM_PAYMENT_MODAL_LABEL' },
            { language: language?.label }
          )}
        </Paragraph>
        {hasTooltip && (
          <Tooltip
            placement="right"
            text={formatMessage({
              id: `COMPANY_SETTINGS.CUSTOM_PAYMENT_MODAL_TOOLTIP`,
            })}
          >
            <Info size="medium" fr="100%" />
          </Tooltip>
        )}
      </LabelContainer>
      <Input
        data-testid={`payment-input-${iso}`}
        data-iso={iso}
        value={value}
        onChange={onChange}
        type="text"
        size="large"
        placeholder={formatMessage(
          { id: 'COMPANY_SETTINGS.CUSTOM_PAYMENT_MODAL_PLACEHOLDER' },
          { example: language?.placeholder }
        )}
        required
      />
    </InputContainer>
  );
}
