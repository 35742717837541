import { store } from 'app/store';
import { AllCountries } from 'domains/Countries';
import { PatchAllVendorsParams } from 'domains/Stores';
import { EndpointsToLog, LogData, startLog } from 'features/log/log';

interface LogUpdateVendorStoresEndpoint {
  endpoint: EndpointsToLog.updateVendorStores;
  data: PatchAllVendorsParams['data'];
  extraData: PatchAllVendorsParams['logData'];
}

const endpointMapper = {
  [EndpointsToLog.updateVendorStores]: (
    payload: LogUpdateVendorStoresEndpoint['data'],
    extra: LogUpdateVendorStoresEndpoint['extraData']
  ) => ({
    stores: payload,
    vendorId: extra.vendorId,
    vendorName: extra.vendorName,
  }),
} as {
  [key in EndpointsToLog]: (payload: unknown, extra: unknown) => LogData['data'];
};

export const logDataByEndpoint = ({
  endpoint,
  data,
  extraData,
  country,
}: LogUpdateVendorStoresEndpoint & { country: AllCountries }): void => {
  const endpointData = endpointMapper[endpoint]?.(data, extraData);

  if (endpointData) {
    store.dispatch(startLog({ endpoint, country, data: endpointData } as LogData));
  }
};
