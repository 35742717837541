import { Paragraph as HexaParagraph } from '@hexa-ui/components';
import { styled } from 'theme';

export const InputContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',

  '& > div:nth-child(2)': {
    width: '100%',
  },
  '&[hidden]': {
    display: 'none',
  },
});

export const LabelContainer = styled('div', {
  display: 'flex',
  gap: '$1',

  '& > p': {
    marginBottom: 0,
  },
});

export const Paragraph = styled(HexaParagraph, {
  marginBottom: '$8',
});
