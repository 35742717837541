const COMPANY_TABLE_FILTER_COUNTRY = 'companyTableFilterCountry';

export enum LocalStorageItems {
  COMPANY = COMPANY_TABLE_FILTER_COUNTRY,
}

const getLocalCountry = (): string => {
  const localCountry =
    sessionStorage.getItem(COMPANY_TABLE_FILTER_COUNTRY) ??
    localStorage.getItem(COMPANY_TABLE_FILTER_COUNTRY);
  return localCountry ?? '';
};

const setLocalCountry = (selectedCountry: string): void => {
  sessionStorage.setItem(COMPANY_TABLE_FILTER_COUNTRY, selectedCountry);
  localStorage.setItem(COMPANY_TABLE_FILTER_COUNTRY, selectedCountry);
};

const removeLocalDataItem = (itemName = COMPANY_TABLE_FILTER_COUNTRY): void => {
  sessionStorage.removeItem(itemName);
  localStorage.removeItem(itemName);
};

export { getLocalCountry, setLocalCountry, removeLocalDataItem };
