import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  Title,
} from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/cash-deposit-payment-method/CashDepositPaymentMethod.styles';
import { PaymentMethodWrapper } from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method/payment-method-wrapper/PaymentMethodWrapper';
import type { PaymentMethodComponentProps } from '@company-settings/components/types';
import { PaymentMethodTypes } from '@company-settings/enums';

export const CashDepositPaymentMethod = ({
  paymentIndex,
}: PaymentMethodComponentProps): JSX.Element => (
  <PaymentMethodWrapper type={PaymentMethodTypes.CashDeposit} paymentIndex={paymentIndex}>
    <Container>
      <Title>
        <FormattedMessage id="COMPANY_SETTINGS.CASH_DEPOSIT_PAYMENT_METHOD_NAME" />
      </Title>
    </Container>
  </PaymentMethodWrapper>
);
