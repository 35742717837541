import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CompanySettings } from '@company-settings/types';
import { Input } from '@hexa-ui/components';
import { useFormikContext } from 'formik';
import { useGetCurrencyParts } from 'hooks/useGetCurrencyParts';
import { InputGroup } from './MinimumOrder.styles';

export const MinimumOrder = (): JSX.Element => {
  const { formatMessage, formatNumber } = useIntl();
  const { setFieldValue, initialValues, dirty } = useFormikContext<CompanySettings>();

  const [value, setValue] = useState('');

  const { currencySymbol } = useGetCurrencyParts();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(e.currentTarget.value.replace(/\D/g, ''));
    const formattedNumber = formatNumber(number, {
      style: 'decimal',
    });

    setFieldValue('checkoutSettings.minimumOrder.value', number);
    setValue(formattedNumber);
  };

  useEffect(() => {
    if (!dirty) {
      const minimumInitialValue = initialValues.checkoutSettings?.minimumOrder?.value;
      if (minimumInitialValue) {
        const formattedNumber = formatNumber(minimumInitialValue, {
          style: 'decimal',
        });
        setValue(formattedNumber);
      } else {
        setValue('');
      }
    }
  }, [initialValues, dirty, formatNumber]);

  return (
    <InputGroup>
      <Input
        data-testid="minimum-order-amount"
        label={formatMessage({ id: 'COMPANY_SETTINGS.MINIMUM_ORDER_LABEL' })}
        prefix={currencySymbol}
        placeholder=""
        value={value}
        onChange={onChange}
        name="checkoutSettings.minimumOrder.value"
        maxLength={12}
      />
    </InputGroup>
  );
};
