import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logReducer from 'features/log/log';

export const rootReducer = combineReducers({
  log: logReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
