import { useLogService } from 'admin-portal-shared-services';
import { createServer, Response } from 'miragejs';
import React from 'react';
import ReactDOM from 'react-dom';
import { setupLocalEnv } from 'setupLocal';
import singleSpaReact from 'single-spa-react';
import App from './App';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!window.Cypress && process.env.STANDALONE && process.env.NODE_ENV === 'development') {
  setupLocalEnv();
}
const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLogService().error(error);
    return <></>;
  },
});

export const { bootstrap, mount, unmount } = reactLifecycles;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
  // If your app makes requests to domains other than / (the current domain), add them
  // here so that they are also proxied from your app to the handleFromCypress function.
  // For example: let otherDomains = ["https://my-backend.herokuapp.com/"]
  const methods = ['get', 'put', 'patch', 'post', 'delete'];

  createServer({
    environment: 'test',
    routes() {
      for (const domain of ['/']) {
        for (const method of methods) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this[method](`${domain}*`, async (schema, request) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const [status, headers, body] = await window.handleFromCypress(request);
            return new Response(status, headers, body);
          });
        }
      }
    },
  });
}
