import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import type { CompanySettings } from '@company-settings/types';
import { TextField } from '@core/components/fields/text-field/TextField';
import type { NumberFieldProps } from '@core/components/fields/types';

const UPDATE_VALUE_DELAY = 100;

const SAFE_LIST = [
  'Backspace',
  'Delete',
  'Left',
  'ArrowLeft',
  'Right',
  'ArrowRight',
  'End',
  'Home',
  'Shift',
  'Tab',
];

const isInSafeList = (key: string): boolean => SAFE_LIST.includes(key);

const isValidFromPattern = (key: string, pattern: string): boolean => {
  const regex = new RegExp(pattern, 'g');

  return regex.test(key);
};

const isValidDigit = (key: string, pattern: string): boolean => {
  const isSafe = isInSafeList(key);

  if (isSafe) {
    return true;
  }

  return isValidFromPattern(key, pattern);
};

const byPassValidation = ({ metaKey, ctrlKey }: React.KeyboardEvent<HTMLInputElement>) =>
  metaKey || ctrlKey;

const removeInvalidValues = (pastedValue: string, pattern: string): string =>
  pastedValue
    .split('')
    .filter((value) => isValidFromPattern(value, pattern))
    .join('')
    .replace(',', '.');

export const NumberField = ({ pattern, ...props }: NumberFieldProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<CompanySettings>();

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedValue = event.clipboardData.getData('text');
      const hasInvalidValue = pastedValue.split('').some((value) => !isValidDigit(value, pattern));

      if (hasInvalidValue) {
        setTimeout(() => {
          const newValue = removeInvalidValues(pastedValue, pattern);

          setFieldValue(props.name, newValue);
        }, UPDATE_VALUE_DELAY);
      }
    },
    [pattern, props.name, setFieldValue]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (byPassValidation(event)) {
        return;
      }

      const isValid = isValidDigit(event.key, pattern);

      if (!isValid) {
        event.preventDefault();
      }
    },
    [pattern]
  );

  return (
    <TextField
      type="number"
      placeholder="0"
      {...props}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
    />
  );
};
