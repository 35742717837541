import React from 'react';

import type { PaymentMethodProps } from '@company-settings/components/types';
import { useGetPaymentMethodComponent } from '@company-settings/hooks';
import { hasValue } from 'utils';

export const PaymentMethod = ({ type, paymentIndex }: PaymentMethodProps): JSX.Element => {
  const { PaymentMethodComponent } = useGetPaymentMethodComponent({ type });

  if (hasValue(PaymentMethodComponent)) {
    return <PaymentMethodComponent paymentIndex={paymentIndex} />;
  }

  return <></>;
};
