import { PaymentMethodTypes } from '@company-settings/enums';
import type { UseBankSlipPaymentMethodResult } from '@company-settings/hooks/types';
import { useGenericPaymentMethod } from '@company-settings/hooks/useGenericPaymentMethod';
import { useField } from 'formik';

interface UseBankSlipPaymentMethodProps {
  paymentIndex: number;
}

export const useBankSlipPaymentMethod = ({
  paymentIndex,
}: UseBankSlipPaymentMethodProps): UseBankSlipPaymentMethodResult => {
  const { fieldNamePrefix, isEnabled: isExpiresInDaysEnabled } = useGenericPaymentMethod({
    type: PaymentMethodTypes.BankSlip,
    paymentIndex,
  });

  const [, { value }, { setValue }] = useField(`${fieldNamePrefix}.expiresInDays`);

  const onChangeExpiresInDays = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = event.currentTarget.value;
    const numberExpiresInDays = Number(currValue);
    setValue(Number.isNaN(numberExpiresInDays) ? currValue : numberExpiresInDays);
  };

  const handleSelectionChange = (selected: boolean) => {
    if (selected) {
      updateExpiresInDays();
    }
  };

  const updateExpiresInDays = () => {
    setValue(value);
  };

  return {
    fieldNamePrefix,
    onChangeExpiresInDays,
    handleSelectionChange,
    isExpiresInDaysEnabled,
  };
};
