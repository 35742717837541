import { styled, theme } from 'theme';

import { Radio as CoreRadio, RadioGroup as CoreRadioGroup, H5 } from '@core/components';

export const Container = styled('div', {
  display: 'flex',
  flexFlow: 'column',
  margin: '$6 0 $6 0',
});

export const SubTitle = styled(H5, {
  '&&': {
    fontWeight: theme.fontWeights.semibold,
    marginTop: '$4',
  },
});

export const RadioGroup = styled(CoreRadioGroup, {
  display: 'flex',
  flexFlow: 'row',
  marginTop: '$6',
  marginBottom: '$6',
  gap: '$4',
});

export const Radio = styled(CoreRadio, {
  '&&': {
    margin: 'unset',
  },
});
