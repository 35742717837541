import { useMemo } from 'react';

import { useFeatureToggle } from '@core/hooks/useFeatureToggle';
import { useHasPermission } from 'admin-portal-shared-services';
import FeatureTogglesV2 from 'config/featureTogglesV2';
import permissions from 'config/permissions';
import { useCountryContext } from 'context/country';
import Countries, { AllCountries } from 'domains/Countries';

export const useShowEmptiesConfiguration = (): boolean => {
  const { isFeatureToggleActivated } = useFeatureToggle(FeatureTogglesV2.SHOW_EMPTIES_FEATURE);

  const { country } = useCountryContext();

  const allowedCountries: Array<AllCountries> = [Countries.ZA];

  const isCountryAllowed = allowedCountries.includes(country);

  const userHasPermission = useHasPermission(
    [
      permissions.CompanySettings.PaymentMethod.READ,
      permissions.CompanySettings.PaymentMethod.WRITE,
    ],
    'OR'
  );
  const hasPermissionToAccess = useMemo(
    () => isFeatureToggleActivated && isCountryAllowed && userHasPermission,
    [isFeatureToggleActivated, isCountryAllowed, userHasPermission]
  );

  return hasPermissionToAccess;
};
