import { Input } from '@hexa-ui/components';
import { useField } from 'formik';
import React from 'react';

import type { TextFieldProps } from '@core/components/fields/types';

export const TextField = ({ name, disabled, validate, ...props }: TextFieldProps): JSX.Element => {
  const [field, meta] = useField({ name, validate });
  const hasError = !disabled && meta.touched && Boolean(meta.error);

  return (
    <Input
      type="text"
      {...field}
      {...props}
      hasError={hasError}
      errorText={meta.error}
      disabled={disabled}
    />
  );
};
