import { Card } from '@hexa-ui/components';
import { styled } from 'theme';

export const CardContainer = styled(Card, {
  alignSelf: 'start',
  boxShadow: '$025',
  borderRadius: '$2',
});

export const Content = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: '$4',
  '& > div': {
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
  '& button': {
    minWidth: '20px',
  },
  '& label': {
    padding: '$6 $4 25px calc(36px + 16px)',
    width: 'calc(100% + 36px)',
    marginLeft: '-36px',
    lineHeight: '1.2',
  },
});

export const ExtraContent = styled('div', {
  padding: '0 $6 $6 calc(36px + 16px)',
});
