import { useMemo } from 'react';
import { DEFAULT_VALUES_V2 } from '@company-settings/constants';
import type { UseCompanySettingsInitialValuesV2Result } from '@company-settings/hooks/types';
import type {
  CompanySettingsV2,
  CompanySettingsV2Form,
  GenericPaymentMethod,
} from '@company-settings/types';
import { ascend, assocPath, dissocPath, isNil, omit, path, prop, sortWith } from 'ramda';
import { useGetCompanySettingsV2 } from 'services/query/useGetCompanySettingsV2';
import { applyIf } from 'utils';
import { useShowManagePaymentMethods } from './useShowManagePaymentMethods';

const preparePaymentMethods = (companySettings: CompanySettingsV2Form) => {
  const sortedPaymentMethods = sortWith(
    [ascend(prop('label'))],
    companySettings.checkoutSettings?.paymentMethods as GenericPaymentMethod[]
  );
  return assocPath(['checkoutSettings', 'paymentMethods'], sortedPaymentMethods, companySettings);
};

const prepareMininumMaximumOrder = (companySettings: CompanySettingsV2Form) => {
  const checkoutSettings = path(['checkoutSettings'], companySettings);

  const parsedCheckoutSettings = dissocPath(
    ['minimumOrder', 'enabled'],
    dissocPath(['maximumOrder'], checkoutSettings)
  );

  return assocPath(['checkoutSettings'], parsedCheckoutSettings, companySettings);
};

const prepareCheckoutSettings = (
  companySettings: CompanySettingsV2Form,
  shouldUseZeroOrderSettings: boolean
): CompanySettingsV2Form => {
  const companySettingsFormWithParsedPaymentMethods = preparePaymentMethods(companySettings);
  const companySettingsFormWithParsedMinimumMaximumOrder = prepareMininumMaximumOrder(
    companySettingsFormWithParsedPaymentMethods
  );
  if (shouldUseZeroOrderSettings) return companySettingsFormWithParsedMinimumMaximumOrder;
  return dissocPath(
    ['checkoutSettings', 'zeroOrderSettings'],
    companySettingsFormWithParsedMinimumMaximumOrder
  );
};

const extractInitialValues = (
  companySettings: CompanySettingsV2,
  shouldUseZeroOrderSettings: boolean
) => {
  const companySettingsForm = omit(
    ['availablePaymentMethods', 'orderNotificationOptions'],
    companySettings
  );
  const companySettingsFormWithDefaultValues = applyIf(DEFAULT_VALUES_V2, companySettingsForm);
  const companySettingsFormWithParsedCheckoutSettings = prepareCheckoutSettings(
    companySettingsFormWithDefaultValues,
    shouldUseZeroOrderSettings
  );

  return companySettingsFormWithParsedCheckoutSettings;
};

const getInitialValues = (
  vendorId: string,
  companySettings?: CompanySettingsV2,
  shouldUseZeroOrderSettings = false
): CompanySettingsV2Form => {
  if (isNil(companySettings)) {
    return {
      ...DEFAULT_VALUES_V2,
      vendorId,
    };
  }

  return extractInitialValues(companySettings, shouldUseZeroOrderSettings);
};

export const useCompanySettingsInitialValuesV2 = (
  vendorId: string
): UseCompanySettingsInitialValuesV2Result => {
  const shouldUseZeroOrderSettings = useShowManagePaymentMethods();
  const { companySettings, isLoading, isUpdating } = useGetCompanySettingsV2(vendorId);

  const availablePaymentMethods = companySettings?.availablePaymentMethods ?? [];
  const orderNotificationOptions = companySettings?.orderNotificationOptions ?? [];
  const initialValues = useMemo(
    () => getInitialValues(vendorId, companySettings, shouldUseZeroOrderSettings),
    [vendorId, companySettings, shouldUseZeroOrderSettings]
  );

  return {
    isLoading,
    isUpdating,
    initialValues,
    orderNotificationOptions,
    availablePaymentMethods,
  };
};
