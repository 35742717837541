import React from 'react';
import { useIntl } from 'react-intl';
import {
  CardContainer,
  Content,
  ExtraContent,
} from '@company-settings/components/company-settings-tab/pay-and-redeem/payment-method-item/PaymentMethodItem.styles';
import { EXPIRES_IN_DAYS_MAXIMUM_LENGTH } from '@company-settings/constants';
import { PaymentMethodTypes } from '@company-settings/enums';
import { useBankSlipPaymentMethod, useGenericPaymentMethodV2 } from '@company-settings/hooks';
import { GenericPaymentMethod } from '@company-settings/types';
import { NumberField } from '@core/components';
import { Checkbox } from '@hexa-ui/components';

export type PaymentMethodItemProps = GenericPaymentMethod & {
  paymentIndex: number;
  onSelectionChange?: (selected: boolean) => void;
};

const BankSlipConfig = ({ paymentIndex }: Pick<PaymentMethodItemProps, 'paymentIndex'>) => {
  const { formatMessage } = useIntl();
  const {
    fieldNamePrefix,
    isExpiresInDaysEnabled,
    onChangeExpiresInDays,
  } = useBankSlipPaymentMethod({ paymentIndex });

  return (
    <ExtraContent>
      <NumberField
        type="text"
        id={`bank-slip-payment-method-${paymentIndex}`}
        name={`${fieldNamePrefix}.expiresInDays`}
        onChange={onChangeExpiresInDays}
        label={formatMessage({ id: 'COMPANY_SETTINGS.BANK_SLIP_PAYMENT_METHOD_INPUT_LABEL' })}
        width="100%"
        disabled={!isExpiresInDaysEnabled}
        maxLength={EXPIRES_IN_DAYS_MAXIMUM_LENGTH}
        pattern="\d"
      />
    </ExtraContent>
  );
};

export const PaymentMethodItem = ({ ...props }: PaymentMethodItemProps): JSX.Element => {
  const { fieldNamePrefix, isEnabled, handleSelectionChange } = useGenericPaymentMethodV2(props);

  return (
    <CardContainer border="small" elevated="minimal">
      <Content>
        <Checkbox
          id={`payment-method-selection-${props.type}_${props.label}_${props.paymentIndex}`}
          name={`${fieldNamePrefix}.enabled`}
          onCheckedChange={handleSelectionChange}
          checked={isEnabled}
          label={props.label}
        />
      </Content>
      {props.type === PaymentMethodTypes.BankSlip && (
        <BankSlipConfig paymentIndex={props.paymentIndex} />
      )}
    </CardContainer>
  );
};
