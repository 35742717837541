import { useAuthenticationService, useEnvService } from 'admin-portal-shared-services';
import Axios from 'axios';
import Countries, { AllCountries } from 'domains/Countries';
import { EndpointsToLog } from 'features/log/log';
import { EU_COUNTRIES } from 'utils/countries';
import { logDataByEndpoint } from 'utils/log';
import { getApiHost } from '../services/host/HostService';
import type { IAxiosRequestConfig, IGenericDataWithError, IRequest } from './Api.d';

export const initialAxios = Axios.create();

const authentication = useAuthenticationService();

const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

authentication.enhancedAxios(initialAxios, {
  headers: [
    {
      country: userCountry ?? Countries.BR,
    },
  ],
});

initialAxios.interceptors.request.use((config: IAxiosRequestConfig) => {
  const isSit = useEnvService().getEnv() === 'QA';
  if (
    !isSit &&
    EU_COUNTRIES.includes(config.headers?.country as Countries) &&
    config.url &&
    config.url !== '/log'
  ) {
    const path = config.url.split('/api/')[1];
    config.url = `/api/eu/${path}`;
  }

  if (config.headers?.log && (!config.data || !config.headers?.logExtraData)) {
    const message = `Missing 'data' (body) or 'logExtraData' header to successfully log ${
      config.headers.log as string
    } endpoint`;
    console.error(message);
    throw new Error(message);
  }

  return config;
});

initialAxios.interceptors.response.use((response) => {
  if (response.config.headers?.log) {
    logDataByEndpoint({
      endpoint: response.config.headers.log as EndpointsToLog,
      data: JSON.parse(response.config.data),
      extraData: JSON.parse(response.config.headers.logExtraData as string),
      country: response.config.headers.country as AllCountries,
    });
  }
  return response;
});

const Api = {
  get: <T, E = unknown>({ url, config }: IRequest): Promise<IGenericDataWithError<T, E>> =>
    initialAxios.get<T>(`${getApiHost()}${url}`, config).catch((error) => ({
      hasError: true,
      ...error,
    })),

  post: <T, E = unknown>({ url, body, config }: IRequest): Promise<IGenericDataWithError<T, E>> =>
    initialAxios.post(`${getApiHost()}${url}`, body, config).catch((error) => ({
      hasError: true,
      ...error,
    })),

  patch: <T, E = unknown>({ url, body, config }: IRequest): Promise<IGenericDataWithError<T, E>> =>
    initialAxios.patch<T>(`${getApiHost()}${url}`, body, config).catch((error) => ({
      hasError: true,
      ...error,
    })),

  delete: <T, E = unknown>({ url, config }: IRequest): Promise<IGenericDataWithError<T, E>> =>
    initialAxios.delete<T>(`${getApiHost()}${url}`, config).catch((error) => ({
      hasError: true,
      ...error,
    })),
};

export default Api;
