import { H3 } from '@core/components';
import { Tooltip } from '@hexa-ui/components';
import { styled } from 'theme';

export const Container = styled('div', {
  display: 'flex',
  flexFlow: 'column',
  gap: '$2',
  margin: '$6 0',
});

export const EmptiesConfigurationTitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  '& svg': {
    margin: 'auto',
  },
  '& > div:first-of-type': {
    display: 'flex',
  },
});

export const EmptiesConfigurationTooltip = styled(Tooltip);

export const EmptiesConfigurationTitle = styled(H3);
