import { useHasPermission } from 'admin-portal-shared-services';
import { useMemo } from 'react';

import { useFeatureToggle } from '@core/hooks/useFeatureToggle';
import FeatureTogglesV2 from 'config/featureTogglesV2';
import permissions from 'config/permissions';

export const useShowManagePaymentMethods = (): boolean => {
  const { isFeatureToggleActivated } = useFeatureToggle(
    FeatureTogglesV2.SHOW_MANAGE_PAYMENT_METHODS
  );

  const userHasScopes = useHasPermission(permissions.CompanySettings.PaymentMethod.WRITE);

  const hasPermissionToAccess = useMemo(() => isFeatureToggleActivated && userHasScopes, [
    isFeatureToggleActivated,
    userHasScopes,
  ]);

  return hasPermissionToAccess;
};
