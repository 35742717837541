/* eslint-disable react-hooks/exhaustive-deps */
import { Radio } from '@hexa-ui/components';
import { useField } from 'formik';
import React, { useCallback, useEffect } from 'react';

import type { RadioGroupProps } from '@core/components/fields/types';
import { hasValue } from 'utils';

export const RadioGroup = ({
  name,
  onValueChange,
  children,
  ...props
}: RadioGroupProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const fieldValue = meta.value ?? '';

  const handleValueChange = useCallback(
    (value: string) => {
      helpers.setValue(value);
      helpers.setTouched(!!value);

      if (onValueChange) onValueChange(value);
    },
    [helpers, onValueChange]
  );

  useEffect(() => {
    if (!hasValue(meta.value) || meta.value === meta.initialValue) {
      handleValueChange(meta.initialValue);
    }
  }, [meta.value, meta.initialValue]);

  return (
    <Radio.Root {...field} {...props} value={fieldValue} onValueChange={handleValueChange}>
      {children}
    </Radio.Root>
  );
};
