import { array, boolean, lazy, number, NumberSchema, object, string } from 'yup';

import {
  AVAILABLE_PAYMENT_METHODS,
  EXPIRES_IN_DAYS_MAXIMUM_VALUE,
  UNACCEPTED_MINIMUM_ORDER_VALUE,
} from '@company-settings/constants';
import { PaymentMethodTypes } from '@company-settings/enums';
import { Translations } from '@company-settings/types';
import { hasValue } from 'utils';

const isValidNumber = (value?: number | null): boolean => {
  if (hasValue(value)) {
    const parsedValue = hasValue(value) ? value.toString() : '';

    return /\d$/i.test(parsedValue);
  }

  return true;
};

const isExpiresInDaysRequired = (type: PaymentMethodTypes, enabled: boolean): boolean =>
  PaymentMethodTypes.BankSlip === type && enabled === true;

const MinimumOrderSchema = object()
  .shape({
    type: string().required(),
    value: number()
      .typeError('Please enter a valid number')
      .positive('The value must be bigger than zero.')
      .moreThan(UNACCEPTED_MINIMUM_ORDER_VALUE, 'The value must be bigger than zero.'),
  })
  .notRequired()
  .default(undefined);

const ExpiresInDaysSchema = number().when(['type', 'enabled'], {
  is: isExpiresInDaysRequired,
  then: (schema: NumberSchema) =>
    schema
      .typeError('Please enter a valid number')
      .test('expires-in-days-only-numbers', 'Please enter a valid number', isValidNumber)
      .integer('The value must be an integer number.')
      .positive('The value must be bigger than zero.')
      .max(
        EXPIRES_IN_DAYS_MAXIMUM_VALUE,
        `The value must be less than ${EXPIRES_IN_DAYS_MAXIMUM_VALUE}`
      )
      .required('Required field'),
});

const mapRules = (obj: Record<string, unknown> = {}, rule: unknown) =>
  Object.keys(obj).reduce((newObj, key) => ({ ...newObj, [key]: rule }), {});

const TranslationsSchema = lazy((obj) =>
  object(
    mapRules(
      obj as Translations,
      object({
        label: string().required(),
        description: string().notRequired(),
      })
    )
  )
    .notRequired()
    .default(undefined)
);

const PaymentMethodSchema = object()
  .shape({
    id: string().notRequired(),
    type: string().required().oneOf(AVAILABLE_PAYMENT_METHODS),
    enabled: boolean().required(),
    label: string().required(),
    description: string().notRequired(),
    translations: TranslationsSchema,
    expiresInDays: ExpiresInDaysSchema,
  })
  .notRequired()
  .default(undefined);

const OrderNotificationSchema = object().shape({
  bccEmails: array().of(string()),
  orderNotificationEmails: string(),
});

const ImageSettingsSchema = object().shape({
  thumbnailUrl: string(),
});

const CheckoutSettingsSchema = object().shape({
  zeroOrderSettings: object().shape({
    emptiesEnabled: boolean(),
    redemptionsEnabled: boolean(),
  }),
  minimumOrder: MinimumOrderSchema,
  paymentMethods: array().of(PaymentMethodSchema),
});

export const CompanySettingsFormV2Schema = object().shape({
  vendorId: string().required(),
  orderNotification: OrderNotificationSchema,
  imageSettings: ImageSettingsSchema,
  checkoutSettings: CheckoutSettingsSchema,
});
