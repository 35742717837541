/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useLogService } from 'admin-portal-shared-services';
import { loadAllVendors as loadAllVendorsApi } from 'Api/vendor/vendorApi';
import {
  loadAllVendorsDone,
  loadAllVendors as loadAllVendorsEvent,
  loadAllVendorsFail,
} from 'stores/vendor/VendorEvents';
import QuantityOptions from 'types/QuantityOptions';

export const execute = (
  selectedCountry?: string,
  page?: number,
  pageSize?: number,
  partialSearch?: string
) => {
  const log = useLogService();
  loadAllVendorsEvent(null);

  if (!page) page = 0;
  if (!pageSize) pageSize = QuantityOptions.firstOptions;
  if (!partialSearch) partialSearch = '';

  const loadAll = loadAllVendorsApi(selectedCountry, page, pageSize, partialSearch);

  return Promise.all([loadAll])
    .then((response) => {
      const loadAllResponse = response[0].data;
      loadAllVendorsDone(loadAllResponse);
      log.info('LoadVendorsService - load all vendors');
      return loadAllResponse;
    })
    .catch((error) => {
      log.error('LoadVendorsService: ', error);

      loadAllVendorsFail(null);
    });
};

export default execute;
