import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import { useSaveSettingsPermission } from '@company-settings/hooks';
import type { UseFooterButtons } from '@company-settings/hooks/types';

export const useFooterButtons = (): UseFooterButtons => {
  const { initialValues, values, isValid } = useFormikContext();
  const saveSettingsPermission = useSaveSettingsPermission();
  const areEquals = useMemo(() => JSON.stringify(values) === JSON.stringify(initialValues), [
    initialValues,
    values,
  ]);

  return {
    isSaveButtonEnabled: !areEquals && saveSettingsPermission && isValid,
    isCancelButtonEnabled: !areEquals,
  };
};
