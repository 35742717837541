import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ACCEPTED_FILE_TYPE_JPG,
  ACCEPTED_FILE_TYPE_PNG,
  MAX_FILE_HEIGHT,
  MAX_FILE_SIZE,
  MAX_FILE_WIDTH,
} from '@core/components/file-uploader/constants';
import type { UseFileUploaderResult } from '@core/hooks/use-file-uploader/types';
import type { FileValidated } from '@hexa-ui/components';

export const useFileUploader = (initialImage?: FileValidated | null): UseFileUploaderResult => {
  const { formatMessage } = useIntl();
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<Array<FileValidated> | undefined>([]);

  const IMAGE_DIMENSIONS_ERROR_MESSAGE = formatMessage({
    id: 'COMPANY_DETAILS.IMAGE_DIMENSIONS_ERROR',
  });
  const IMAGE_TYPE_ERROR_MESSAGE = formatMessage({
    id: 'COMPANY_DETAILS.IMAGE_TYPE_ERROR',
  });
  const IMAGE_SIZE_ERROR_MESSAGE = formatMessage({
    id: 'COMPANY_DETAILS.IMAGE_SIZE_ERROR',
  });

  const setError = (errorMessage: string) => {
    setIsInvalidFile(true);
    setCurrentErrorMessage(errorMessage);
  };

  const cleanErrors = () => {
    setIsInvalidFile(false);
    setCurrentErrorMessage('');
  };

  const getFileExtension = (fileName: string) => {
    const splittedFileName = /^.+\.([^.]+)$/.exec(fileName);

    const FILE_NAME_INDEX = 1;

    return splittedFileName == null ? '' : splittedFileName[FILE_NAME_INDEX];
  };

  const validateImageTypeError = (file: File): boolean => {
    const fileType = getFileExtension(file.name);
    const allowedFileTypes = [ACCEPTED_FILE_TYPE_JPG, ACCEPTED_FILE_TYPE_PNG];
    const isFileTypeSupported = allowedFileTypes.includes(fileType);
    let hasError = false;

    if (!isFileTypeSupported) {
      setError(IMAGE_TYPE_ERROR_MESSAGE);

      hasError = true;
    }

    return hasError;
  };

  const validateImageSizeError = (file: File): boolean => {
    let hasError = false;

    if (file.size > MAX_FILE_SIZE) {
      setError(IMAGE_SIZE_ERROR_MESSAGE);

      hasError = true;
    }

    return hasError;
  };

  const validateImageDimensionsError = (img: HTMLImageElement): Promise<boolean> =>
    new Promise((resolve, reject) => {
      img.onload = () => {
        if (img.width > MAX_FILE_WIDTH || img.height > MAX_FILE_HEIGHT) {
          setError(IMAGE_DIMENSIONS_ERROR_MESSAGE);

          URL.revokeObjectURL(img.src);
          resolve(true);
        } else {
          URL.revokeObjectURL(img.src);
          resolve(false);
        }
      };

      img.onerror = () => {
        reject(new Error());
      };
    });

  const checkIfImageHasErrors = async (file: File): Promise<boolean> => {
    const fileUrl = URL.createObjectURL(file);
    const img = new Image();

    img.src = fileUrl;

    const hasError =
      validateImageTypeError(file) ||
      validateImageSizeError(file) ||
      (await validateImageDimensionsError(img));

    return hasError;
  };

  const onDrop = async (filesToValidate: Array<FileValidated>) => {
    const fileToValidate = filesToValidate[0];
    const { file } = fileToValidate;

    cleanErrors();

    const imageHasErrors = await checkIfImageHasErrors(file);

    if (!imageHasErrors) setUploadedFiles(filesToValidate);
  };

  const onClean = () => {
    setUploadedFiles([]);
    cleanErrors();
  };

  useEffect(() => {
    if (initialImage) {
      setUploadedFiles([initialImage]);
    } else {
      setUploadedFiles([]);
    }
  }, [initialImage]);

  return {
    onDrop,
    onClean,
    isInvalidFile,
    uploadedFiles,
    setUploadedFiles,
    currentErrorMessage,
    checkIfImageHasErrors,
  };
};
