import { Button as HexaButton } from '@hexa-ui/components';
import React from 'react';

import type { ButtonProps } from '@core/components/buttons/types';

export const Button = ({ children, variant = 'primary', ...props }: ButtonProps): JSX.Element => (
  <HexaButton variant={variant} size="medium" {...props}>
    {children}
  </HexaButton>
);
