import React from 'react';

import { Button } from '@core/components/buttons/button/Button';
import type { GenericButtonProps } from '@core/components/buttons/types';

export const SecondaryButton = ({ children, ...props }: GenericButtonProps): JSX.Element => (
  <Button variant="secondary" {...props}>
    {children}
  </Button>
);
