import React, { createContext, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  useAuthenticationService,
  useCountryService,
  useEnvService,
  usePermissionService,
} from 'admin-portal-shared-services';
import {
  CountryData,
  CountryLangType,
} from 'admin-portal-shared-services/dist/hooks/useCountryService/useCountryService.types';
import {
  DTC_SUPPORTED_COUNTRIES,
  SUPPORTED_COUNTRIES,
  US_SUPPORTED_COUNTRIES,
} from 'config/constants';
import permissions from 'config/permissions';
import { useClusterContext } from 'context/cluster';
import { AllCountries, SupportedCountries } from 'domains/Countries';
import { useStore } from 'effector-react';
import usePreferredLanguage from 'hooks/usePreferredLanguage';
import { selectCountry } from 'stores/vendor/VendorEvents';
import VendorStore from 'stores/vendor/VendorStore';
import segmentAnalytics from 'tracking/generated';
import { FilterCriteria, ListType, ScreenName } from 'tracking/types.deprecated';
import { COUNTRY_BY_ACRONYM } from 'utils/countries';
import { getLocalCountry } from 'utils/localData';
import { notifyError } from 'utils/notification';

interface CountryContextData {
  country: AllCountries;
  havePermissionToSeeSelectCountries: boolean;
  isLoading: boolean;
  setCountry: (country: AllCountries) => void;
  getCountryName: (countryCode: AllCountries) => string;
  getSupportedCountries: (shouldConsiderDTC?: boolean) => Array<SupportedCountries>;
}

interface CountryProviderProps {
  children: React.ReactNode;
}

const CountryContext = createContext<CountryContextData>({} as CountryContextData);

const CountryProvider = ({ children }: CountryProviderProps): JSX.Element => {
  useStore(VendorStore);
  const history = useHistory();
  const { isDTC, isUS } = useClusterContext();
  const { formatMessage } = useIntl();
  const permissionService = usePermissionService();
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const userCountries = authentication.getSupportedCountries() as Array<AllCountries>;
  const { data: countriesData, isLoading } = useCountryService();
  const preferredLanguage = usePreferredLanguage();
  const env = useEnvService().getEnv();

  const shouldShowSelectCountries = permissionService.has(
    permissions.CompanyManagementGlobal.WRITE
  );
  const initialCountry = shouldShowSelectCountries ? getLocalCountry() || userCountry : userCountry;

  const doesUserHaveAllCountriesPermission = (): boolean =>
    permissionService.has(permissions.CompanyManagementGlobal.ALL_COUNTRIES) ||
    permissionService.has(permissions.CompanyManagement.ALL_COUNTRIES);

  const getSupportedCountries = (shouldConsiderDTC = true): Array<SupportedCountries> => {
    const getAllSupportedCountries = () => {
      const isProd = env === 'PROD';
      if (shouldConsiderDTC && isDTC) {
        return DTC_SUPPORTED_COUNTRIES;
      }
      if (isUS) {
        return US_SUPPORTED_COUNTRIES;
      }
      if (isProd) {
        return SUPPORTED_COUNTRIES;
      }
      return [...SUPPORTED_COUNTRIES, ...US_SUPPORTED_COUNTRIES];
    };

    const allSupported = getAllSupportedCountries();

    if (doesUserHaveAllCountriesPermission()) {
      return allSupported;
    }

    const filteredCountries = userCountries.filter((country) =>
      allSupported.includes(country as never)
    );

    return filteredCountries as Array<SupportedCountries>;
  };

  const allSupportedCountries = getSupportedCountries();

  const [selectedCountry, setSelectedCountry] = useState<AllCountries>(
    allSupportedCountries.includes(initialCountry as SupportedCountries)
      ? (initialCountry as SupportedCountries)
      : allSupportedCountries[0]
  );

  const setCountry = (country: AllCountries) => {
    segmentAnalytics.listFiltered({
      filter_criteria: FilterCriteria.Country,
      filter_value: COUNTRY_BY_ACRONYM[country as keyof typeof COUNTRY_BY_ACRONYM],
      list_type: ListType.CompanyList,
      screen_name: ScreenName.CompanyManagement,
    });
    setSelectedCountry(country);
    selectCountry({ selectedCountry: country, history });
  };

  const getCountryName = (countryCode: string) => {
    const countries = (countriesData ?? []) as CountryData[];
    const foundCountry = countries.find((c) => c.code === countryCode);
    const language = preferredLanguage.split('-')[0] as keyof CountryLangType;
    return foundCountry?.name?.[language] ?? foundCountry?.name?.en ?? countryCode;
  };

  useEffect(() => {
    if (!userCountries.length) {
      notifyError(formatMessage({ id: 'COMPANY_LIST.CANNOT_LOAD_SUPPORTED_COUNTRIES' }));
    }
  }, [userCountries, formatMessage]);

  useEffect(() => {
    selectCountry({ selectedCountry: initialCountry as AllCountries, history });
  }, []);

  return (
    <CountryContext.Provider
      value={{
        country: selectedCountry,
        havePermissionToSeeSelectCountries: shouldShowSelectCountries,
        setCountry: (country: AllCountries) => setCountry(country),
        getSupportedCountries,
        getCountryName,
        isLoading,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};

const useCountryContext = (): CountryContextData => {
  const context = useContext(CountryContext);
  if (!Object.keys(context).length) {
    throw new Error('useCountryContext must be used within a CountryProvider');
  }

  return context;
};

export { CountryProvider, useCountryContext };
