import { Alert } from '@hexa-ui/components';
import { styled } from 'theme';

export const StyledAlert = styled(Alert, {
  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    spaced: {
      true: {
        margin: '$2 0',
      },
    },
  },
});
