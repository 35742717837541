import { useField } from 'formik';

import type { GenericPaymentMethod } from '@company-settings/types';
import { hasValue } from 'utils';

export type UseGenericPaymentMethodV2Props = {
  paymentIndex: number;
  onSelectionChange?: (selected: boolean) => void;
};

export type UseGenericPaymentMethodV2Result = {
  fieldNamePrefix: string;
  isEnabled: boolean;
  value?: GenericPaymentMethod;
  handleSelectionChange: (selected: boolean) => void;
};

export const useGenericPaymentMethodV2 = ({
  paymentIndex,
  onSelectionChange,
}: UseGenericPaymentMethodV2Props): UseGenericPaymentMethodV2Result => {
  const fieldNamePrefix = `checkoutSettings.paymentMethods[${paymentIndex}]`;
  const [, { value }, { setValue }] = useField<GenericPaymentMethod>(fieldNamePrefix);
  const isEnabled = Boolean(value?.enabled);

  const handleSelectionChange = (selected: boolean) => {
    setValue({
      ...value,
      enabled: selected,
    });

    if (hasValue(onSelectionChange)) {
      onSelectionChange(selected);
    }
  };

  return {
    fieldNamePrefix,
    isEnabled,
    value,
    handleSelectionChange,
  };
};
